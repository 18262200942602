import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { ErrorCodes, LoadingStatusesRecord } from '../../../constants';
import { RootState } from '../../../store/store';
import { GrpcService } from '../../../services/grpcService';
import { OfferIdResponse, OfferResponse } from 'grpc-era/offer_pb';
import { selectToken } from '../../../store/slices/authSlice';
import { delayAsyncFunction } from '../../../helpers/delayAsyncFunction';
import { LoadingStatuses } from '../../../types/LoadingStatuses';
import { HttpService } from '../../../services/httpService';
import { showAuthModal } from '../../../components/Modals/store/modalSlice';

export const createNewOffer = createAsyncThunk<
    OfferIdResponse.AsObject,
    Omit<Parameters<typeof GrpcService.createOffer>[0], 'imageData' | 'imageName'> & {
        imagesList: FileList;
    },
    { state: RootState }
>('offers/createOffer', async ({ imagesList: [image], ...rest }, { getState, dispatch }) => {
    const token = selectToken(getState());
    const httpService = new HttpService({
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return GrpcService.getFileUploadLink(image.name, token)
        .then((res) => res.toObject().uploadPath)
        .then(async (uploadPath) => {
            const formData = new FormData();
            formData.set('file', image);

            const uploadResult = await httpService.post('/upload/' + uploadPath, formData);

            if (uploadResult.error) {
                throw uploadResult.error;
            }

            return uploadPath;
        })
        .then(async (uploadPath) => {
            // debugger;
            return (await GrpcService.createOffer({ ...rest, imageName: uploadPath }, token)).toObject();
        })
        .catch((error) => {
            console.error(error);

            if (error) {
                if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                    dispatch(showAuthModal());
                } else if (error.name && error.name === ErrorCodes.RPC_ERROR) {
                    dispatch(showAuthModal());
                }
            }

            return Promise.reject(error);
        });
});

export const getPublicOffers = createAsyncThunk<
    Array<OfferResponse.AsObject>,
    Parameters<typeof GrpcService.getOfferByCategory>[0],
    { state: RootState }
>('offers/getOffers', async (input, { getState, dispatch }) => {
    const token = selectToken(getState());

    await delayAsyncFunction(500);

    try {
        return await GrpcService.getOfferByCategory(input, token); //optional token should be here ?
    } catch (error: any) {
        console.error(error)
        if (error) {
            if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                dispatch(showAuthModal());
            } else if (error.name && error.name === ErrorCodes.RPC_ERROR) {
                dispatch(showAuthModal());
            }
        }

        return Promise.reject(error);
    }
});

export const getPublicOffer = createAsyncThunk<
    OfferResponse.AsObject,
    Parameters<typeof GrpcService.getPublicOffer>[0],
    { state: RootState }
>('offers/getOffer', async (input, { getState, dispatch }) => {
    const token = selectToken(getState());

    await delayAsyncFunction(500);

    try {
        return await GrpcService.getPublicOffer(input, token); //optional token should be here ?
    } catch (error: any) {
        if (error) {
            if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                dispatch(showAuthModal());
            } else if (error.name && error.name === ErrorCodes.RPC_ERROR) {
                dispatch(showAuthModal());
            }
        }

        return Promise.reject(error);
    }
});

export const deleteOffer = createAsyncThunk<
    OfferIdResponse.AsObject,
    Parameters<typeof GrpcService.deleteOffer>[0],
    { state: RootState }
>('offers/deleteOffer', async (input, { getState, dispatch }) => {
    const token = selectToken(getState());

    await delayAsyncFunction(500);

    try {
        return await GrpcService.deleteOffer(input, token); //optional token should be here ?
    } catch (error: any) {
        if (error) {
            if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                dispatch(showAuthModal());
            } else if (error.name && error.name === ErrorCodes.RPC_ERROR) {
                dispatch(showAuthModal());
            }
        }

        return Promise.reject(error);
    }
});

type AdditionalFields = {
    loadingStatus: LoadingStatuses;
    hasMoreEntities: boolean;
};

const initialAdditionalFields: AdditionalFields = {
    loadingStatus: LoadingStatusesRecord.Initial,
    hasMoreEntities: true,
};

const offersAdapter = createEntityAdapter<OfferResponse.AsObject>();

export const offersSlice = createSlice({
    name: 'offers',
    initialState: offersAdapter.getInitialState(initialAdditionalFields),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(
                getPublicOffers.pending,
                (
                    draftState,
                    {
                        meta: {
                            arg: { lastId },
                            requestStatus,
                        },
                    }
                ) => {
                    draftState.loadingStatus = requestStatus;

                    !lastId && offersAdapter.removeAll(draftState);
                }
            )
            .addCase(getPublicOffers.fulfilled, (draftState, { payload, meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
                draftState.hasMoreEntities = !!payload.length;

                draftState.hasMoreEntities && offersAdapter.addMany(draftState, payload);
            })
            .addCase(getPublicOffers.rejected, (draftState, { meta: { requestStatus, aborted } }) => {
                !aborted && (draftState.loadingStatus = requestStatus);
            })
            .addCase(getPublicOffer.pending, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            })
            .addCase(getPublicOffer.fulfilled, (draftState, { payload, meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
                offersAdapter.addOne(draftState, payload);
            })
            .addCase(getPublicOffer.rejected, (draftState, { meta: { requestStatus, aborted } }) => {
                !aborted && (draftState.loadingStatus = requestStatus);
            })
            .addCase(createNewOffer.pending, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            })
            .addCase(createNewOffer.fulfilled, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            })
            .addCase(createNewOffer.rejected, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            });
    },
});

const selectSlice = ({ offers }: RootState) => offers;

export const selectOffersList = offersAdapter.getSelectors(selectSlice).selectAll;

export const selectOffersListLength = offersAdapter.getSelectors(selectSlice).selectTotal;

export const selectOfferById = (id?: number) =>
    createSelector(selectSlice, (slice) => (id ? offersAdapter.getSelectors().selectById(slice, id) : undefined));

export const selectOffersLoadingStatus = createSelector(selectSlice, ({ loadingStatus }) => loadingStatus);

export const selectOffersListHasMoreEntities = createSelector(selectSlice, ({ hasMoreEntities }) => hasMoreEntities);

