import { useRouteMatch } from 'react-router-dom';
import { FC, ReactNode } from 'react';
import classNames from 'classnames';

type Props = { children: ReactNode };

const routesWithoutPadding = ['/chats/:id', '/chats', '/deals', '/earn'];

export const StyledContainer: FC<Props> = ({ children }) => {
    const withPadding = !useRouteMatch({
        path: routesWithoutPadding,
        strict: true,
        sensitive: true,
        exact: true,
    });

    return (
        <div
            className={classNames('max-w-[680px] mx-auto h-full', {
                ['px-5 ']: withPadding,
            })}
        >
            {children}
        </div>
    );
};
