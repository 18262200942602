import { useCallback } from 'react';
import { WalletNotConnectedError } from '@solana/wallet-adapter-base';
import { getNonce, login, selectToken } from '../store/slices/authSlice';
import { bs58 } from '@project-serum/anchor/dist/cjs/utils/bytes';
import { Connection, ConnectionConfig, PublicKey, Transaction } from '@solana/web3.js';
import { AnchorProvider, Program, web3 } from '@project-serum/anchor';
import {
    createAssociatedTokenAccountInstruction,
    getAccount,
    getAssociatedTokenAddress,
    TOKEN_PROGRAM_ID,
    TokenError,
} from '@solana/spl-token';
import { IDL } from '../../../libs/ctonna_escrow';
import { BN } from 'bn.js';
import { useAnchorWallet, useWallet } from '@solana/wallet-adapter-react';
import * as anchor from '@project-serum/anchor';
import { useAppDispatch } from '../store/store';
import { GrpcService } from '../services/grpcService';
import { useSelector } from 'react-redux';
import { useCookie } from './useCookie';
import { cookieReferralName } from '../constants';
import { showAuthModal } from '../components/Modals/store/modalSlice';
import { config } from '../../config/config';
import {
    TokenAccountNotFoundError,
    TokenInvalidAccountOwnerError,
    TokenInvalidAccountSizeError,
} from '@solana/spl-token/src/errors';
import { toast } from 'react-toastify';
import { PublicKeyNotFound } from '../services/errorService';
import { useRollbar } from '@rollbar/react';
import { useApplicationLocalState } from './useApplicationLocalState';

type Response = {
    data: number | null;
    error:
        | TokenAccountNotFoundError
        | TokenInvalidAccountOwnerError
        | TokenInvalidAccountSizeError
        | PublicKeyNotFound
        | null;
};

const programId = new PublicKey(config.programId);
const mint = new PublicKey(config.mint);
const tokenSeed = anchor.utils.bytes.utf8.encode('vault');
const escrowSeed = anchor.utils.bytes.utf8.encode('escrow');
const judgePublicKeyInstance = new PublicKey(config.judgePublicKey);

const { SystemProgram, Keypair } = web3;

const generateKeyPairFromSecretKey = (secretKey: string) => Keypair.fromSecretKey(bs58.decode(secretKey));

const throwWalletNotConnectedError = () => {
    throw new WalletNotConnectedError();
};

export const useSolanaService = () => {
    const { getCookie } = useCookie();

    const dispatch = useAppDispatch();

    const rollbar = useRollbar();

    const token = useSelector(selectToken);
    const { setToken } = useApplicationLocalState();

    const { publicKey, signMessage, connecting } = useWallet();

    const anchorWallet = useAnchorWallet();

    const getProvider = useCallback(async () => {
        if (!anchorWallet) {
            return throwWalletNotConnectedError();
        }

        const network = config.networkEndpoint;

        const connectionConfig = (token && {
            httpHeaders: { auth: token },
            wsEndpoint: config.websocket.solanaNode,
        }) as ConnectionConfig;
        const connection = new Connection(network, connectionConfig);

        return new AnchorProvider(connection, anchorWallet, {
            preflightCommitment: 'processed',
        });
    }, [anchorWallet, token]);

    const getTokenBalance = useCallback(async () => {
        const response: Response = { data: null, error: null };

        if (!publicKey || !token) {
            if (!connecting) {
                response.error = new PublicKeyNotFound();
            }

            return response;
        }

        const provider = await getProvider();
        const tokenAccount = await getAssociatedTokenAddress(mint, publicKey);

        const tokenAccountBalance = await provider.connection.getTokenAccountBalance(tokenAccount)
            .catch((tokenAccountBalanceError) => {
                console.error(tokenAccountBalanceError);
                rollbar.error(tokenAccountBalanceError);

                return tokenAccountBalanceError;
            })

        if (tokenAccountBalance.value?.uiAmount) {
            response.data = tokenAccountBalance.value?.uiAmount
        } else {
            response.error = tokenAccountBalance;
        }

        return response
    }, [connecting, getProvider, publicKey, rollbar]);

    const signInWallet = useCallback(async () => {
        if (!publicKey) {
            return throwWalletNotConnectedError();
        }

        if (typeof signMessage === 'undefined') {
            alert('The wallet does not support sign. Please select another wallet (Phantom for example)');
        } else {
            const nonceResult = await dispatch(getNonce());
            const referralCode = getCookie(cookieReferralName) ?? '';

            if (getNonce.fulfilled.match(nonceResult)) {
                const { authPhrase, nonce } = nonceResult.payload;

                dispatch(
                    login({
                        signature: bs58.encode(await signMessage(new TextEncoder().encode(authPhrase))),
                        publicKey: publicKey.toString(),
                        nonce,
                        authPhrase,
                        refParentUserPublicKey: referralCode,
                        saveToken: (signedToken) => setToken?.(signedToken)
                    })
                );
            }
        }
    }, [dispatch, publicKey, signMessage, getCookie]);

    const createEscrow = useCallback(
        async (sellerPublicKey: string, offerId: number, offerPrice: number, cb: () => void) => {
            if (!publicKey || !token) {
                dispatch(showAuthModal());
                return;
            }

            const sellerPublicKeyInstance = new PublicKey(sellerPublicKey);
            const sellerTokenAddress = await getAssociatedTokenAddress(mint, sellerPublicKeyInstance);

            const provider = await getProvider();

            const escrowAccount = Keypair.generate();

            const escrowAccountPublicKey = escrowAccount.publicKey.toString();

            // TODO: handle escrow accounts errors

            await GrpcService.saveEscrowKeypair(
                {
                    publicKey: escrowAccountPublicKey,
                    secretKey: bs58.encode(escrowAccount.secretKey),
                },
                token
            ).catch((e) => {
                console.error(e)
                dispatch(showAuthModal())
            });

            const savedEscrowAccountPublicKey = (
                await GrpcService.getEscrowKeypair({ publicKey: escrowAccountPublicKey }, token)
            ).publicKey;

            if (escrowAccountPublicKey !== savedEscrowAccountPublicKey) throw Error('pizdariki');

            const buyerTokenAccount = await getAssociatedTokenAddress(mint, publicKey);

            const program = new Program(IDL, programId, provider);

            try {
                await getAccount(provider.connection, buyerTokenAccount);
            } catch (error) {
                if (error instanceof TokenAccountNotFoundError) {
                    toast.info('You need to have USDT, please buy it!!');
                }

                if (error instanceof TokenInvalidAccountOwnerError) {
                    toast.info('Invalid account owner');
                }
                if (error instanceof TokenInvalidAccountSizeError) {
                    toast.info('Invalid account size');
                }

                console.error('Failed to get an account due to error!', error);

                return;
            }

            const [vaultAccountPda] = await PublicKey.findProgramAddress(
                [Buffer.from(tokenSeed), escrowAccount.publicKey.toBuffer()],
                program.programId
            );

            const initialiseInst = await program.methods
                .buyerInitialize(new BN(offerId), new BN(offerPrice * (web3.LAMPORTS_PER_SOL / 1000)))
                .accounts({
                    escrowAccount: escrowAccount.publicKey,
                    buyer: publicKey,
                    systemProgram: SystemProgram.programId,
                    vaultAccount: vaultAccountPda,
                    seller: sellerPublicKeyInstance,
                    sellerReceiveTokenAccount: sellerTokenAddress,
                    mint: mint,
                    buyerDepositTokenAccount: buyerTokenAccount,
                })
                .instruction();

            const instructions = await getAccount(provider.connection, sellerTokenAddress)
                .then(() => [])
                .catch(() => [
                    createAssociatedTokenAccountInstruction(
                        publicKey,
                        sellerTokenAddress,
                        sellerPublicKeyInstance,
                        mint
                    ),
                ])
                .then((result) => [...result, initialiseInst]);

            await provider.sendAndConfirm(new Transaction().add(...instructions), [escrowAccount]);

            cb()
        },
        [getProvider, publicKey, token, dispatch]
    );

    const cancelEscrow = useCallback(
        async (escrowAccountPublicKey: string) => {
            if (!publicKey || !token) return throwWalletNotConnectedError();

            const { secretKey: escrowAccountSecretKey } = await GrpcService.getEscrowKeypair(
                { publicKey: escrowAccountPublicKey },
                token
            );

            const provider = await getProvider();
            const escrowAccountKeyPair = generateKeyPairFromSecretKey(escrowAccountSecretKey);
            const buyerTokenAccount = await getAssociatedTokenAddress(mint, publicKey);
            const program = new Program(IDL, programId, provider);

            const [vaultAccountPda] = await PublicKey.findProgramAddress(
                [Buffer.from(tokenSeed), escrowAccountKeyPair.publicKey.toBuffer()],
                program.programId
            );

            const [vaultAuthorityPda] = await PublicKey.findProgramAddress(
                [Buffer.from(escrowSeed), escrowAccountKeyPair.publicKey.toBuffer()],
                program.programId
            );

            const cancelInst = await program.methods
                .buyerCancel()
                .accounts({
                    buyerDepositTokenAccount: buyerTokenAccount,
                    escrowAccount: escrowAccountKeyPair.publicKey,
                    vaultAccount: vaultAccountPda,
                    vaultAuthority: vaultAuthorityPda,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    buyer: publicKey,
                })
                .instruction();

            const ts3 = new Transaction().add(cancelInst);

            await provider.sendAndConfirm(ts3, [escrowAccountKeyPair]);
        },
        [getProvider, publicKey, token]
    );

    const approveEscrow = useCallback(
        async (sellerPublicKey: string, escrowAccountPublicKey: string) => {
            if (!publicKey || !token) return throwWalletNotConnectedError();

            const { secretKey: escrowAccountSecretKey } = await GrpcService.getEscrowKeypair(
                { publicKey: escrowAccountPublicKey },
                token
            );

            const sellerPublicKeyInstance = new PublicKey(sellerPublicKey);
            const provider = await getProvider();
            const escrowAccountKeyPair = generateKeyPairFromSecretKey(escrowAccountSecretKey);

            const program = new Program(IDL, programId, provider);

            const approveInst = await program.methods
                .buyerApprove()
                .accounts({
                    escrowAccount: escrowAccountKeyPair.publicKey,
                    buyer: publicKey,
                    seller: sellerPublicKeyInstance,
                })
                .instruction();

            const ts = new Transaction().add(approveInst);

            await provider.sendAndConfirm(ts, [escrowAccountKeyPair]);
        },
        [getProvider, publicKey, token]
    );

    const releaseEscrow = useCallback(
        async (sellerPublicKey: string, escrowAccountPublicKey: string) => {
            if (!publicKey || !token) return throwWalletNotConnectedError();

            const { secretKey: escrowAccountSecretKey } = await GrpcService.getEscrowKeypair(
                { publicKey: escrowAccountPublicKey },
                token
            );

            const sellerPublicKeyInstance = new PublicKey(sellerPublicKey);
            const sellerTokenAccount = await getAssociatedTokenAddress(mint, sellerPublicKeyInstance);
            const provider = await getProvider();
            const escrowAccountKeyPair = generateKeyPairFromSecretKey(escrowAccountSecretKey);

            const judgeAssociatedTokenAccountAddress = await getAssociatedTokenAddress(mint, judgePublicKeyInstance);

            const buyerTokenAccount = await getAssociatedTokenAddress(mint, publicKey);
            const program = new Program(IDL, programId, provider);

            const [vaultAccountPda] = await PublicKey.findProgramAddress(
                [Buffer.from(tokenSeed), escrowAccountKeyPair.publicKey.toBuffer()],
                program.programId
            );

            const [vaultAuthorityPda] = await PublicKey.findProgramAddress(
                [Buffer.from(escrowSeed), escrowAccountKeyPair.publicKey.toBuffer()],
                program.programId
            );

            const finishInst = await program.methods
                .buyerFinish()
                .accounts({
                    buyerDepositTokenAccount: buyerTokenAccount,
                    seller: sellerPublicKeyInstance,
                    sellerReceiveTokenAccount: sellerTokenAccount,
                    escrowAccount: escrowAccountKeyPair.publicKey,
                    vaultAccount: vaultAccountPda,
                    vaultAuthority: vaultAuthorityPda,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    buyer: publicKey,
                    commissionTokenAccount: judgeAssociatedTokenAccountAddress,
                })
                .instruction();

            const ts = new Transaction().add(finishInst);

            await provider.sendAndConfirm(ts, [escrowAccountKeyPair]);
        },
        [getProvider, publicKey, token]
    );

    const judgeCancel = useCallback(
        async (buyerPublicKey: string, escrowAccountPublicKey: string) => {
            if (!publicKey || !token) return throwWalletNotConnectedError();

            const { secretKey: escrowAccountSecretKey } = await GrpcService.getEscrowKeypair(
                { publicKey: escrowAccountPublicKey },
                token
            );

            const buyerPublicKeyInstance = new PublicKey(buyerPublicKey);
            const buyerTokenAccount = await getAssociatedTokenAddress(mint, buyerPublicKeyInstance);
            const provider = await getProvider();
            const escrowAccountKeyPair = generateKeyPairFromSecretKey(escrowAccountSecretKey);

            const program = new Program(IDL, programId, provider);

            const [vaultAccountPda] = await PublicKey.findProgramAddress(
                [Buffer.from(tokenSeed), escrowAccountKeyPair.publicKey.toBuffer()],
                program.programId
            );

            const [vaultAuthorityPda] = await PublicKey.findProgramAddress(
                [Buffer.from(escrowSeed), escrowAccountKeyPair.publicKey.toBuffer()],
                program.programId
            );

            const judgeAssociatedTokenAccountAddress = await getAssociatedTokenAddress(mint, judgePublicKeyInstance);

            const finishInst = await program.methods
                .judgeCancel()
                .accounts({
                    buyer: buyerPublicKey,
                    buyerDepositTokenAccount: buyerTokenAccount,
                    escrowAccount: escrowAccountKeyPair.publicKey,
                    vaultAccount: vaultAccountPda,
                    vaultAuthority: vaultAuthorityPda,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    judge: judgeAssociatedTokenAccountAddress,
                })
                .instruction();

            const transaction = new Transaction().add(finishInst);

            await provider.sendAndConfirm(transaction, [escrowAccountKeyPair]);
        },
        [getProvider, publicKey, token]
    );

    const judgeConfirm = useCallback(
        async (sellerPublicKey: string, escrowAccountPublicKey: string) => {
            if (!publicKey || !token) return throwWalletNotConnectedError();

            const { secretKey: escrowAccountSecretKey } = await GrpcService.getEscrowKeypair(
                { publicKey: escrowAccountPublicKey },
                token
            );

            const sellerPublicKeyInstance = new PublicKey(sellerPublicKey);
            const sellerTokenAccount = await getAssociatedTokenAddress(mint, sellerPublicKeyInstance);
            const provider = await getProvider();
            const escrowAccountKeyPair = generateKeyPairFromSecretKey(escrowAccountSecretKey);

            const program = new Program(IDL, programId, provider);

            const [vaultAccountPda] = await PublicKey.findProgramAddress(
                [Buffer.from(tokenSeed), escrowAccountKeyPair.publicKey.toBuffer()],
                program.programId
            );

            const [vaultAuthorityPda] = await PublicKey.findProgramAddress(
                [Buffer.from(escrowSeed), escrowAccountKeyPair.publicKey.toBuffer()],
                program.programId
            );

            const judgeAssociatedTokenAccountAddress = await getAssociatedTokenAddress(mint, judgePublicKeyInstance);

            const finishInst = await program.methods
                .judgeFinish()
                .accounts({
                    seller: sellerPublicKey,
                    sellerReceiveTokenAccount: sellerTokenAccount,
                    escrowAccount: escrowAccountKeyPair.publicKey,
                    vaultAccount: vaultAccountPda,
                    vaultAuthority: vaultAuthorityPda,
                    tokenProgram: TOKEN_PROGRAM_ID,
                    judge: judgeAssociatedTokenAccountAddress,
                })
                .instruction();

            const transaction = new Transaction().add(finishInst);

            await provider.sendAndConfirm(transaction, [escrowAccountKeyPair]);
        },
        [getProvider, publicKey, token]
    );

    return {
        signInWallet,
        createEscrow,
        cancelEscrow,
        approveEscrow,
        releaseEscrow,
        publicKey, //: publicKey?.toString() ?? storedPublicKey,
        getTokenBalance,
        judgeCancel,
        judgeConfirm,
    };
};

