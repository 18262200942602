import { useCallback, useEffect, FC, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './Offers.module.css';
import search from '../../../Landing/assets/Img/search-img.svg';
import settingsImage from '../../../Landing/assets/Img/settings.svg';
import {
    getPublicOffers,
    selectOffersList,
    selectOffersListHasMoreEntities,
    selectOffersLoadingStatus,
} from '../../store/offersSlice';
import { useAppDispatch } from '../../../../store/store';
import { OfferTypesEnum, SortingByEnum, SortingDirectionsEnum } from 'grpc-era/offer_pb';
import { OffersList } from './OffersList/OffersList';
import { LoadingStatusesRecord, MAX_PRICE_OFFER } from '../../../../constants';
import { Button } from '../../../../ui/Button/Button';
import { debouncedValues, FormValues, resolver } from './formData';
import { Select } from '../../../../ui/Select/Select';
import { RadioButtons } from '../../../../ui/RadioGroup/RadioGroup';
import { StyledSlider } from '../../../../ui/StyledSlider/StyledSlider';
import { useBooleanState } from '../../../../hooks/useBooleanState';
import { Modal } from '../../../../ui/Modal/Modal';
import { Input } from '../../../../ui/Input/Input';
import { selectPublicOfferCategories } from '../../../../store/slices/publicSlice';
import { Animated } from '../../../../ui/Animated/Animated';
import { Title } from '../../../../ui/Title/Title';
import { Header } from '../../../../components/Header/Header';
import { Carousel } from '../../../../components/Carousel/Carousel';
import { categoryIconsMap } from '../../../../helpers/categoryIconsMap';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { Text } from '../../../../ui/Text/Text';
import altCategoryIcon from '../../../../../../assets/icon/CategoryDragon.png'
import { CategoryImage } from '../../../../ui/CategoryImage/CategoryImage';

export const Offers: FC = () => {
    const dispatch = useAppDispatch();
    const { isMobile } = useWindowDimensions();
    const { t } = useTranslation();

    const offersList = useSelector(selectOffersList);
    const offersLoadingStatus = useSelector(selectOffersLoadingStatus);
    const offersListHasMoreEntities = useSelector(selectOffersListHasMoreEntities);
    const { list: offerCategories } = useSelector(selectPublicOfferCategories);

    const {
        state: isSettingsModalOpen,
        setTrue: openSettingsModalMenu,
        setFalse: closeSettingsModalMenu,
    } = useBooleanState();

    const lastId = useRef<Parameters<typeof getPublicOffers>[0]['lastId']>(0);
    const cancelOffersLoading = useRef<VoidFunction | null>(null);
    const submitTimerId = useRef<number | null>(null);

    const offerSortDirectionOptions = useMemo(() => [
        { label: t('In descending order'), value: SortingDirectionsEnum.DESCENDING },
        { label: t('In ascending order'), value: SortingDirectionsEnum.ASCENDING },
    ], [t]) ;
    
    const offerSortFieldOptions = useMemo(() => [
        { label: t('Date of creation'), value: SortingByEnum.CREATED_AT },
        { label: t('Price'), value: SortingByEnum.PRICE },
    ], [t]) ;

    const offerCategoriesOptions = useMemo(() => {
        const categories = ['all', ...offerCategories];
        return categories.map((category) => ({ label: t(category), value: category }));
    }, [offerCategories, t]);

    const offerCategoriesCarousel = useMemo(() => {
        return offerCategories.map((category) => ({ label: t(category), value: category }));
    }, [offerCategories, t]);

    const defaultValues: FormValues = useMemo(
        () => ({
            offerCategory: offerCategoriesOptions[0].value,
            offerType: OfferTypesEnum.SELL,
            size: 14,
            minPrice: 0,
            maxPrice: MAX_PRICE_OFFER,
            sortDirection: SortingDirectionsEnum.DESCENDING,
            sortBy: SortingByEnum.CREATED_AT,
            search: '',
        }),
        [offerCategoriesOptions]
    );

    const {
        watch,
        setValue,
        control,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues,
        resolver,
    });

    const [offerType, minPrice, maxPrice] = watch(['offerType', 'minPrice', 'maxPrice']);

    const updatePrices = useCallback(
        ([minPrice, maxPrice]: Array<number>) => {
            setValue('minPrice', minPrice);
            setValue('maxPrice', maxPrice);
        },
        [setValue]
    );

    const handleSelectCategoryInCarousel = useCallback((selectedCategory: string) => {
        setValue('offerCategory', selectedCategory);
    }, [setValue]);

    const offerTypeSetters = useMemo(
        () => ({
            [OfferTypesEnum.BUY]: () => setValue('offerType', OfferTypesEnum.BUY),
            [OfferTypesEnum.SELL]: () => setValue('offerType', OfferTypesEnum.SELL),
        }),
        [setValue]
    );

    const onSubmit = useMemo(
        () =>
            handleSubmit((formValues: FormValues) => {
                cancelOffersLoading.current?.();

                const request = dispatch(getPublicOffers({ ...formValues, lastId: lastId.current }));
                cancelOffersLoading.current = request.abort;

                return request;
            }),
        [dispatch, handleSubmit]
    );

    const [sentryRef] = useInfiniteScroll({
        loading: offersLoadingStatus === LoadingStatusesRecord.Loading,
        hasNextPage: offersListHasMoreEntities,
        onLoadMore: onSubmit,
        delayInMs: 500,
        disabled: offersLoadingStatus === LoadingStatusesRecord.Error,
    });

    useEffect(() => {
        onSubmit().finally();

        const subscription = watch((_, { name }) => {
            lastId.current = 0;
            submitTimerId.current && clearTimeout(submitTimerId.current);

            name && debouncedValues.includes(name)
                ? (submitTimerId.current = window.setTimeout(() => onSubmit(), 500))
                : onSubmit();
        });

        return () => {
            subscription.unsubscribe();
        };
    }, [dispatch, onSubmit, watch]);

    useEffect(() => {
        setTimeout(() => {
            const currentOffersLastId = offersList[offersList.length - 1]?.id;

            currentOffersLastId && (lastId.current = currentOffersLastId);
        }, 0)
        
    }, [offersList]);

    return (
        <section className={styles.offersWrapper}>
            <div>
                <Header />
            </div>

            <div className="max-w-[680px] mx-auto h-[5rem] p-5">
                <Title type="6">{t('Offers')}</Title>
            </div>

            <Animated>
                <div className="flex justify-center gap-3">
                    {[OfferTypesEnum.SELL, OfferTypesEnum.BUY].map((currentOfferType) => (
                        <div key={currentOfferType} className="flex-1">
                            <Button
                                type="button"
                                colorScheme={offerType === currentOfferType ? 'white' : 'gray'}
                                onClick={offerTypeSetters[currentOfferType]}
                                size="FULL"
                            >
                                {currentOfferType === OfferTypesEnum.BUY ? t('Sell') : t('Buy')}
                            </Button>
                        </div>
                    ))}
                </div>

                <div className="mt-6">
                    <Carousel
                        visibleSlides={isMobile ? 4 : 6}
                        slides={offerCategoriesCarousel.map(({ value, label }, index) => (
                            <div key={index} onClick={() => handleSelectCategoryInCarousel(value)}>
                                <div className="flex">
                                    <div className="mx-auto w-[70px] h-[70px]">
                                        <CategoryImage imageUrl={categoryIconsMap[value]} fallbackUrl={altCategoryIcon} alt={value} />
                                    </div>
                                </div>
                                <div className="text-center break-words ">
                                    <Text>{label}</Text>
                                </div>
                            </div>
                        ))}
                    />
                </div>

                <div className={styles.search}>
                    <Input
                        inputSize="small"
                        className="mr-2.5"
                        icon={<img src={String(search)} alt="search-icon" />}
                        {...register('search')}
                        placeholder={t('Search')!}
                    />

                    <div className={styles.settingsBlock} onClick={openSettingsModalMenu}>
                        <img src={String(settingsImage)} alt="settings-img" />
                    </div>
                </div>

                <OffersList offersList={offersList} ref={sentryRef} loadingStatus={offersLoadingStatus} />
            </Animated>

            <Modal open={isSettingsModalOpen} onClose={closeSettingsModalMenu}>
                <Select className="mt-6" options={offerCategoriesOptions} name="offerCategory" control={control} />

                <Select options={offerSortFieldOptions} name="sortBy" control={control} className="mt-4" />

                <RadioButtons<SortingDirectionsEnum, FormValues>
                    options={offerSortDirectionOptions}
                    name="sortDirection"
                    control={control}
                    label={t("Sort direction")!}
                    className="mt-4"
                />

                <label className="mt-4 block">{`${t('Price')}, $`}</label>

                <div className="flex gap-3 mt-4">
                    <Input {...register('minPrice')} error={errors.minPrice?.message} />

                    <Input {...register('maxPrice')} error={errors.maxPrice?.message} />
                </div>

                <StyledSlider
                    className="mt-4"
                    range
                    draggableTrack
                    draggable={false}
                    allowCross={false}
                    value={[minPrice, maxPrice]}
                    // @ts-ignore
                    onChange={updatePrices}
                    min={0}
                    max={MAX_PRICE_OFFER}
                />
            </Modal>
        </section>
    );
};

