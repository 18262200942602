import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FC, useCallback, useEffect } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getDeals, selectDealsList, selectDealsLoadingStatus } from '../../store/dealsSlice';
import { useAppDispatch } from '../../../../store/store';
import { LoadingStatusesRecord } from '../../../../constants';
import { Text } from '../../../../ui/Text/Text';
import { Animated } from '../../../../ui/Animated/Animated';
import { Subheader } from '../../../../components/Subeader/Subheader';
import * as styles from './Deals.module.css';
import { showAuthModal } from '../../../../components/Modals/store/modalSlice';
import { selectToken } from '../../../../store/slices/authSlice';
import { useTranslation } from 'react-i18next';

export const Deals: FC = () => {
    const { t } = useTranslation();

    const deals = useSelector(selectDealsList);
    const loadingStatus = useSelector(selectDealsLoadingStatus);
    const dispatch = useAppDispatch();
    const token = useSelector(selectToken);
    const login = useCallback(() => dispatch(showAuthModal()), [dispatch]);

    useEffect(() => {
        dispatch(getDeals());
    }, [dispatch, token]);

    return (
        <Animated>
            <Subheader title={t('Deals')} />

            {loadingStatus === LoadingStatusesRecord.Loaded && (
                <div className="flex flex-col px-5">
                    {deals.map(({ offerHeader, createdAt, dealId, amount }) => (
                        <NavLink key={dealId} to={`/deals/${dealId}`}>
                            <button className={styles.dealWrapper}>
                                <div>
                                    <p className={styles.id}>{`#${String(dealId).substring(0, 2)}`}</p>
                                </div>
                                <div className={styles.content}>
                                    <div className={styles.messageWrapper}>
                                        <p className={styles.chatId}>{`$ ${amount}`}</p>
                                        <p className={styles.text}>{offerHeader}</p>
                                    </div>
                                    <div className={styles.dateWrapper}>
                                        <p className={styles.date}>{moment(createdAt).format('D/MM/YY, h:mm a')}</p>
                                    </div>
                                </div>
                            </button>
                        </NavLink>
                    ))}
                </div>
            )}

            {loadingStatus === LoadingStatusesRecord.Loaded && !deals.length && (
                <div className="py-5 px-10">
                    <Text>
                        ${t('No deals')}.
                        <br />
                        {t('Proceed to')}{' '}
                        <Link to="/offers" className="underline underline-offset-2">
                            {t('Offers')}
                        </Link>
                    </Text>
                </div>
            )}

            {loadingStatus === LoadingStatusesRecord.Error && (
                <div className="h-full flex">
                    <div className="pl-10 pt-5">
                        <Text>{`${t('You should')} `}</Text>
                        <Text className="underline underline-offset-2" onClick={login}>
                            {t('Login')}
                        </Text>
                        <Text>${` ${t('to see your deals')}`}</Text>
                    </div>
                </div>
            )}
        </Animated>
    );
};
