import { FC } from 'react';
import styles from './GlobalMarket.module.css';
import { Title } from '../../../../ui/Title/Title';
import { Arrow } from '../../../../ui/Arrow/Arrow';

export const GlobalMarket: FC = () => (
    <div className={styles.globalMarket}>
        <Title type="3" tag="h3">
            Global market <br /> efficiency
        </Title>
        <div className={styles.globalMarketSelling}>
            Earn money with ERA crypto <br /> by selling goods or services <br /> in a few clicks
        </div>
        <div className={styles.globalMarketArrows}>
            <Arrow direction="left" />
            <Arrow />
        </div>
    </div>
);
