.cryptoUniverseAbout {
    padding: 0 40px;
    text-align: center;
}
.cryptoUniverseWrapper {
    display: flex;
    margin-top: 35px;
    padding-bottom: 50px;
}
.cryptoUniverseMenu {
    width: 184px;
    height: 184px;
    border: 1px solid #ffffff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
}
.cryptoUniverseMenu:first-child {
    position: relative;
    bottom: -3%;
}
.cryptoUniverseMenu:last-child {
    position: relative;
    top: -3%;
}
.cryptoUniverseBlock {
    display: flex;
    flex-direction: column;
    width: 50%;
}
.cryptoUniverseBlock:first-child {
    align-items: end;
    position: relative;
    right: -2%;
}
.cryptoUniverseBlock:last-child {
    position: relative;
    left: -2%;
}
