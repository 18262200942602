import { FC } from 'react';
import styles from './WhatIs.module.css';
import { Title } from '../../../../ui/Title/Title';
import { Gradient } from '../../../../ui/Gradient/Gradient';

export const WhatIs: FC = () => (
    <div className={styles.whatIs}>
        <Gradient type="4" />
        <Title type="1" tag="h1">
            What is ERA crypto?
        </Title>
        <div className={styles.whatIsText}>
            You asked – we made. <br /> ERA crypto is a <b>decentralized platform</b>
            <br /> combining both
            <br /> a <b>supply-and-demand marketplace</b>
            <br /> and <br />
            <b>an independent logistics service</b>.{' '}
        </div>
    </div>
);
