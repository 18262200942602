import { FC } from 'react';
import styles from './Footer.module.css';

export const Footer: FC = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.footerWrapper}>
                <a href="src/shared/components/layout/Footer/Footer#">terms of service</a>
                <a href="src/shared/components/layout/Footer/Footer#">Cookies</a>
                <a href="src/shared/components/layout/Footer/Footer#">Help center</a>
            </div>
        </div>
    );
};
