import { REFRESH_SUBSCRIPTION_INTERVALS } from '../constants';

/**
 * 0	CONNECTING	Socket has been created. The connection is not yet open.
 * 1	OPEN	The connection is open and ready to communicate.
 * 2	CLOSING	The connection is in the process of closing.
 * 3	CLOSED The connection is closed or couldn't be opened
 */
enum ReadyState {
    CONNECTING = 0,
    OPEN = 1,
    CLOSING = 2,
    CLOSED = 3,
}

class WebsocketService {
    private ws: WebSocket | undefined;
    private readonly websocketUrl: string;
    private readonly dataHandler: (message: any) => void;

    constructor(websocketUrl: string, dataHandler: (data: any) => void) {
        this.websocketUrl = websocketUrl;
        this.dataHandler = dataHandler;
    }

    connectionState(): ReadyState {
        if (!this.ws) {
            return 3;
        }

        return this.ws.readyState;
    }

    disconnect(reason = 'Close connection') {
        this.ws?.close(1000, reason);
    }

    connect(token: string | null, onConnect?: () => void, onConnectionFailed?: (code: number, reason: string) => void) {
        this.ws = new WebSocket(this.websocketUrl);

        this.ws.onopen = (ev: Event) => {
            // do nothing
        };

        this.ws.onmessage = (messageEvent: MessageEvent) => {
            const { data } = messageEvent;

            if (data === 'Auth:') {
                if (token) {
                    this.ws?.send(token);
                    onConnect?.()
                } else {
                    this.ws?.close(1000, 'Token not found. Close connection');
                }

                return;
            }

            this.dataHandler(JSON.parse(data));
        };

        this.ws.onclose = (cv: CloseEvent) => {
            const reason = ['Connection closed.', 'Code: ', cv.code, '. Reason: ', cv.reason].join('');

            console.log(reason);
            onConnectionFailed?.(cv.code, reason)
            
            // this.ws?.close();

            // if (this.connectionState() === ReadyState.CLOSED) {
            //     if (cv.code !== 1000) {
            //         console.log("The connection is closed or couldn't be opened. Reconnecting...");
            //         setTimeout(() => {
            //             this.connect(token);
            //         }, REFRESH_SUBSCRIPTION_INTERVALS.RECONNECT);
            //     }
            //
            //     return;
            // }
            //
            // if (this.connectionState() === ReadyState.CLOSING) {
            //     console.log('The connection is in the process of closing. Waiting...');
            // }
            //
            // if (this.connectionState() === ReadyState.OPEN) {
            //     console.log('The connection is open and ready to communicate. No need to reconnect!');
            // }
            // if (this.connectionState() === ReadyState.CONNECTING) {
            //     console.log('Socket has been created. The connection is not yet open. Waiting...');
            // }
        };
    }
}

export const websocketService = (websocketUrl: string, dataHandler: (data: any) => void): WebsocketService =>
    new WebsocketService(websocketUrl, dataHandler);
