import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../ui/Text/Text';

export const PredealState: FC = () => {
    const mounted = useRef(false);
    const { t } = useTranslation()
    const [predealState, setPredealState] = useState<number>(0);

    const predealOptions = useMemo(() => ([t('firstState'), t('secondState'), t('thirdState')]), [t])

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false
        };
      }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            if (mounted.current) {
                setPredealState(prev => {
                    if (prev >= 2) {
                        clearInterval(interval)
                        
                        return prev
                    }
    
                    return prev + 1
                })
            }
        }, 3000)

        return () => {
            clearInterval(interval)
        }
    }, [setPredealState])

    return <Text className="!text-3xl">{predealOptions[predealState]}</Text>;
};

