import { FC } from 'react';
import styles from './Experts.module.css';
import truck from '../../assets/Img/png/truck-bottom.png';
import { Title } from '../../../../ui/Title/Title';
import { Button } from '../../../../ui/Button/Button';
import { Arrow } from '../../../../ui/Arrow/Arrow';
import { Gradient } from '../../../../ui/Gradient/Gradient';

export const Experts: FC = () => (
    <div className={styles.experts}>
        <img src={truck} alt="truck" className={styles.expertsImg} />
        <Gradient type="2" />
        <Title type="5">Talk to our experts</Title>
        <Button colorScheme="gradient" size="L">
            Contact us
        </Button>
        <form className={styles.form}>
            <input type="text" placeholder="Enter your email" className={styles.input} />
            <div className={styles.subscribeBlock}>
                <input type="submit" value="Subscribe" className={styles.summitInput} />
                <Arrow />
            </div>
        </form>
    </div>
);
