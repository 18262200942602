import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Header } from '../../components/Header/Header';
import { useAppDispatch } from '../../store/store';
import { Text } from '../../ui/Text/Text';
import { getPublicOffer, selectOfferById } from '../Offers/store/offersSlice';
import { OfferTypesEnum } from 'grpc-era/offer_pb';
import { useSolanaService } from '../../hooks/useSolanaService';
import { Button } from '../../ui/Button/Button';
import { Gradient } from '../../ui/Gradient/Gradient';
import { PredealPicture } from './PredealPicture';
import CircleArrowRight from '../../../../assets/svg/circle-arrow-right.svg';
import { PredealState } from './PredealState';

export const Predeal: FC = () => {
    const dispatch = useAppDispatch();
    const history = useHistory()
    const offerId = Number(useParams<{ offerId: string }>().offerId);
    const offer = useSelector(selectOfferById(offerId));
    const { publicKey } = useSolanaService();

    useEffect(() => {
        let abort = () => {};

        if (!offer) {
            abort = dispatch(getPublicOffer({ offerId })).abort;
        }

        return () => {
            abort();
        };
    }, [offerId, offer, dispatch]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            history.push('/deals')
        }, 10000)

        return () => {
            clearTimeout(timeout)
        }
    }, [history.push])

    const sellerWalletAddress =
        offer?.offerType === OfferTypesEnum.SELL ? offer?.ownerPublicKey : publicKey?.toString();
    const buyerWalletAddress = offer?.offerType === OfferTypesEnum.SELL ? publicKey?.toString() : offer?.ownerPublicKey;

    return (
        <div className="h-full">
            <div className="relative z-1">
                <Gradient type="7" />
            </div>

            <div className="z-10 h-full flex flex-col justify-between gap-1">
                <div className="basis-1/5">
                    <Header />
                </div>

                <div className="basis-1/5 flex relative">
                    <div className="mx-auto">
                        <PredealPicture />
                    </div>
                </div>

                <div className="basis-1/5 relative flex items-center justify-center">
                    <PredealState />
                </div>

                <div className="basis-1/5 flex flex-col gap-4 relative">
                    <div>
                        <div>
                            <Text variant="title">Description</Text>
                        </div>
                        <div className="break-words">
                            <Text className="!text-lg">{offer?.offerData}</Text>
                        </div>
                    </div>

                    <div>
                        <div>
                            <Text variant="title">Seller</Text>
                        </div>
                        <div className="break-words">
                            <Text className="!text-lg">{sellerWalletAddress}</Text>
                        </div>
                    </div>

                    <div>
                        <div>
                            <Text variant="title">Buyer</Text>
                        </div>
                        <div className="break-words">
                            <Text className="!text-lg">{buyerWalletAddress}</Text>
                        </div>
                    </div>
                </div>

                <div className="mt-auto">
                    <Button size="FILL" colorScheme="gradient">
                        <div className="flex justify-between items-center w-full m-4">
                            <Text>My Deals</Text>
                            <img src={String(CircleArrowRight)} alt="circle-arrow-right" />
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    );
};

