import { FC, ReactNode, useCallback } from 'react';
import homeIcon from '../../../../assets/svg/homeNavIcon.svg';
import tradesIcon from '../../../../assets/svg/tradesNavIcon.svg';
import chatIcon from '../../../../assets/svg/chatNavIcon.svg';
import plusIcon from '../../../../assets/svg/plus.svg';
import dollar from '../../../../assets/svg/dollar.svg';
import heads from '../../../../assets/svg/heads.svg';
import other from '../../../../assets/svg/other.svg';
import { useLocation, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { CircleButton } from '../../ui/Button/CircleButton';
import { NewMessageIcon } from '../../ui/NewMessageIcon/NewMessageIcon';
import { useSelector } from 'react-redux';
import { selectHaveNewMessages } from '../../screens/Chats/store/chatSlice';
import { StyledPopover } from '../../ui/StyledPopover/StyledPopover';

type BottomNavigationItemProps = {
    icon: string | ReactNode;
    active?: boolean;
    onClick?: () => void;
    className?: string;
};

const BottomNavigationItem: FC<BottomNavigationItemProps> = ({ icon, active, onClick, className }) => (
    <div
        onClick={onClick}
        className={cn(
            'flex-1 text-center flex first:rounded-tl-xl last:rounded-tr-xl',
            // active && 'border-t-2 border-t-white border-solid',
            className
        )}
    >
        <div className="m-auto">
            <div className={cn(active && 'border-t-2 border-t-white border-solid p-5')}>{icon}</div>
        </div>
    </div>
);

const popoverItems = [
    {
        icon: <img src={String(heads)} alt="else" />,
        text: 'Investors',
        to: 'https://investors.eracrypto.app'
    },
    {
        icon: <img src={String(dollar)} alt="else" />,
        text: 'Referral',
        to: '/earn'
    },
];

export const BottomNavigation: FC = () => {
    const { pathname } = useLocation();
    const history = useHistory();

    const atHome = pathname.includes('/offers');
    const atChat = pathname.includes('/chat');
    const atMy = pathname.includes('/deals');
    const atEarn = pathname.includes('/earn');

    const haveNewMessages = useSelector(selectHaveNewMessages);

    const handleNavigate = useCallback(
        (url: string) => {
            history.push(url);
        },
        [history]
    );

    const chatIconEl = (
        <div className="relative">
            {haveNewMessages && (
                <div className="absolute right-0 left-4 bottom-5">
                    <NewMessageIcon />
                </div>
            )}

            <img src={String(chatIcon)} alt="chat" />
        </div>
    );

    return (
        <div className="fixed bottom-0 flex w-full h-[5rem]">
            <div className="w-full md:max-w-[768px] mx-auto flex w-full bg-[#131C28]">
                <BottomNavigationItem
                    onClick={() => handleNavigate('/offers')}
                    active={atHome}
                    icon={<img src={String(homeIcon)} alt="home" />}
                />
                <BottomNavigationItem
                    onClick={() => handleNavigate('/deals')}
                    active={atMy}
                    icon={<img src={String(tradesIcon)} alt="my" />}
                />
                <BottomNavigationItem
                    className="mt-[-30px]"
                    onClick={() => handleNavigate('/createOffer')}
                    icon={
                        <CircleButton className="bg-gradient flex">
                            <img className="m-auto" src={String(plusIcon)} alt="create-offer" />
                        </CircleButton>
                    }
                />
                <BottomNavigationItem
                    onClick={() => handleNavigate('/chats')}
                    active={atChat}
                    icon={chatIconEl}
                />
                <BottomNavigationItem
                    active={atEarn}
                    icon={<StyledPopover buttonContent={<img src={String(other)} alt="else" />} items={popoverItems} />}
                />
            </div>
        </div>
    );
};
