import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { createTradeRequest } from '../../store/tradeRequestsSlice';
import { MAX_PRICE_OFFER } from '../../../../constants';

export type FormValues = Parameters<typeof createTradeRequest>[0];

const priceMinLimit = 0;

export const resolver = yupResolver(
    Yup.object().shape({
        price: Yup.number()
            .typeError('Price should be a valid number')
            .moreThan(Number(priceMinLimit), `Price should be greater than ${priceMinLimit}`)
            .max(Number(MAX_PRICE_OFFER), `Price should not be more than ${MAX_PRICE_OFFER} dollars`),
        text: Yup.string().required('Please enter offer description'),
    })
);
