import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getPublicGetOfferCategories, selectPublicOfferCategories } from '../../store/slices/publicSlice';
import { LoadingStatusesRecord } from '../../constants';
import { Spinner } from '../../ui/Spinner/Spinner';
import { useAppDispatch } from '../../store/store';

export const PublicDataProvider: FC = ({ children }) => {
    const dispatch = useAppDispatch();
    const { loadingStatus } = useSelector(selectPublicOfferCategories);

    useEffect(() => {
        dispatch(getPublicGetOfferCategories());      
    }, [dispatch]);    

    if (loadingStatus !== LoadingStatusesRecord.Loaded && loadingStatus !== LoadingStatusesRecord.Error) {
        return <Spinner size="large" />;
    }

    return <>{children}</>;
};
