import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { HttpService } from '../services/httpService';
import { selectToken } from '../store/slices/authSlice';

export const useHttpService = () => {
    const token = useSelector(selectToken)
    const httpService = useMemo(() => new HttpService({
        headers: {
            auth: token!
        }
    }), [token]);

    const post = httpService.post

    return { post };
};

