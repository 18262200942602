.wrapper {
    /*padding: 0 20px;*/
    display: flex;
    flex-direction: column;
    /*bottom: 30px;*/
    max-width: 1200px;
    width: 100%;
    /*height: 100%;*/
    /*overflow: auto;*/
}

.messageFromWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.messageToWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.messageFrom {
    max-width: 80vw;
    background: linear-gradient(95.35deg, #8F3DD5 2.01%, #F26997 49.8%, #E03892 101.74%);
    padding: 11px 7px 9px 22px;
    border-radius: 30px 20px 0 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 10px 0;
    display: flex;
}

.messageTo {
    max-width: 80vw;
    margin: 0 0 10px 0;
    display: flex;
    padding: 12px 20px;
    background: #808897;
    border-radius: 20px 30px 30px 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.time {
    display: flex;
    align-items: flex-end;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #C8CEDB;
    margin: 0 13px 0 5px;
}

.form {
    position: relative;
    margin: 0 20px;
}

.input {
    height: 53px;
    width: 100%;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    background-color: transparent;
    border: 1px solid #808897;
    padding: 20px 70px 20px 20px;
    color: #FFFFFF;
    outline: none;
}

.button {
    width: 45px;
    height: 45px;
    background-color: #FFFFFF;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    position: absolute;
    right: 4px;
    top: 4px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
