import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useCookie } from './useCookie';
import { cookieReferralName } from '../constants';

export const useReferral = () => {
    const location = useLocation();
    const { setCookie } = useCookie();

    useEffect(() => {
        const sp = new URLSearchParams(location.search);
        const referralPublicKey = sp.get('ref');

        if (referralPublicKey) {
            setCookie(cookieReferralName, referralPublicKey);
        }
    }, [location.search, setCookie]);
};
