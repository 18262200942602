import { FC } from 'react';
import styles from './Header.module.css';
import { Title } from '../../../../ui/Title/Title';
import { Gradient } from '../../../../ui/Gradient/Gradient';

export const Header: FC = () => (
    <header className={styles.header}>
        <Gradient type="1" />
        <div>
            <span className={styles.title}>era</span>
            <span className={styles.crypto}>crypto</span>
        </div>
        <div className={styles.headerGradient} />
        <Title type="1" tag="h1">
            A new economic universe
        </Title>
    </header>
);
