import { FC } from 'react';
import styles from './EraAbout.module.css';
import { Title } from '../../../../ui/Title/Title';

export const EraAbout: FC = () => (
    <>
        <Title type="2" tag="h2">
            It's time to take your business to a new level
        </Title>
        <p className={styles.description}>
            ERA crypto is a new deal-making app. ERA crypto will save you time with escrow deals, fast and supportive
            interface, speedy transactions
        </p>
    </>
);
