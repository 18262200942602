import { useCallback, useEffect, useRef } from 'react';
import { GrpcService } from '../services/grpcService';
import { updateChat, updateChatMessages, updateChatsSubscriptionStatus } from '../screens/Chats/store/chatSlice';
import { useAppDispatch } from '../store/store';
import { useSelector } from 'react-redux';
import { selectToken } from '../store/slices/authSlice';
import { ChatMessageDTO } from 'grpc-era/chat_pb';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { updateDealsSubscriptionStatus } from '../screens/Deals/store/dealsSlice';
import { SubscriptionStatusesEnum } from '../constants';
import { websocketService } from '../services/wsService';
import { config } from '../../config/config';
import { ChatWsResponseDTO } from '../services/types';

export const useChatsStream = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { pathname } = useLocation();

    const token = useSelector(selectToken);

    const onConnect = useCallback(
        () => dispatch(updateChatsSubscriptionStatus(SubscriptionStatusesEnum.Subscribed)),
        [dispatch]
    );
    const onConnectionFailed = useCallback(
        () => dispatch(updateChatsSubscriptionStatus(SubscriptionStatusesEnum.SubscriptionFailed)),
        [dispatch]
    );

    const newMessageHandlerRef = useRef<(newMessage: ChatWsResponseDTO) => void>();

    useEffect(() => {
        newMessageHandlerRef.current = ({ message, chatId, fromPublicKey }) => {
            if (pathname !== `/chats/${chatId}` && fromPublicKey !== 'system') {
                toast.info(`New message from chat: ${message}`, {
                    theme: 'dark',
                    onClick: () => history.push(`/chats/${chatId}`),
                });
            }
        };
    }, [history, pathname]);

    const messageHandler = useCallback(
        (chatWsResponse: ChatWsResponseDTO) => {
            const { chatId, fromPublicKey, id, message, toPublicKey, createdAt } = chatWsResponse;

            newMessageHandlerRef.current?.(chatWsResponse);

            dispatch(
                updateChat({
                    chatId,
                    updatedAt: '',
                    fromPublicKey,
                    toPublicKey,
                    lastMessage: message,
                    wasRead: false,
                })
            );

            dispatch(
                updateChatMessages({
                    id,
                    chatId,
                    message,
                    fromPublicKey,
                    toPublicKey,
                    createdAt,
                })
            );
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(updateChatsSubscriptionStatus(SubscriptionStatusesEnum.Subscribing));

        const ws = websocketService(config.websocket.chats, messageHandler);
        ws.connect(token, onConnect, onConnectionFailed);

        return () => {
            ws.disconnect();
        };
    }, [dispatch, onConnect, onConnectionFailed, token, messageHandler]);
};
