import { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store/store';
import { Chat } from './components/Chat/Chat';
import { NavLink } from 'react-router-dom';
import * as classes from './Chats.module.css';
import { getAllChats, selectChats, selectChatsLoadingStatus, selectChatsSubscriptionStatus } from './store/chatSlice';
import { LoadingStatusesRecord, SubscriptionStatusesEnum } from '../../constants';
import { Animated } from '../../ui/Animated/Animated';
import { Subheader } from '../../components/Subeader/Subheader';
import { Text } from '../../ui/Text/Text';
import { showAuthModal } from '../../components/Modals/store/modalSlice';
import { selectToken } from '../../store/slices/authSlice';
import { useTranslation } from 'react-i18next';

export const Chats: FC = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const login = useCallback(() => dispatch(showAuthModal()), [dispatch]);
    const token = useSelector(selectToken);
    const chats = useSelector(selectChats);
    const subscriptionStatus = useSelector(selectChatsSubscriptionStatus);
    const loadingStatus = useSelector(selectChatsLoadingStatus);

    useEffect(() => {
        dispatch(getAllChats());
    }, [dispatch, token]);

    const loadingContent = (loadingStatus === LoadingStatusesRecord.Loading ||
        loadingStatus === LoadingStatusesRecord.Initial ||
        subscriptionStatus === SubscriptionStatusesEnum.Subscribing) && (
        <div className="p-5">
            <p>{`${t('Loading')}...`}</p>
        </div>
    );

    const noChats = (
        <div className="p-5">
            <Text>{t('No chats yet')}</Text>
        </div>
    );

    const chatsContent =
        chats && chats.length
            ? chats.map((chat) => (
                  <NavLink key={chat.chatId.toString()} to={`/chats/${chat.chatId}`}>
                      <Chat chat={chat} />
                  </NavLink>
              ))
            : noChats;

    return (
        <>
            <Subheader title="Chats" />
            <Animated>
                <div className="h-full px-5">
                    <div className={classes.wrapper}>
                        {(subscriptionStatus !== SubscriptionStatusesEnum.Subscribed ||
                            loadingStatus === LoadingStatusesRecord.Error) && (
                            <div className="h-full flex">
                                <div className="pl-5 pt-5">
                                    <Text>{`${t('You should')} `}</Text>
                                    <Text className="underline underline-offset-2" onClick={login}>
                                        {t('Login')}
                                    </Text>
                                    <Text>{` ${t('to see your chats')}`}</Text>
                                </div>
                            </div>
                        )}

                        {loadingContent}
                        {loadingStatus === LoadingStatusesRecord.Loaded && chatsContent}
                    </div>
                </div>
            </Animated>
        </>
    );
};
