import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GrpcService } from '../../../services/grpcService';
import { ErrorCodes, LoadingStatusesRecord, SubscriptionStatusesEnum } from '../../../constants';
import { ChatDTO, ChatMessageDTO, SendMessageResponse } from 'grpc-era/chat_pb';
import { RootState } from '../../../store/store';
import { selectToken } from '../../../store/slices/authSlice';
import { LoadingStatuses } from '../../../types/LoadingStatuses';
import { showAuthModal } from '../../../components/Modals/store/modalSlice';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';

export const getAllChats = createAsyncThunk<Array<ChatDTO.AsObject>, void, { state: RootState }>(
    'chats/getChats',
    async (_, { getState, dispatch, rejectWithValue }) => {
        const token = selectToken(getState());

        return GrpcService.getChats(token)
            .then((c) => c.toObject().chatList)
            .catch((error) => {
                if (error) {
                    if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                        dispatch(showAuthModal());
                    } else if (error.name && error.name === ErrorCodes.RPC_ERROR) {
                        dispatch(showAuthModal());
                    }
                }
                return rejectWithValue(error);
            });
    }
);

export const getChatMessages = createAsyncThunk<
    Array<ChatMessageDTO.AsObject>,
    Parameters<typeof GrpcService.getChatMessages>[0],
    { state: RootState }
>('chats/getChatMessages', async (input, { getState, dispatch, fulfillWithValue }) => {
    const token = selectToken(getState());

    return GrpcService.getChatMessages(input, token)
        .then((c) => {
            fulfillWithValue(input.chatId);
            return c.toObject().messageList;
        })
        .catch((error) => {
            if (error) {
                if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                    dispatch(showAuthModal());
                } else if (error.name && error.name === ErrorCodes.RPC_ERROR) {
                    dispatch(showAuthModal());
                }
            }

            return Promise.reject(error);
        });
});

export const sendMessage = createAsyncThunk<
    SendMessageResponse.AsObject,
    Parameters<typeof GrpcService.sendMessageGrpc>[0],
    { state: RootState }
>('chats/sendMessage', async (input, { getState, dispatch }) => {
    const token = selectToken(getState());

    return GrpcService.sendMessageGrpc(input, token)
        .then((c) => c.toObject())
        .catch((error) => {
            if (error) {
                if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                    dispatch(showAuthModal());
                } else if (error.name && error.name === ErrorCodes.RPC_ERROR) {
                    dispatch(showAuthModal());
                }
            }

            return Promise.reject(error);
        });
});

type State = {
    chats: Array<ChatDTO.AsObject>;
    messages: Array<ChatMessageDTO.AsObject>;
    openedChatId: number;
    chatsLoadingStatus: LoadingStatuses;
    messagesLoadingStatus: LoadingStatuses;
    haveNewMessages: boolean;
    subscriptionStatus: SubscriptionStatusesEnum;
};

const initialState: State = {
    chats: [],
    messages: [],
    openedChatId: 0,
    chatsLoadingStatus: LoadingStatusesRecord.Initial,
    messagesLoadingStatus: LoadingStatusesRecord.Initial,
    haveNewMessages: false,
    subscriptionStatus: SubscriptionStatusesEnum.Initial,
};

export const chatSlice = createSlice({
    name: 'chats',
    initialState,
    reducers: {
        updateChat(draftState, { payload: { chatId, lastMessage, wasRead } }: PayloadAction<ChatDTO.AsObject>) {
            const chat = draftState.chats.find(({ chatId: currentChatId }) => currentChatId === chatId);

            if (chat) {
                chat.lastMessage = lastMessage;
                chat.wasRead = wasRead;
            }
        },
        updateChatMessages(draftState, { payload }: PayloadAction<ChatMessageDTO.AsObject>) {
            draftState.openedChatId === payload.chatId && draftState.messages.unshift(payload);
        },
        dropOpenedChatId(draftState) {
            draftState.openedChatId = 0;
        },
        haveNewMessages(draftState, { payload }: PayloadAction<boolean>) {
            draftState.haveNewMessages = payload;
        },
        updateChatsSubscriptionStatus(draftState, { payload }: PayloadAction<SubscriptionStatusesEnum>) {
            draftState.subscriptionStatus = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // .addCase(getHaveNewMessages.pending, (draftState, { meta: { requestStatus } }) => {
            //     draftState.chatsLoadingStatus = requestStatus;
            // })
            // .addCase(getHaveNewMessages.fulfilled, (draftState, { payload: { wasRead }, meta: { requestStatus } }) => {
            //     draftState.chatsLoadingStatus = requestStatus;
            //     draftState.haveNewMessages = !wasRead;
            // })
            // .addCase(getHaveNewMessages.rejected, (draftState, { meta: { requestStatus } }) => {
            //     draftState.haveNewMessages = false;
            //     draftState.chatsLoadingStatus = requestStatus;
            // })
            .addCase(getAllChats.pending, (draftState, { meta: { requestStatus } }) => {
                draftState.chatsLoadingStatus = requestStatus;
            })
            .addCase(getAllChats.fulfilled, (draftState, { payload, meta: { requestStatus } }) => {
                draftState.chatsLoadingStatus = requestStatus;
                draftState.chats = payload;
            })
            .addCase(getAllChats.rejected, (draftState, { meta: { requestStatus } }) => {
                draftState.chatsLoadingStatus = requestStatus;
            })
            .addCase(getChatMessages.pending, (draftState, { meta: { requestStatus } }) => {
                draftState.chatsLoadingStatus = requestStatus;
            })
            .addCase(getChatMessages.fulfilled, (draftState, { payload, meta: { requestStatus, arg } }) => {
                draftState.chatsLoadingStatus = requestStatus;
                draftState.messages = payload;
                draftState.openedChatId = arg.chatId;
            })
            .addCase(getChatMessages.rejected, (draftState, { meta: { requestStatus } }) => {
                draftState.chatsLoadingStatus = requestStatus;
            })
            .addCase(
                LOCATION_CHANGE,
                (
                    draftState,
                    {
                        payload: {
                            location: { pathname },
                        },
                    }: LocationChangeAction
                ) => {
                    return pathname.startsWith('/chats') ? { ...draftState, messages: [] } : undefined;
                }
            );
    },
});

export const { updateChat, updateChatMessages, dropOpenedChatId, haveNewMessages, updateChatsSubscriptionStatus } =
    chatSlice.actions;

const selectSlice = ({ chats }: RootState) => chats;

export const selectChats = createSelector(selectSlice, ({ chats }) => chats);

export const selectOpenedChat = createSelector(selectSlice, ({ chats, openedChatId }) =>
    chats.find(({ chatId }) => chatId === openedChatId)
);

export const selectChatsLoadingStatus = createSelector(selectSlice, ({ chatsLoadingStatus }) => chatsLoadingStatus);

export const selectMessages = createSelector(selectSlice, ({ messages }) => messages);
export const selectHaveNewMessages = createSelector(selectSlice, ({ haveNewMessages }) => haveNewMessages);

export const selectMessagesLoadingStatus = createSelector(
    selectSlice,
    ({ messagesLoadingStatus }) => messagesLoadingStatus
);

export const selectChatsSubscriptionStatus = createSelector(
    selectSlice,
    ({ subscriptionStatus }) => subscriptionStatus
);
