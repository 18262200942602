import { FC, ReactNode } from 'react';
import { Popover } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { navigate } from '../../helpers/navigate';
import {Text} from "../Text/Text";

type Props = {
    buttonContent: ReactNode;
    items: {
        icon: ReactNode;
        text: string;
        to: string;
    }[];
};

export const StyledPopover: FC<Props> = ({ buttonContent, items }) => {
    return (
        <Popover>
            <Popover.Button>{buttonContent}</Popover.Button>

            <Popover.Overlay className="fixed inset-0 bg-black opacity-50" />

            <Popover.Panel className="fixed z-10 left-0 w-full bottom-[15%]">
                {({ close }) => (
                    <div className="p-10">
                        <div className="grid grid-cols-3 gap-y-4 bg-[#131C28] py-5 rounded-3xl">
                            {items.map((item, index: number) => (
                                <Link
                                    key={index}
                                    to={item.to}
                                    onClick={(evt) => {
                                        if (item.to.startsWith('http')) {
                                            evt.preventDefault();
                                            navigate(item.to);
                                        } else {
                                            close();
                                        }
                                    }}
                                    className="flex flex-col gap-4"
                                >
                                    <div className="mx-auto w-5 h-4">{item.icon}</div>
                                    <div className="mx-auto">{<Text>{item.text}</Text>}</div>
                                </Link>
                            ))}
                        </div>
                    </div>
                )}
            </Popover.Panel>
        </Popover>
    );
};
