import { year } from '../constants';
import { useCallback } from 'react';

const isProduction = false;

const defaultCookieOptions = ['path=/', 'samesite=strict', isProduction && 'secure'].filter(Boolean).join(';');

export const useCookie = () => {
    const setCookie = useCallback((name: string, value: string) => {
        const date = new Date(Date.now() + year);
        const UTCDate = date.toUTCString();

        document.cookie = [
            encodeURIComponent(name) + '=' + encodeURIComponent(value),
            defaultCookieOptions,
            `expires=${UTCDate}`,
        ].join(';');
    }, []);

    const getCookie = useCallback((name: string) => {
        const matches = document.cookie.match(
            new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
        );
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }, []);

    return {
        setCookie,
        getCookie
    };
};
