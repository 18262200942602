import { FC, useCallback, useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getDeals, selectDealById, selectDealsSubscriptionStatus } from '../../store/dealsSlice';
import { useSelector } from 'react-redux';
import { Spinner } from '../../../../ui/Spinner/Spinner';
import { ErrorMessage } from '../../../../ui/ErrorMessage/ErrorMessage';
import { Title } from '../../../../ui/Title/Title';
import { Button } from '../../../../ui/Button/Button';
import { useSolanaService } from '../../../../hooks/useSolanaService';
import { DealDTO, DealStatusEnum } from 'grpc-era/deal_pb';
import { Stepper } from '../../../../ui/Stepper/Stepper';
import { SubscriptionStatusesEnum } from '../../../../constants';
import { sendMessage } from '../../../Chats/store/chatSlice';
import { useAppDispatch } from '../../../../store/store';
import { Text } from '../../../../ui/Text/Text';
import { useBooleanState } from '../../../../hooks/useBooleanState';
import { Animated } from '../../../../ui/Animated/Animated';
import { useTranslation } from 'react-i18next';
import { navigate } from '../../../../helpers/navigate';
import { config } from '../../../../../config/config';
import complainIcon from '../../../../../../assets/svg/complain.svg';

enum DealStepsEnum {
    Created = 'Created',
    Approved = 'Approved',
    Paid = 'Paid',
    Cancelled = 'Cancelled',
}

const dealStepsByDealStatuses: Required<Record<DealStatusEnum, DealStepsEnum>> = {
    [DealStatusEnum.NEW]: DealStepsEnum.Created,
    [DealStatusEnum.BUYER_APPROVED]: DealStepsEnum.Approved,
    [DealStatusEnum.BUYER_FINISHED]: DealStepsEnum.Paid,
    [DealStatusEnum.JUDGE_FINISHED]: DealStepsEnum.Paid,
    [DealStatusEnum.JUDGE_CANCELED]: DealStepsEnum.Cancelled,
    [DealStatusEnum.BUYER_CANCELED]: DealStepsEnum.Cancelled,
};

export const generateDealStepsData = (deal: DealDTO.AsObject) => {
    const activeStepId = dealStepsByDealStatuses[deal.status];

    const steps =
        activeStepId === DealStepsEnum.Cancelled
            ? [
                  { label: 'Preview', id: DealStepsEnum.Created },
                  { label: 'Cancelled', id: DealStepsEnum.Cancelled },
              ]
            : [
                  { label: 'Preview', id: DealStepsEnum.Created },
                  { label: 'In progress', id: DealStepsEnum.Approved },
                  { label: 'Paid', id: DealStepsEnum.Paid },
              ];

    return { activeStep: activeStepId, steps };
};

export const Deal: FC = () => {
    const {
        state: actionButtonsState,
        setFalse: enableActionButtons,
        setTrue: disableActionButtons,
    } = useBooleanState();

    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const history = useHistory();

    const dealId = Number(useParams<{ id: string }>().id);

    const deal = useSelector(selectDealById(dealId));

    useEffect(() => {
        if (!deal) {
            dispatch(getDeals());
        }
    }, [deal, dispatch]);

    const subscriptionStatus = useSelector(selectDealsSubscriptionStatus);

    const { cancelEscrow, approveEscrow, releaseEscrow, publicKey } = useSolanaService();

    const onCancelEscrowButtonClick = useCallback(() => {
        disableActionButtons();
        if (deal) {
            cancelEscrow(deal.escrowAccount).finally(() => enableActionButtons());
        }
    }, [cancelEscrow, deal, disableActionButtons, enableActionButtons]);

    const onApproveEscrowButtonClick = useCallback(() => {
        disableActionButtons();
        if (deal) {
            approveEscrow(deal.sellerPublicKey, deal.escrowAccount).finally(() => enableActionButtons());
        }
    }, [approveEscrow, deal, enableActionButtons, disableActionButtons]);

    const onReleaseEscrowButtonClick = useCallback(() => {
        disableActionButtons();
        if (deal) {
            releaseEscrow(deal.sellerPublicKey, deal.escrowAccount).finally(() => enableActionButtons());
        }
    }, [releaseEscrow, deal, enableActionButtons, disableActionButtons]);

    const openChat = useCallback(
        async (to: string) => {
            const result = await dispatch(sendMessage({ to, message: '' }));

            sendMessage.fulfilled.match(result) && history.push(`/chats/${result.payload.chatId}`);
        },
        [dispatch, history]
    );

    // TODO FIX TYPES AFTER REMOVE GRPC COMPLETELY
    const dealStepsData = useMemo(() => deal && generateDealStepsData(deal), [deal]);

    if (subscriptionStatus === SubscriptionStatusesEnum.Subscribing) {
        return <Spinner showText={true} />;
    }

    if (subscriptionStatus === SubscriptionStatusesEnum.SubscriptionFailed) {
        return <ErrorMessage message={t("Couldn't load this deal")!} />;
    }

    if (!deal || !publicKey) {
        return null;
    }

    const handleComplainClick = () => {
        navigate(config.complainLink);
    };

    return (
        <Animated>
            <div>
                {dealStepsData && (
                    <Stepper steps={dealStepsData?.steps} activeStepId={dealStepsData?.activeStep} className="mt-12" />
                )}

                <Title type="6" className="mt-2">
                    {deal.offerHeader}
                </Title>

                <div className="mt-5">
                    <p className="font-bold text-sm leading-4 text-brand-600">{`${t('Deal')} ${t('price')}:`}</p>

                    <Title type="6" className="mt-2">
                        $ {deal.amount}
                    </Title>
                </div>

                <div className="mt-5">
                    <p className="font-bold text-sm leading-4 text-brand-600">{t('Description')}:</p>

                    <Text>{deal.offerData}</Text>
                </div>

                <div className="mt-5">
                    <p className="font-bold text-sm leading-4 text-brand-600">{t('Seller')}:</p>

                    <Text className="mt-2 text-sm leading-4 break-all">{deal.sellerPublicKey}</Text>
                </div>

                <div className="mt-5">
                    <p className="font-bold text-sm leading-4 text-brand-600">{t('Buyer')}:</p>

                    <Text className="mt-2 text-sm leading-4 break-all">{deal.buyerPublicKey}</Text>
                </div>

                {dealStepsData?.activeStep === 'Created' && (
                    <div className="mt-5">
                        <Text className="text-base leading-4">{t('DealState-Created')}</Text>
                    </div>
                )}

                {dealStepsData?.activeStep === 'Cancelled' && (
                    <div className="mt-5">
                        <Text className="text-base leading-4">{t('DealState-Cancelled')}</Text>
                    </div>
                )}

                {dealStepsData?.activeStep === 'Approved' && (
                    <div className="mt-5">
                        <Text className="text-base leading-4">{t('DealState-Approved')}</Text>
                    </div>
                )}

                {dealStepsData?.activeStep === 'Paid' && (
                    <div className="mt-5">
                        <Text className="text-base leading-4">{t('DealState-Paid')}</Text>
                    </div>
                )}

                <div className="mt-5">
                    <button onClick={handleComplainClick} className="flex justify-start items-center gap-2">
                        <img src={String(complainIcon)} alt="complain" />
                        <Text className="text-[#808897]">{t('Complain')}</Text>
                    </button>
                </div>

                <div className="mt-8">
                    {publicKey.toString() === deal.buyerPublicKey && (
                        <>
                            {deal.status === DealStatusEnum.NEW && (
                                <>
                                    <Button
                                        size="XL"
                                        colorScheme="gradient"
                                        block
                                        onClick={onApproveEscrowButtonClick}
                                        disabled={actionButtonsState}
                                        loading={actionButtonsState}
                                    >
                                        {t('Approve')}
                                    </Button>

                                    <Button
                                        size="XL"
                                        colorScheme="gray"
                                        className="mt-2.5"
                                        block
                                        onClick={onCancelEscrowButtonClick}
                                        disabled={actionButtonsState}
                                        loading={actionButtonsState}
                                    >
                                        {t('Cancel')}
                                    </Button>
                                </>
                            )}

                            {deal.status === DealStatusEnum.BUYER_APPROVED && (
                                <Button
                                    size="XL"
                                    colorScheme="gradient"
                                    block
                                    onClick={onReleaseEscrowButtonClick}
                                    disabled={actionButtonsState}
                                    loading={actionButtonsState}
                                >
                                    {t('Release')}
                                </Button>
                            )}
                        </>
                    )}

                    <Button
                        size="XL"
                        colorScheme="gray"
                        className="mt-2.5"
                        block
                        onClick={() =>
                            openChat(
                                publicKey.toString() === deal.sellerPublicKey
                                    ? deal.buyerPublicKey
                                    : deal.sellerPublicKey
                            )
                        }
                    >{`${t('Talk to')} ${
                        publicKey.toString() === deal.sellerPublicKey ? t('Buyer') : t('Seller')
                    }`}</Button>
                </div>
            </div>
        </Animated>
    );
};

