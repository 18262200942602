.footer {
    margin-bottom: 45px;
}
.footerWrapper {
    display: flex;
    padding: 0 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    opacity: .5;
    justify-content: space-between;
    cursor: pointer;
}
a {
    color: #fff;
}

