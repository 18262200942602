import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './slices/authSlice';
import { chatSlice } from '../screens/Chats/store/chatSlice';
import { offersSlice } from '../screens/Offers/store/offersSlice';
import { useDispatch } from 'react-redux';
import { tradeRequestsSlice } from '../screens/Offers/store/tradeRequestsSlice';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { dealsSlice } from '../screens/Deals/store/dealsSlice';
import { modalSlice } from '../components/Modals/store/modalSlice';
import { publicSlice } from './slices/publicSlice';

export const browserHistory = createBrowserHistory();

const reducer = {
    router: connectRouter(browserHistory),
    [authSlice.name]: authSlice.reducer,
    [chatSlice.name]: chatSlice.reducer,
    [offersSlice.name]: offersSlice.reducer,
    [tradeRequestsSlice.name]: tradeRequestsSlice.reducer,
    [dealsSlice.name]: dealsSlice.reducer,
    [modalSlice.name]: modalSlice.reducer,
    [publicSlice.name]: publicSlice.reducer,
};

export const store = configureStore({
    reducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
