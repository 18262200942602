import { getPublicOffers } from '../../store/offersSlice';
import { SortingByEnum, SortingDirectionsEnum } from 'grpc-era/offer_pb';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MAX_PRICE_OFFER } from '../../../../constants';

export type FormValues = Omit<Parameters<typeof getPublicOffers>[0], 'lastId'>;

export const debouncedValues: Array<keyof FormValues> = ['minPrice', 'maxPrice', 'search'];

export const resolver = yupResolver(
    Yup.object().shape({
        minPrice: Yup.number()
            .typeError('Price should be a valid number')
            .min(Number(0), 'Price cannot be less than zero')
            .max(Number(MAX_PRICE_OFFER), 'Price cannot be more than five thousand dollars'),
        maxPrice: Yup.number()
            .typeError('Price should be a valid number')
            .min(Number(0), 'Price cannot be less than zero')
            .max(Number(MAX_PRICE_OFFER), 'Price cannot be more than five thousand dollars'),
    })
);
