import { FC } from 'react';
import arrow from '../../../../assets/svg/arrow-right.svg';
import styles from './Arrow.module.css';
import classNames from 'classnames';

type Props = {
    direction?: 'left';
    className?: string;
};

export const Arrow: FC<Props> = ({ direction }) => {
    return (
        <a href="src/shared/components/Arrow/Arrow#">
            <img
                src={String(arrow)}
                alt="arrow"
                className={classNames(styles.wrapper, {
                    [styles.left]: direction === 'left',
                })}
            />
        </a>
    );
};
