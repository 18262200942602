import { config } from '../config/config';

export const hostName = config.hostName;
export const apiUrl = config.apiUrl;
export const uploadUrl = config.uploadUrl;

export const bytesInMegabyte = 1048576;

export const oneDay = 86400e3;
export const year = oneDay * 365;
export const cookieReferralName = 'eraReferral';

export const ErrorCodes = {
    FORBIDDEN: 7,
    RPC_ERROR: 'RpcError',
    TOKEN_ACCOUNT_NOT_FOUND: 'TokenAccountNotFoundError'
};

export const LoadingStatusesRecord = {
    Loading: 'pending',
    Loaded: 'fulfilled',
    Error: 'rejected',
    Initial: 'initial',
} as const;

export enum SubscriptionStatusesEnum {
    Initial = 'Initial',
    Subscribing = 'Subscribing',
    Subscribed = 'Subscribed',
    Unsubscribed = 'Unsubscribed',
    SubscriptionFailed = 'SubscriptionFailed',
}

export enum LocalStorageKeysEnum {
    Token = 'token',
    ApplicationLocal = 'era_app_local',
}

export const REFRESH_SUBSCRIPTION_INTERVALS = {
    DEALS: 2000,
    RECONNECT: 1000
}

export const MAX_PRICE_OFFER = 999999999 // Number.MAX_SAFE_INTEGER
