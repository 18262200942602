import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GrpcService } from '../../services/grpcService';
import { LoadingStatusesRecord, LocalStorageKeysEnum } from '../../constants';
import { RootState } from '../store';
import { LoadingStatuses } from '../../types/LoadingStatuses';

export const login = createAsyncThunk(
    'login',
    async ({
        signature,
        publicKey,
        nonce,
        authPhrase,
        refParentUserPublicKey,
        saveToken,
    }: Parameters<typeof GrpcService.login>[0] & { saveToken: (token: string) => void }) => {
        const token = (
            await GrpcService.login({ signature, publicKey, nonce, authPhrase, refParentUserPublicKey })
        ).getAuthHeader();

        saveToken(token);

        return { token };
    }
);

export const getNonce = createAsyncThunk('getNonce', async () => (await GrpcService.fetchNonceGrpc()).toObject());

type State = {
    token: string | null;
    loadingStatus: LoadingStatuses;
};

const initialState: State = {
    token: JSON.parse(localStorage.getItem(LocalStorageKeysEnum.ApplicationLocal) || '{}').token,
    loadingStatus: LoadingStatusesRecord.Initial,
};

export const authSlice = createSlice({
    name: 'authData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            })
            .addCase(login.fulfilled, (draftState, { payload: { token }, meta: { requestStatus } }) => {
                // localStorage.setItem(LocalStorageKeysEnum.Token, token);

                // draftState.showAuthModal = false;
                draftState.token = token;
                draftState.loadingStatus = requestStatus;
            })
            .addCase(login.rejected, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
                // draftState.showAuthModal = true;
            })
            .addCase(getNonce.pending, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            })
            .addCase(getNonce.fulfilled, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            })
            .addCase(getNonce.rejected, (draftState, { meta: { requestStatus } }) => {
                // draftState.showAuthModal = true;
                draftState.loadingStatus = requestStatus;
            });
    },
});

const selectSlice = ({ authData }: RootState) => authData;

export const selectToken = createSelector(selectSlice, ({ token }) => token);
export const selectAuthDataLoadingStatus = createSelector(selectSlice, ({ loadingStatus }) => loadingStatus);

