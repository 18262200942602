.wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: #FFFFFF;
    padding: 0 8px;
    margin: 9px 0 0 0;
}

.id {
    width: 56px !important;
    height: 56px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #131C28;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #808897;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid rgba(128, 136, 151, 0.1);
    padding: 0 0 12px 0;
    margin: 0 10px;
    width: 100%;
    height: 100%;
}

.text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #808897;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.messageWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    text-align: left;
}

.chatId {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
    margin: 8px 0 0;
}

.dateWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
}

.date {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #808897;
}

.newMessage {
    background: linear-gradient(95.35deg, #8F3DD5 2.01%, #F26997 49.8%, #E03892 101.74%);
    width: 15px;
    height: 15px;
    -webkit-border-radius: 75%;
    -moz-border-radius: 75%;
    border-radius: 75%;
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    color: #F1F3F7;
    display: flex;
    align-items: center;
    justify-content: center;
}
