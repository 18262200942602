import { FC, useEffect, useState } from 'react';
import { Text } from '../../ui/Text/Text';
import { Button } from '../../ui/Button/Button';
import { useApplicationLocalState } from '../../hooks/useApplicationLocalState';

const forbiddenCountries = ['Algeria', 'Morocco', 'Bolivia', 'Egypt', 'Vietnam', 'the USA', 'Nepal', 'Colombia'];

export const SelectCountry: FC = () => {
    const [show, setShow] = useState(false); 

    const { context, setReadOnly } = useApplicationLocalState()

    useEffect(() => {
        if (context?.readOnly) {
            setShow(true)
        } else {
            setShow(false)
        }

    }, [context?.readOnly, setShow])

    const handleIAm = () => {
        setShow(false)
    };

    const handleIAmNot = () => {
        setReadOnly?.(false);
    };
    
    if (!show) {
        return null
    }

    return (
        <div className="fixed left-[50%] top-[1%] bg-white w-[335px] min-h-[117px] ml-[-170px] rounded-lg p-4 z-10">
            <div className="flex flex-col gap-5">
                <Text className="text-black font-bold">Please mind that ERA crypto currently is not available in the following countries</Text>
                
                <ul>
                    {forbiddenCountries.map((country, index) => (
                        <li key={index}>
                            <Text className="text-slate-600">{country}</Text>
                        </li>
                    ))}
                </ul>

                <Text className="text-black font-bold">Are you a citizen of one of the above listed countries?</Text>
            </div>
            <div className="mt-4 flex gap-6 justify-between">
                <Button colorScheme='gradient' onClick={handleIAmNot}>
                    I AM NOT
                </Button>
                <Button colorScheme='slate' onClick={handleIAm}>
                    I AM
                </Button>
            </div>
        </div>
    );
};

