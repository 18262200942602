.wrapper {
    filter: blur(60px);
    position: absolute;
    /*z-index: -1;*/
    background: radial-gradient(52.02% 42.66% at 50.13% 57.65%, rgba(250, 134, 156, 0.96) 0%, rgba(250, 134, 156, 0) 93.16%),
    radial-gradient(41.14% 25.08% at 55.47% 53.63%, rgba(42, 101, 171, 0.9) 0%, rgba(42, 101, 171, 0.623243) 0%, rgba(42, 101, 171, 0) 100%),
    radial-gradient(60.41% 31.11% at 39.47% 63.83%, rgba(237, 31, 154, 0.9) 0%, rgba(237, 31, 154, 0) 95.43%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(70.28% 57.81% at 50.13% 57.65%, rgba(165, 45, 110, 0.9) 0%, rgba(165, 45, 110, 0.429933) 52.23%, rgba(165, 45, 110, 0) 100%), radial-gradient(51.3% 27.2% at 66.13% 58.58%, rgba(62, 36, 170, 0.9) 6.57%, rgba(62, 36, 170, 0) 99.16%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    opacity: 1;
    border: none;
}

.style1 {
    top: -65%;
    left: 8%;
    width: 77%;
    height: 560px;
}

.style2 {
    top: 31%;
    left: 35%;
    width: 45%;
    height: 200px;
}

.style3 {
    width: 55%;
    height: 127%;
    top: 25%;
    left: 12%;
}

.style4 {
    top: -143%;
    left: 7%;
    width: 85%;
    height: 600px;
}

.style5 {
    top: -20%;
    left: 7%;
    width: 85%;
    height: 600px;
}

.style6 {
    top: -40%;
    left: -15%;
    width: 95%;
    height: 600px;
}

.style7 {
    top: -30%;
    left: -10%;
    width: 75%;
    height: 450px;
}

