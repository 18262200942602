{
    "translation": {
        "Login": "Войти",
        "Offers": "Объявления",
        "Offer": "Объявление",
        "Buy": "Купить",
        "Sell": "Продать",
        "Search": "Найти",
        "Price": "Цена",
        "price": "цена",
        "CONNECT WALLET": "ПОДКЛЮЧИТЬ КОШЕЛЕК",
        "Free connect": "Подключение кошелька бесплатно! Кошелек нужен только для входа в систему.",
        "Use your solana wallet": "Используйте свой кошелек Solana для самых быстрых сделок в мире.",
        "Each deal is safe": "Каждая сделка безопасна.",
        "Your money is returned if the deal fails": "Ваши деньги вернутся, если сделка не состоялась.",
        "Accept": "Принять",
        "Decline": "Отклонить",
        "Cookie ensure": "ERA crypto использует файлы cookie для улучшения вашей работы в приложении ERA crypto",
        "and": "и",
        "Deals": "Сделки",
        "Deal": "Сделка",
        "Status": "Статус",
        "Who wins?": "Кто прав?",
        "Seller": "Продавец",
        "Buyer": "Покупатель",
        "Loading": "Загрузка",
        "No chats yet": "Пока нет ни одного чата",
        "You should": "Вам следует",
        "You need": "Вам необходимо",
        "to see your chats": "посмотреть ваши чаты",
        "to see your deals": "посмотреть ваши сделки",
        "before access this page": "перед тем как зайти на эту страницу",
        "Chat": "Чат",
        "Wallet": "Кошелек",
        "Your message": "Ваше сообщение",
        "Couldn't load this deal": "Не удалось загрузить эту сделку. Пожалуйста, попробуйте обновить страницу и проверьте корректность ссылки.",
        "Couldn't load this offer": "Не удалось загрузить это объявление. Пожалуйста, попробуйте обновить страницу и проверьте корректность ссылки.",
        "Couldn't load offers": "Не удалось загрузить объявления. Пожалуйста, попробуйте обновить страницу",
        "Couldn't find any offers": "Не удалось найти ни одного объявления. Пожалуйста, попробуйте другие настройки",
        "Description": "Описание",
        "DealState-Created": "Сделка началась. Деньги хранятся в блокчейне. Продавец ждет, когда покупатель подтвердит сделку.",
        "DealState-Cancelled": "Сделка отменена покупателем. Деньги возвращены покупателю.",
        "DealState-Approved": "Сделка создана. Деньги хранятся в блокчейне. Покупатель подтвердил сделку. Продавец может начать поставку.",
        "DealState-Paid": "Сделка завершена. Деньги отправлены продавцу.",
        "Approve": "Подтвердить",
        "Cancel": "Отменить",
        "Release": "Отпустить",
        "Talk to buyer": "НАПИСАТЬ ПОКУПАТЕЛЮ",
        "Talk to seller": "НАПИСАТЬ ПРОДАВЦУ",
        "No deals": "Вы еще не начали ни одной сделки",
        "Proceed to": "Начать",
        "Earn with ERA Crypto!": "Заработайте с ERA Crypto!",
        "earnPercent": "Вы получите {{percent}}% комиссии с каждой сделки реферала, если поделитесь этой ссылкой с другом",
        "Your referral link": "Ваша реферальная ссылка",
        "New offer": "Новое объявление",
        "Own offer": "Мое объявление",
        "Offer name": "Название",
        "Offer price": "Цена",
        "Offer description": "Описание",
        "Details": "Детали",
        "Upload": "Загрузить",
        "photo": "фото",
        "Create": "СОЗДАТЬ",
        "to ensure this transaction": "для этой сделки",
        "approximately": "примерно",
        "Start Deal": "НАЧАТЬ СДЕЛКУ",
        "Delete Offer": "УДАЛИТЬ ОБЪЯВЛЕНИЕ",
        "Sellers price offers": "Ценовые предложения продавцов",
        "Suggested price": "Предлагаемая цена",
        "Product does not have a name": "Продукт не имеет названия",
        "delivery": "Доставка",
        "building materials": "Стройматериалы",
        "clothing": "Одежда, аксессуары",
        "computers and smartphones": "Компьютеры, смартфоны",
        "digital goods": "Цифровые товары",
        "equipment": "Оборудование",
        "food": "Еда",
        "games": "Игры",
        "jeweller": "Украшения",
        "jewellery": "Украшения",
        "real estate": "Недвижимость",
        "real food": "Эко продукты",
        "services": "Услуги",
        "software": "Программное обеспечение",
        "tobacco": "Табачные изделия",
        "vehicles": "Транспорт",
        "all": "Показать все",
        "Share": "Поделиться",
        "In descending order": "В порядке убывания",
        "In ascending order": "В порядке возрастания",
        "Date of creation": "Дата создания",
        "Sort direction": "Направление сортировки",
        "You have enough money to start the deal": "У вас достаточно монет, чтобы начать сделку",
        "firstState": "Запрос отправлен в блокчейн, ожидается создание сделки",
        "secondState": "Получено подтверждение от блокчейн, формирование сделки одобрено",
        "thirdState": "Формируется сделка"
    }
}
