import { ReactElement } from 'react';
import { RadioGroup } from '@headlessui/react';
import { Control, Controller, Path } from 'react-hook-form';
import classNames from 'classnames';
import { CheckIcon } from '@heroicons/react/solid';

export const RadioButtons = <ValueType, FormValues>({
    options,
    control,
    name,
    className,
    label,
}: {
    options: Array<{ label: string; value: ValueType }>;
    control: Control<FormValues>;
    name: Path<FormValues>;
    className?: string;
    label?: string;
}): ReactElement => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <div className={classNames('w-full', className)}>
                <div className="mx-auto w-full">
                    <RadioGroup value={field.value} onChange={field.onChange}>
                        {label && <RadioGroup.Label className="mb-4 block">{label}</RadioGroup.Label>}
                        <div className="space-y-2">
                            {options.map(({ label, value }) => (
                                <RadioGroup.Option
                                    key={label}
                                    value={value}
                                    className={({ checked }) =>
                                        classNames(
                                            'relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none h-[46]',
                                            {
                                                ['bg-white']: checked,
                                                ['border border-solid border-brand-600 bg-transparent']: !checked,
                                            }
                                        )
                                    }
                                >
                                    {({ checked }) => (
                                        <>
                                            <div className="flex w-full items-center justify-between">
                                                <div className="flex items-center">
                                                    <div className="text-sm">
                                                        <RadioGroup.Label
                                                            as="p"
                                                            className={`font-medium  ${
                                                                checked ? 'text-brand-800' : 'text-white'
                                                            }`}
                                                        >
                                                            {label}
                                                        </RadioGroup.Label>
                                                    </div>
                                                </div>
                                                {checked && (
                                                    <div className="shrink-0 text-brand-700">
                                                        <CheckIcon className="h-5 w-5" />
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </RadioGroup>
                </div>
            </div>
        )}
    />
);
