// TODO ADD ERROR NOTIFICATION TO TRACKER !

import {TokenError} from "@solana/spl-token";

const someErrorTracker = {
    notify: (error: any) => console.error(error)
}

export interface IErrorService {
    notify: (error: any) => void
}

export class ErrorService implements IErrorService {
    private tracker: typeof someErrorTracker

    constructor() {
        this.tracker = someErrorTracker
    }

    notify(error: any) {
        this.tracker.notify(error)
    }
}

export class PublicKeyNotFound extends TokenError {
    name = 'PublicKeyNotFound';
}
