import { FC, useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import CircleArrowRight from '../../../../../assets/svg/circle-arrow-right.svg';
import Free from '../../../../../assets/svg/free.svg';
import Fasty from '../../../../../assets/svg/fasty.svg';
import Lock from '../../../../../assets/svg/lock.svg';
import { Text } from '../../../ui/Text/Text';
import { useSolanaService } from '../../../hooks/useSolanaService';
import { closeAuthModal } from '../store/modalSlice';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Modal } from '../../../ui/Modal/Modal';
import { useAppDispatch } from '../../../store/store';
import { Gradient } from '../../../ui/Gradient/Gradient';
import { Header } from '../../Header/Header';

const Block: FC<{ className?: string }> = ({ children, className }) => {
    return (
        <div className={cn('border-solid border-2 rounded-xl border-[#808897] p-5 flex flex-col', className)}>
            {children}
        </div>
    );
};

export const AuthModal: FC = () => {
    const [shouldSignIn, setShouldSignIn] = useState(false);

    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const { signInWallet, publicKey } = useSolanaService();

    const handleClose = useCallback(() => dispatch(closeAuthModal()), [dispatch]);

    const toggleShouldSignIn = () => setShouldSignIn((c) => !c);

    useEffect(() => {
        (async () => {
            if (publicKey && shouldSignIn) {
                await signInWallet().finally(() => {
                    toggleShouldSignIn();
                    handleClose();
                });
            }
        })();
    }, [publicKey, signInWallet, shouldSignIn, handleClose]);

    return (
        <Modal open={true} onClose={handleClose} fullHeight>
            <Gradient type="6" />
            <div className="z-[70]">
                <Header />
            </div>
            <div className="h-full z-[1]">
                <div className="h-full flex flex-col gap-5">
                    <div className="flex-1" />

                    <div className="flex">
                        <div className="mx-auto mt-auto">
                            <div className="text-center">
                                <Text className="text-3xl font-bold">{t('Login')}</Text>
                            </div>
                        </div>
                    </div>

                    <WalletMultiButton
                        className="bg-gradient !h-[69px] !rounded-xl px-5 py-6 !w-full "
                        onClick={toggleShouldSignIn}
                    >
                        <div className="flex justify-between items-center cursor-pointer w-full">
                            <Text>{t('CONNECT WALLET')}</Text>
                            <img src={String(CircleArrowRight)} alt="circle-arrow-right" />
                        </div>
                    </WalletMultiButton>

                    <div className="flex flex-wrap gap-2">
                        <Block className="flex-1 justify-between">
                            <div className="pt-2 pb-5">
                                <img src={String(Free)} alt="free" />
                            </div>
                            <Text className="text-base">{t('Free connect')}</Text>
                        </Block>
                        <Block className="flex-1 justify-between">
                            <div className="pt-1 pb-5">
                                <img src={String(Fasty)} alt="fast" />
                            </div>
                            <Text className="text-base">{t('Use your solana wallet')}</Text>
                        </Block>

                        <Block className="flex-0 w-full justify-between">
                            <div className="pb-20">
                                <img src={String(Lock)} alt="lock" />
                            </div>
                            <Text className="text-base">
                                {t('Each deal is safe')}
                                <br />
                                {t('Your money is returned if the deal fails')}
                            </Text>
                        </Block>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
