import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment, ReactNode, useRef } from 'react';
import { XIcon } from '@heroicons/react/solid';
import cn from 'classnames';

type Props = {
    open: boolean;
    onClose: VoidFunction;
    children: ReactNode;
    fullHeight?: boolean;
};

export const Modal: FC<Props> = ({ open, onClose, fullHeight = false, children }) => {
    const cancelButtonRef = useRef(null);

    // @ts-ignore
    return (
        <Transition show={open} as={Fragment} appear={true}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto flex"
                initialFocus={cancelButtonRef}
                static
                open={open}
                onClose={onClose}
            >
                <div className={cn('mx-auto md:p-5 text-center flex', fullHeight ? 'h-full' : 'min-h-full ')}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-50 w-full h-full" />
                    </Transition.Child>

                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        // @ts-ignore
                        className={cn("mt-auto", fullHeight && 'h-full')}
                    >
                        <div
                            className={cn(
                                'inline-block min-w-content max-w-full text-start align-middle transition-all relative',
                                fullHeight && 'h-full'
                            )}
                        >
                            <button
                                onClick={onClose}
                                aria-label="Close panel"
                                ref={cancelButtonRef}
                                className="inline-block outline-none focus:outline-none absolute right-4 top-4 z-[60]"
                            >
                                <XIcon className="h-4 w-4" />
                            </button>
                            <div
                                className={cn(
                                    'py-8 px-5 bg-brand-800 w-screen w-full flex flex-col justify-center rounded-t-xl md:rounded-xl md:max-w-[480px]',
                                    fullHeight && 'h-full'
                                )}
                            >
                                {children}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};
