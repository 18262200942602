import { createAsyncThunk, createSlice, createSelector } from '@reduxjs/toolkit';
import { GrpcService } from '../../services/grpcService';
import { LoadingStatuses } from '../../types/LoadingStatuses';
import { LoadingStatusesRecord } from '../../constants';
import { RootState } from '../store';

type State = {
    offerCategories: {
        loadingStatus: LoadingStatuses;
        list: string[];
    };
};

const initialState: State = {
    offerCategories: {
        loadingStatus: LoadingStatusesRecord.Initial,
        list: [],
    },
};

export const getPublicGetOfferCategories = createAsyncThunk('getPublicGetOfferCategories', async () =>
    (await GrpcService.publicGetOfferCategories()).toObject()
);

export const publicSlice = createSlice({
    name: 'publicData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPublicGetOfferCategories.pending, (draftState) => {
                draftState.offerCategories = {
                    loadingStatus: LoadingStatusesRecord.Loading,
                    list: [],
                };
            })
            .addCase(getPublicGetOfferCategories.rejected, (draftState) => {
                draftState.offerCategories = {
                    loadingStatus: LoadingStatusesRecord.Error,
                    list: [],
                };
            })
            .addCase(getPublicGetOfferCategories.fulfilled, (draftState, { payload: { nameList } }) => {
                let orderedNameList = nameList

                // === make delivery category first
                if (nameList.includes('delivery')) {
                    orderedNameList = nameList.filter(name => name !== 'delivery')
                    orderedNameList.unshift('delivery')
                }
                // ===

                draftState.offerCategories = {
                    loadingStatus: LoadingStatusesRecord.Loaded,
                    list: orderedNameList,
                };
            });
    },
});

const selectSlice = ({ publicData }: RootState) => publicData;

export const selectPublicOfferCategories = createSelector(selectSlice, ({ offerCategories }) => offerCategories)
