/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: deal.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as deal_pb from './deal_pb';


export class DealClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorgetMyDeals = new grpcWeb.MethodDescriptor(
    '/Deal/getMyDeals',
    grpcWeb.MethodType.UNARY,
    deal_pb.GetDealsRequest,
    deal_pb.DealsResponse,
    (request: deal_pb.GetDealsRequest) => {
      return request.serializeBinary();
    },
    deal_pb.DealsResponse.deserializeBinary
  );

  getMyDeals(
    request: deal_pb.GetDealsRequest,
    metadata: grpcWeb.Metadata | null): Promise<deal_pb.DealsResponse>;

  getMyDeals(
    request: deal_pb.GetDealsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: deal_pb.DealsResponse) => void): grpcWeb.ClientReadableStream<deal_pb.DealsResponse>;

  getMyDeals(
    request: deal_pb.GetDealsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: deal_pb.DealsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Deal/getMyDeals',
        request,
        metadata || {},
        this.methodDescriptorgetMyDeals,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Deal/getMyDeals',
    request,
    metadata || {},
    this.methodDescriptorgetMyDeals);
  }

  methodDescriptorgetDeal = new grpcWeb.MethodDescriptor(
    '/Deal/getDeal',
    grpcWeb.MethodType.UNARY,
    deal_pb.GetDealRequest,
    deal_pb.DealDTO,
    (request: deal_pb.GetDealRequest) => {
      return request.serializeBinary();
    },
    deal_pb.DealDTO.deserializeBinary
  );

  getDeal(
    request: deal_pb.GetDealRequest,
    metadata: grpcWeb.Metadata | null): Promise<deal_pb.DealDTO>;

  getDeal(
    request: deal_pb.GetDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: deal_pb.DealDTO) => void): grpcWeb.ClientReadableStream<deal_pb.DealDTO>;

  getDeal(
    request: deal_pb.GetDealRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: deal_pb.DealDTO) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Deal/getDeal',
        request,
        metadata || {},
        this.methodDescriptorgetDeal,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Deal/getDeal',
    request,
    metadata || {},
    this.methodDescriptorgetDeal);
  }

  methodDescriptorsubscribeToDeals = new grpcWeb.MethodDescriptor(
    '/Deal/subscribeToDeals',
    grpcWeb.MethodType.SERVER_STREAMING,
    deal_pb.DealsSubscribeRequest,
    deal_pb.DealDTO,
    (request: deal_pb.DealsSubscribeRequest) => {
      return request.serializeBinary();
    },
    deal_pb.DealDTO.deserializeBinary
  );

  subscribeToDeals(
    request: deal_pb.DealsSubscribeRequest,
    metadata?: grpcWeb.Metadata): grpcWeb.ClientReadableStream<deal_pb.DealDTO> {
    return this.client_.serverStreaming(
      this.hostname_ +
        '/Deal/subscribeToDeals',
      request,
      metadata || {},
      this.methodDescriptorsubscribeToDeals);
  }

  methodDescriptorcreateEscrowAccountKeys = new grpcWeb.MethodDescriptor(
    '/Deal/createEscrowAccountKeys',
    grpcWeb.MethodType.UNARY,
    deal_pb.EscrowAccountDTO,
    deal_pb.CreateEscrowAccountKeysResponse,
    (request: deal_pb.EscrowAccountDTO) => {
      return request.serializeBinary();
    },
    deal_pb.CreateEscrowAccountKeysResponse.deserializeBinary
  );

  createEscrowAccountKeys(
    request: deal_pb.EscrowAccountDTO,
    metadata: grpcWeb.Metadata | null): Promise<deal_pb.CreateEscrowAccountKeysResponse>;

  createEscrowAccountKeys(
    request: deal_pb.EscrowAccountDTO,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: deal_pb.CreateEscrowAccountKeysResponse) => void): grpcWeb.ClientReadableStream<deal_pb.CreateEscrowAccountKeysResponse>;

  createEscrowAccountKeys(
    request: deal_pb.EscrowAccountDTO,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: deal_pb.CreateEscrowAccountKeysResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Deal/createEscrowAccountKeys',
        request,
        metadata || {},
        this.methodDescriptorcreateEscrowAccountKeys,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Deal/createEscrowAccountKeys',
    request,
    metadata || {},
    this.methodDescriptorcreateEscrowAccountKeys);
  }

  methodDescriptorgetEscrowAccountKeys = new grpcWeb.MethodDescriptor(
    '/Deal/getEscrowAccountKeys',
    grpcWeb.MethodType.UNARY,
    deal_pb.GetEscrowAccount,
    deal_pb.EscrowAccountDTO,
    (request: deal_pb.GetEscrowAccount) => {
      return request.serializeBinary();
    },
    deal_pb.EscrowAccountDTO.deserializeBinary
  );

  getEscrowAccountKeys(
    request: deal_pb.GetEscrowAccount,
    metadata: grpcWeb.Metadata | null): Promise<deal_pb.EscrowAccountDTO>;

  getEscrowAccountKeys(
    request: deal_pb.GetEscrowAccount,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: deal_pb.EscrowAccountDTO) => void): grpcWeb.ClientReadableStream<deal_pb.EscrowAccountDTO>;

  getEscrowAccountKeys(
    request: deal_pb.GetEscrowAccount,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: deal_pb.EscrowAccountDTO) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Deal/getEscrowAccountKeys',
        request,
        metadata || {},
        this.methodDescriptorgetEscrowAccountKeys,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Deal/getEscrowAccountKeys',
    request,
    metadata || {},
    this.methodDescriptorgetEscrowAccountKeys);
  }

}

