import { FC, ImgHTMLAttributes, useMemo } from 'react';
import { useBooleanState } from '../../hooks/useBooleanState';
import defaultFallbackImage from './assets/jpg/image-placeholder.jpg';

type Props = ImgHTMLAttributes<HTMLImageElement> & { fallback?: string };

export const Image: FC<Props> = ({ src, alt, fallback, ...rest }) => {
    const { state: isFallbackRequired, setTrue: onImageLoadingError } = useBooleanState();
    
    const imgSrc = useMemo(() => {
        if (isFallbackRequired) {
            return defaultFallbackImage
        }

        return src ?? fallback
    }, [isFallbackRequired])

    return (
        <img
            {...rest}
            src={imgSrc}
            onError={onImageLoadingError}
            alt={isFallbackRequired ? 'Image is not available' : alt}
        />
    );
};

