import { FC, ReactNode } from 'react';
import { Tab } from '@headlessui/react';
import cn from 'classnames';

export type TabItem = {
    header: ReactNode | string;
    content: ReactNode;
};

type Props = {
    tabs: TabItem[];
};

export const Tabs: FC<Props> = ({ tabs }) => {
    return (
        <div className="w-full max-w-md pt-5">
            <Tab.Group>
                <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                    {tabs.map((tab, index) => (
                        <Tab
                            key={index}
                            className={({ selected }) =>
                                cn(
                                    'w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-black',
                                    'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2',
                                    selected
                                        ? 'bg-white shadow'
                                        : '!text-white hover:bg-white/[0.12] hover:text-white'
                                )
                            }
                        >
                            {tab.header}
                        </Tab>
                    ))}
                </Tab.List>
                <Tab.Panels className="mt-2">
                    {tabs.map((tab, index) => (
                        <Tab.Panel key={index} className={cn(
                            'rounded-xl ',
                            'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                        )}>{tab.content}</Tab.Panel>
                    ))}
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
};
