import { FC, ReactNode } from 'react';
import cn from 'classnames';
import warning from '../../../../assets/svg/warningSmall.svg';

type Props = {
    content: ReactNode;
    footer?: ReactNode;
};

export const Warning: FC<Props> = ({ content, footer }) => {
    return (
        <div className="bg-white rounded-lg p-4">
            <div className="flex gap-4">
                <div className="w-[9%]">
                    <img src={String(warning)} alt="warning" />
                </div>

                <div>
                    <div className="text-gradient">
                        {content}
                    </div>
                    <div className={cn(footer && "mt-4 flex gap-6 text-gradient")}>
                        {footer}
                    </div>
                </div>
            </div>
        </div>
    );
};
