import { copyToClipboard } from '../../../../helpers/copyToClipboard';
import { share } from '../../../../helpers/share';
import { useSolanaService } from '../../../../hooks/useSolanaService';
import { useAppDispatch } from '../../../../store/store';
import { sendMessage } from '../../../Chats/store/chatSlice';

export const useOfferActions = () => {
    const dispatch = useAppDispatch();
    const { createEscrow } = useSolanaService();

    const onStartSelling = (sellerPublicKey: string, offerId: number, price: number, cb: () => void = () => {}) =>
        createEscrow(sellerPublicKey, offerId, price, cb);

    const openChat = async (to: string, cb?: (path: string) => void) => {
        const result = await dispatch(sendMessage({ to, message: '' }));

        sendMessage.fulfilled.match(result) && cb?.(`/chats/${result.payload.chatId}`);
    };

    const onShareOffer = async () => {
        const currentUrl = window.location.href;
        const shareData: ShareData = {
            title: 'ERA crypto',
            text: 'See my offer in ERA crypto',
            url: currentUrl,
        };

        if (await share(shareData)) {
            return true;
        }

        console.log("Your browser doesn't support the Web Share API");
        return await copyToClipboard(currentUrl);
    };

    return {
        onStartSelling,
        openChat,
        onShareOffer,
    };
};

