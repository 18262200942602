import { LoginRequest, NonceRequest } from 'grpc-era/auth_pb';
import { AuthClient } from 'grpc-era/AuthServiceClientPb';
import {
    ChatMessageDTO,
    GetChatMessagesRequest,
    GetChatsRequest,
    GetUnreadMessagesRequest,
    GetUnreadMessagesResponse,
    MessagesSubscribeRequest,
    SendMessageRequest,
} from 'grpc-era/chat_pb';
import { ChatClient } from 'grpc-era/ChatServiceClientPb';
import {
    CreateOfferRequest,
    CreateTradeRequest,
    GetOffersByCategoryRequest,
    OfferIdRequest,
    PublicGetTradeRequestsForOfferRequest,
    GetFileUploadLinkRequest,
    PublicGetOfferCategoriesRequest,
} from 'grpc-era/offer_pb';
import { OfferClient } from 'grpc-era/OfferServiceClientPb';
import { apiUrl } from '../constants';
import { DealClient } from 'grpc-era/DealServiceClientPb';
import {
    DealDTO,
    DealsSubscribeRequest,
    EscrowAccountDTO,
    GetDealRequest,
    GetDealsRequest,
    GetEscrowAccount,
} from 'grpc-era/deal_pb';
import { RpcError, Metadata } from 'grpc-web';

const INTERVAL_TIMEOUT = 2000;

export class GrpcService {
    private static offerClient = new OfferClient(apiUrl);
    private static authClient = new AuthClient(apiUrl);
    private static chatClient = new ChatClient(apiUrl);
    private static dealClient = new DealClient(apiUrl);

    // noinspection JSUnusedLocalSymbols
    private constructor() {
        // Prevent class instantiation.
    }

    static fetchNonceGrpc = () => this.authClient.publicGetNonce(new NonceRequest().setLang('en'), null);

    static login = ({ authPhrase, nonce, publicKey, signature, refParentUserPublicKey }: LoginRequest.AsObject) =>
        this.authClient.publicLogin(
            new LoginRequest()
                .setNonce(nonce)
                .setAuthPhrase(authPhrase)
                .setSignature(signature)
                .setPublicKey(publicKey)
                .setRefParentUserPublicKey(refParentUserPublicKey),
            null
        );

    static sendMessageGrpc = async (
        { message, to }: Omit<SendMessageRequest.AsObject, 'auth'>,
        auth: string | null
    ) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return await this.chatClient.sendMessage(
            new SendMessageRequest()
                .setAuth(<string>auth)
                .setTo(to)
                .setMessage(message),
            metadata
        );
    };

    static listenChat = (
        { auth }: MessagesSubscribeRequest.AsObject,
        callBack: (dto: ChatMessageDTO.AsObject) => void
    ) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return this.chatClient
            .subscribeToMessages(new MessagesSubscribeRequest().setAuth(auth), metadata)
            .on('data', (dto) => callBack(dto.toObject()))
            .on('error', (err) => {
                console.log('subscribeToMessages error', err);
                setTimeout(() => {
                    this.listenChat({ auth }, callBack);
                }, INTERVAL_TIMEOUT);
            });
    };

    static listenUnreadMessages = (
        { auth }: GetUnreadMessagesRequest.AsObject,
        callback: (dto: GetUnreadMessagesResponse.AsObject) => void
    ) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return this.chatClient
            .subscribeToUnreadMessages(new GetUnreadMessagesRequest().setAuth(auth), metadata)
            .on('data', (dto) => callback(dto.toObject()))
            .on('error', (err) => {
                console.log('subscribeToUnreadMessages error', err);
                setTimeout(() => {
                    this.listenUnreadMessages({ auth }, callback);
                }, INTERVAL_TIMEOUT);
            });
    };

    static getChats = async (auth: string | null) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return this.chatClient.getChats(new GetChatsRequest(), metadata);
    };

    static getChatMessages = async ({ chatId }: GetChatMessagesRequest.AsObject, auth: string | null) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return this.chatClient.getChatMessages(new GetChatMessagesRequest().setChatId(chatId), metadata);
    };

    static getFileUploadLink = async (fileName: string, auth: string | null) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return await this.offerClient.getFileUploadLink(new GetFileUploadLinkRequest().setFileName(fileName), metadata);
    };

    static publicGetOfferCategories = () =>
        this.offerClient.publicGetOfferCategories(new PublicGetOfferCategoriesRequest(), {});

    static createOffer = async (
        { header, imageName, offerCategory, offerData, offerType, price }: CreateOfferRequest.AsObject,
        auth: string | null
    ) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        const createdOffer = await this.offerClient.createOffer(
            new CreateOfferRequest()
                .setHeader(header)
                .setImageName(imageName)
                .setOfferCategory(offerCategory)
                .setOfferData(JSON.stringify({ details: offerData }))
                .setOfferType(offerType)
                .setPrice(price),
            metadata
        );

            console.log('createdOffer request', createdOffer)

        return createdOffer;
    };

    static getOfferByCategory = async (
        {
            lastId,
            maxPrice,
            minPrice,
            offerCategory,
            offerType,
            size,
            sortBy,
            sortDirection,
            search,
        }: GetOffersByCategoryRequest.AsObject,
        auth: string | null
    ) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return (
            await this.offerClient.publicGetOffersByCategory(
                new GetOffersByCategoryRequest()
                    .setOfferCategory(offerCategory)
                    .setOfferType(offerType)
                    .setLastId(lastId)
                    .setMinPrice(minPrice)
                    .setMaxPrice(maxPrice)
                    .setSortBy(sortBy)
                    .setSortDirection(sortDirection)
                    .setSize(size)
                    .setSearch(search),
                metadata
            )
        )
            .toObject()
            .offersList.map((offer) => ({ ...offer, offerData: JSON.parse(offer.offerData)?.details }));
    };

    static getPublicOffer = async ({ offerId }: OfferIdRequest.AsObject, auth: string | null) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        const offer = (
            await this.offerClient.publicGetOffer(new OfferIdRequest().setOfferId(offerId), metadata)
        ).toObject();

        return { ...offer, offerData: JSON.parse(offer.offerData)?.details };
    };

    static deleteOffer = async ({ offerId }: OfferIdRequest.AsObject, auth: string | null) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return (await this.offerClient.deleteOffer(new OfferIdRequest().setOfferId(offerId), metadata)).toObject();
    };

    static createTradeRequest = async ({ offerId, price, text }: CreateTradeRequest.AsObject, auth: string | null) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return this.offerClient.createTradeRequest(
            new CreateTradeRequest().setPrice(price).setOfferId(offerId).setText(text),
            metadata
        );
    };

    static getTradeRequestByOfferId = async (
        { offerId }: PublicGetTradeRequestsForOfferRequest.AsObject,
        auth: string | null
    ) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return this.offerClient.publicGetOfferTradeRequests(
            new PublicGetTradeRequestsForOfferRequest().setOfferId(offerId),
            metadata
        );
    };

    static saveEscrowKeypair = async ({ publicKey, secretKey }: EscrowAccountDTO.AsObject, auth: string | null) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return this.dealClient.createEscrowAccountKeys(
            new EscrowAccountDTO().setPublicKey(publicKey).setSecretKey(secretKey),
            metadata
        );
    };

    static getEscrowKeypair = async ({ publicKey }: GetEscrowAccount.AsObject, auth: string | null) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return (
            await this.dealClient.getEscrowAccountKeys(new GetEscrowAccount().setPublicKey(publicKey), metadata)
        ).toObject();
    };

    static getDeals = async (auth: string | null) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return await this.dealClient.getMyDeals(new GetDealsRequest(), metadata);
        // .toObject()
        // .dealsList.map((deal) => ({ ...deal, offerData: JSON.parse(deal.offerData)?.details }));
    };

    static getDeal = async (dealId: number, auth: string | null) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return (await this.dealClient.getDeal(new GetDealRequest().setDealId(dealId), metadata)).toObject()
        // .dealsList.map((deal) => ({ ...deal, offerData: JSON.parse(deal.offerData)?.details }));
    };

    static listenDeals = ({ auth }: DealsSubscribeRequest.AsObject, onMessage: (dto: DealDTO.AsObject) => void) => {
        const metadata: Metadata = {};

        if (auth) {
            metadata.auth = auth;
        }

        return this.dealClient
            .subscribeToDeals(new DealsSubscribeRequest().setAuth(auth), metadata)
            .on('data', (dto) => onMessage(dto.toObject()));
        // .on('error', (error) => {
        //     console.log('subscribeToDeals error', error)
        //     setTimeout(() => {
        //         this.listenDeals({ auth }, onMessage)
        //     }, INTERVAL_TIMEOUT)
        // });
    };
}
