import { FC, ReactNode } from 'react';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import * as styles from './Carousel.module.css'

interface Props {
    slides: ReactNode[];
    visibleSlides?: number;
    orientation?: 'horizontal' | 'vertical';
    naturalSlideWidth?: number;
    naturalSlideHeight?: number;
}

export const Carousel: FC<Props> = ({
    slides,
    visibleSlides = 6,
    orientation = 'horizontal',
    naturalSlideWidth = 100,
    naturalSlideHeight = 125,
}) => {
    return (
        <CarouselProvider
            naturalSlideWidth={naturalSlideWidth}
            naturalSlideHeight={naturalSlideHeight}
            totalSlides={slides.length}
            visibleSlides={visibleSlides}
            orientation={orientation}
            preventVerticalScrollOnTouch
        >
            <Slider className={styles['scrollbar-hide']}>
                {slides.map((slide, index) => (
                    <Slide key={index} index={index}>
                        {slide}
                    </Slide>
                ))}
            </Slider>
        </CarouselProvider>
    );
};

