.wrapper {
    width: 100%;
    /*margin: 23px 0 0 0;*/
    overflow: auto;

    /*padding: 0 20px;*/
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    height: calc(100% - 120px);
}

