import { FC } from 'react';
import styles from './Deal.module.css';
import { Arrow } from '../../../../ui/Arrow/Arrow';

export const Deal: FC = () => (
    <div className={styles.deal}>
        <span className={styles.dealTitle}>Start a deal</span>
        <Arrow />
    </div>
);
