.topLevelContainer {
    font-family: 'Gilroy-Regular', sans-serif;
}

.wrapper {
    max-width: 800px;
    margin: 0 auto;
}

.topImgTruck {
    width: 100%;
    position: relative;
    top: -210px;
}

.container {
    padding: 0 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.arrowLeft {
    margin-right: 10px;
    cursor: pointer;
}

.arrowRight {
    margin-left: 10px;
    cursor: pointer;
}
