/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: auth.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as auth_pb from './auth_pb';


export class AuthClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorPublicGetNonce = new grpcWeb.MethodDescriptor(
    '/Auth/PublicGetNonce',
    grpcWeb.MethodType.UNARY,
    auth_pb.NonceRequest,
    auth_pb.NonceReply,
    (request: auth_pb.NonceRequest) => {
      return request.serializeBinary();
    },
    auth_pb.NonceReply.deserializeBinary
  );

  publicGetNonce(
    request: auth_pb.NonceRequest,
    metadata: grpcWeb.Metadata | null): Promise<auth_pb.NonceReply>;

  publicGetNonce(
    request: auth_pb.NonceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: auth_pb.NonceReply) => void): grpcWeb.ClientReadableStream<auth_pb.NonceReply>;

  publicGetNonce(
    request: auth_pb.NonceRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: auth_pb.NonceReply) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Auth/PublicGetNonce',
        request,
        metadata || {},
        this.methodDescriptorPublicGetNonce,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Auth/PublicGetNonce',
    request,
    metadata || {},
    this.methodDescriptorPublicGetNonce);
  }

  methodDescriptorPublicLogin = new grpcWeb.MethodDescriptor(
    '/Auth/PublicLogin',
    grpcWeb.MethodType.UNARY,
    auth_pb.LoginRequest,
    auth_pb.LoginResponse,
    (request: auth_pb.LoginRequest) => {
      return request.serializeBinary();
    },
    auth_pb.LoginResponse.deserializeBinary
  );

  publicLogin(
    request: auth_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null): Promise<auth_pb.LoginResponse>;

  publicLogin(
    request: auth_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: auth_pb.LoginResponse) => void): grpcWeb.ClientReadableStream<auth_pb.LoginResponse>;

  publicLogin(
    request: auth_pb.LoginRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: auth_pb.LoginResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Auth/PublicLogin',
        request,
        metadata || {},
        this.methodDescriptorPublicLogin,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Auth/PublicLogin',
    request,
    metadata || {},
    this.methodDescriptorPublicLogin);
  }

}

