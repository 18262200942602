import { forwardRef } from 'react';
import { OfferResponse, OfferTypesEnum } from 'grpc-era/offer_pb';
import moment from 'moment';
import { Button } from '../../../../../ui/Button/Button';
import { hostName, LoadingStatusesRecord } from '../../../../../constants';
import { LoadingStatuses } from '../../../../../types/LoadingStatuses';
import { Spinner } from '../../../../../ui/Spinner/Spinner';
import { ErrorMessage } from '../../../../../ui/ErrorMessage/ErrorMessage';
import { Link, useRouteMatch } from 'react-router-dom';
import { ProductImage } from '../../../../../ui/ProductImage/ProductImage';
import { useSolanaService } from '../../../../../hooks/useSolanaService';
import styles from './OffersList.module.css';
import { useTranslation } from 'react-i18next';

type Props = {
    offersList: Array<OfferResponse.AsObject>;
    loadingStatus: LoadingStatuses;
};

export const OffersList = forwardRef<HTMLDivElement, Props>(function OffersList({ offersList, loadingStatus }, ref) {
    const { t } = useTranslation();

    const { url } = useRouteMatch();

    const { publicKey } = useSolanaService();

    return (
        <>
            {loadingStatus !== LoadingStatusesRecord.Error && (
                <div>
                    <div className={styles.productsContainer}>
                        {offersList.map(
                            ({ id, header, imagePath, price, offerType, createdAt, ownerPublicKey }, index) => (
                                <div
                                    className="min-w-full bg-brand-800 mb-[10px] rounded-[10px]"
                                    key={id}
                                    ref={offersList.length > 10 && offersList.length - 1 === index ? ref : undefined}
                                >
                                    <Link to={`${url}/${String(id)}`}>
                                        <div>
                                            <ProductImage imageUrl={`${hostName}${imagePath}`} />
                                        </div>

                                        <div className="p-2.5">
                                            <div className="h-11 line-clamp-2">
                                                <span className="my-auto">
                                                    {header || t('Product does not have a name')}
                                                </span>
                                            </div>

                                            <div className="mt-[10px] mb-[4px]">
                                                <span className={styles.currency}>$</span>

                                                {price}
                                            </div>

                                            <div className={styles.today}>
                                                <div>
                                                    <span>
                                                        {createdAt ? moment(createdAt).format('MMMM Do YYYY') : ''}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-[10px] px-[10px]">
                                            <Button colorScheme="gradient" block>
                                                {publicKey?.toString() === ownerPublicKey
                                                    ? t('Own offer')
                                                    : offerType === OfferTypesEnum.BUY
                                                    ? t('Sell')
                                                    : t('Buy')}
                                            </Button>
                                        </div>
                                    </Link>
                                </div>
                            )
                        )}
                    </div>
                </div>
            )}

            {loadingStatus === LoadingStatusesRecord.Loaded && !offersList.length && (
                <p className="flex justify-center">{t("Couldn't find any offers")}</p>
            )}

            {loadingStatus === LoadingStatusesRecord.Loading && <Spinner showText={true} className="mt-4" />}

            {loadingStatus === LoadingStatusesRecord.Error && <ErrorMessage message={t("Couldn't load offers")!} />}
        </>
    );
});
