// import { OfferTypesEnum } from 'grpc-era/offer_pb';
import { FC, useEffect, useState } from 'react';
import useIsMounted from '../../../../hooks/useIsMounted';
// import { downloadFile } from '../../../../services/downloadFile';
// import { forEachWithDelay } from '../../../../services/forEachWithDelay';
// import { getFileName } from '../../../../services/getFilename';
// import { useAppDispatch } from '../../../../store/store';
// import { createNewOffer } from '../../store/offersSlice';
import uploadIconPath from '../../../../../../assets/svg/plus.svg';
import { useHttpService } from '../../../../hooks/useHttpService';
import { toast } from 'react-toastify';
import { Spinner } from '../../../../ui/Spinner/Spinner';

export const BulkCreateOffer: FC = () => {
    const isMounted = useIsMounted();
    // const dispatch = useAppDispatch();
    const { post } = useHttpService()
    const [file, setFile] = useState<File | null>(null);
    // const [progress, setProgress] = useState<{ header: string; status: string }[]>([]);
    const [isUploading, setIsUploading] = useState(false);

    const handleAddFile = (evt: any) => {
        const files = evt.target.files;

        setFile(files[0]);
    };

    useEffect(() => {
        (async () => {
            if (file && isMounted()) {
                setIsUploading(true)

                const fd = new FormData()
                fd.set('file', file)

                await post('/offer/upload', fd).then((res) => {
                    if (res.error) {
                        toast.error('Error during import!')
                    } else {
                        toast.info('Import successful!')
                    }
                }).finally(() => {
                    setIsUploading(false)
                })
            }
        })()
    }, [file, isMounted, post])

    // useEffect(() => {
    //     let reader: FileReader | null = new FileReader();

    //     (async () => {
    //         if (file && isMounted()) {
    //             reader.onload = () => {
    //                 const rows = (reader!.result as string).split('\n');

    //                 forEachWithDelay(
    //                     rows,
    //                     async (row, index) => {
    //                         if (index && index > 0 && row) {
    //                             const cleanRow = row.replace(/"/g, '').split(',');
    //                             const [header, offerType, price, offerData, offerCategory, img] = cleanRow;
    //                             const { file, mimeType }: { file: ArrayBuffer; mimeType: string } = await downloadFile(
    //                                 img
    //                             );
    //                             const fileName = getFileName(img);
    //                             const imageFile = new File([file], fileName, { type: mimeType });

    //                             const result = await dispatch(
    //                                 createNewOffer({
    //                                     imagesList: [imageFile] as unknown as FileList,
    //                                     header: header,
    //                                     offerCategory: offerCategory,
    //                                     offerData: JSON.stringify({ details: offerData }),
    //                                     offerType: offerType === 'BUY' ? OfferTypesEnum.BUY : OfferTypesEnum.SELL, //   as unknown as OfferTypesEnum,
    //                                     price: Number(price),
    //                                 })
    //                             );

    //                             if (createNewOffer.fulfilled.match(result)) {
    //                                 setProgress((prev) => [
    //                                     ...prev,
    //                                     { header: header, status: `Offer ${header} created` },
    //                                 ]);
    //                             }
    //                             if (createNewOffer.rejected.match(result)) {
    //                                 setProgress((prev) => [
    //                                     ...prev,
    //                                     { header: header, status: `Offer ${header} FAILED to create` },
    //                                 ]);
    //                             }
    //                         }
    //                     },
    //                     1000
    //                 );
    //             };
    //             reader.readAsText(file);
    //         }
    //     })();

    //     return () => {
    //         reader = null;
    //     };
    // }, [file, dispatch, isMounted]);

    return (
        <div>
            {!isUploading && (
                <div>
                    <label htmlFor="bulk-offer-uploader" className="mt-5 flex gap-3 cursor-pointer">
                        Click here to upload <img src={String(uploadIconPath)} alt="upload-icon" />
                    </label>
                    <input id="bulk-offer-uploader" type="file" onChange={handleAddFile} className="hidden" />
                </div>
            )}

            {isUploading && (
                <Spinner size='large' />
            )}
        </div>
    )

    // return (
    //     <div>
    //         {progress.length === 0 && (
    //             <div>
    //                 <label htmlFor="bulk-offer-uploader" className="mt-5 flex gap-3 cursor-pointer">
    //                     Click here to upload <img src={String(uploadIconPath)} alt="upload-icon" />
    //                 </label>
    //                 <input id="bulk-offer-uploader" type="file" onChange={handleAddFile} className="hidden" />
    //             </div>
    //         )}

    //         <div>
    //             {progress.map((p, i) => (
    //                 <div key={i}>
    //                     {p.header} ${p.status}
    //                 </div>
    //             ))}
    //         </div>
    //     </div>
    // );
};

