import { OfferTypesEnum } from 'grpc-era/offer_pb';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Header } from '../../../../components/Header/Header';
import { useSolanaService } from '../../../../hooks/useSolanaService';
import { useAppDispatch } from '../../../../store/store';
import { Animated } from '../../../../ui/Animated/Animated';
import { Spinner } from '../../../../ui/Spinner/Spinner';
import { getPublicOffer, selectOfferById } from '../../store/offersSlice';
import { getTradeRequests } from '../../store/tradeRequestsSlice';
import { Offer } from './Offer';
import { OwnOffer } from './OwnOffer';

export const OfferContainer: FC = () => {
    const dispatch = useAppDispatch()
    const offerId = Number(useParams<{ id: string }>().id);
    const offer = useSelector(selectOfferById(offerId));
    const { publicKey } = useSolanaService();

    useEffect(() => {
        const abort = dispatch(getPublicOffer({ offerId })).abort;

        return () => {
            abort();
        };
    }, [dispatch, offerId]);

    useEffect(() => {
        const abort =
            offer?.offerType === OfferTypesEnum.BUY && dispatch(getTradeRequests({ offerId: offer?.id })).abort;

        return () => {
            abort && abort();
        };
    }, [dispatch, offer?.id, offer?.offerType]);

    const isOwnOffer = offer?.ownerPublicKey === publicKey?.toString();

    if (!offer) {
        return <div className="h-screen"><Spinner /></div>;
    }

    const component = isOwnOffer ? <OwnOffer offer={offer} /> : <Offer offer={offer} />;

    return (
        <div>
            <div>
                <Header />
            </div>

            <div className="max-w-[680px] mx-auto h-[5rem] p-5" />

            <Animated>{component}</Animated>
        </div>
    );
};
