{
    "translation": {
        "Login": "登录",
        "Offers": "广告",
        "Offer": "广告",
        "Buy": "买",
        "Sell": "卖",
        "Search": "寻找",
        "Price": "价钱",
        "price": "价钱",
        "CONNECT WALLET": "连接钱包",
        "Free connect": "连接钱包是免费的！ Era 仅使用您的钱包进行登录。",
        "Use your solana wallet": "您的 solana 钱包非常适合世界上最快的交易",
        "Each deal is safe": "每笔交易都是安全的。",
        "Your money is returned if the deal fails": "如果交易失败，您的钱将被退还。",
        "Accept": "接受",
        "Decline": "拒绝",
        "Cookie ensure": "为了您出色的体验，ERA crypto 使用 cookie",
        "and": "和",
        "Deals": "交易",
        "Deal": "交易",
        "Status": "状态",
        "Who wins?": "谁赢?",
        "Seller": "卖方",
        "Buyer": "买方",
        "Loading": "加载中",
        "No chats yet": "还没有交谈记录",
        "You should": "您应该",
        "You need": "您需要",
        "to see your chats": "查看您的交谈记录",
        "to see your deals": "查看您的交易",
        "before access this page": "在访问此页面之前",
        "Chat": "交谈",
        "Wallet": "钱包",
        "Your message": "您的信息",
        "Couldn't load this deal": "无法加载此交易。请尝试刷新页面和检查您使用有效的链接。",
        "Couldn't load this offer": "无法加载此广告。请尝试刷新页面和检查您使用有效的链接。",
        "Couldn't load offers": "无法加载此广告。 请尝试刷新页面",
        "Couldn't find any offers": "找不到任何广告. 请尝试其他设置",
        "Description": "描述",
        "DealState-Created": "交易开始了。 钱存储在区块链中。 卖方正在等待买方批准此交易。",
        "DealState-Cancelled": "交易被买方取消。 钱已给买方退还了。",
        "DealState-Approved": "交易已创建。 钱存储在区块链中。 买方已同意交易。 卖方可以开始发货。",
        "DealState-Paid": "交易完成了。 钱给卖方寄了。",
        "Approve": "同意",
        "Cancel": " 取消",
        "Release": "松开",
        "Talk to": "交谈",
        "Talk to buyer": "交谈 买方",
        "Talk to seller": "交谈 卖方",
        "No deals": "您还没开始任何交易",
        "Proceed to": "继续",
        "Earn with ERA Crypto!": "赚钱 跟ERA Crypto ！",
        "earnPercent": "如果您与朋友分享此链接，您收到则每笔交易的佣金 – {{percent}}%",
        "Your referral link": "您的推荐链接",
        "New offer": "新广告",
        "Own offer": "我的广告",
        "Offer name": "广告的标题",
        "Offer price": "广告的价钱",
        "Offer description": "广告的描述",
        "Details": "详情",
        "Upload": "上载",
        "photo": "照片",
        "Create": "创建",
        "to ensure this transaction": "为了执行这此交易",
        "approximately": "大约",
        "Start Deal": "交易开始",
        "Delete Offer": "删除我的广告",
        "Sellers price offers": "卖方的报价",
        "Suggested price": "建议价格",
        "Product does not have a name": "这个产品没有名称",
        "delivery": "交付",
        "building materials": "建筑材料",
        "clothing": "服装和配饰",
        "computers and smartphones": "计算机和手机",
        "digital goods": "数字项目",
        "equipment": "仪器",
        "food": "食品",
        "games": "游戏",
        "jeweller": "珠宝首饰",
        "jewellery": "珠宝首饰",
        "real estate": "房产",
        "real food": "生态食品",
        "services": "服务",
        "software": "软件",
        "tobacco": "烟",
        "vehicles": "车",
        "all": "显示所有",
        "Share": "分享",
        "In descending order": "递减",
        "In ascending order": "递增",
        "Date of creation": "日期",
        "Sort direction": "显示方向",
        "You have enough money to start the deal": "您有足够的钱来开始交易。",
        "firstState": "要求已发送到区块链，等待创建一笔交易",
        "secondState": "确认已收到，开始一笔交易的创建",
        "thirdState": "一笔交易创建中"
    }
}
