import { FC } from 'react';
import { goBack } from '../../helpers/goBack';
import * as classes from './Subeader.module.css';
import { useRouteMatch } from 'react-router-dom';

type Props = { title: string; caption?: string };

const routesWithoutBackButton = ['/chats', '/deals', '/earn'];

export const Subheader: FC<Props> = ({ title, caption }) => {
    const withBackButton = !useRouteMatch({
        path: routesWithoutBackButton,
        strict: true,
        sensitive: true,
        exact: true,
    });

    return (
        <div className="mx-auto w-full md:max-w-[768px] !h-[5rem] ">
            <div className={classes.chats}>
                {withBackButton && (
                    <button onClick={() => goBack()} className={classes.chatsButtonWrapper}>
                        <span className={classes.chatsButton} />
                    </button>
                )}

                <div className={classes.chatsTitle}>
                    <p>{title}</p>

                    {caption && <p className={classes.chatsText}>{caption}</p>}
                </div>
            </div>
        </div>
    );
};
