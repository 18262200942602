import { FC } from 'react';
import classNames from 'classnames';
import styles from './Gradient.module.css';

interface Props {
    type?: '1' | '2' | '3' | '4' | '5' | '6' | '7';
}

export const Gradient: FC<Props> = ({ type = '1' }) => {
    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.style1]: type === '1',
                [styles.style2]: type === '2',
                [styles.style3]: type === '3',
                [styles.style4]: type === '4',
                [styles.style5]: type === '5',
                [styles.style6]: type === '6',
                [styles.style7]: type === '7',
            })}
        />
    );
};
