import styles from './Button.module.css';
import classNames from 'classnames';
import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { Spinner } from '../Spinner/Spinner';

type Props = {
    children: ReactNode;
    colorScheme: 'gradient' | 'white' | 'gray' | 'slate' | 'fullTransparent' | 'transparent';
    size?: 'S' | 'M' | 'L' | 'XL' | 'FULL' | 'FILL';
    block?: boolean;
    className?: string;
    loading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button: FC<Props> = ({
    children,
    size = 'M',
    colorScheme = 'gradient',
    className = '',
    loading,
    disabled,
    block,
    ...rest
}) => (
    <button
        className={classNames(styles.wrapper, className, {
            [styles.sizeS]: size === 'S',
            [styles.sizeM]: size === 'M',
            [styles.sizeL]: size === 'L',
            [styles.sizeXl]: size === 'XL',
            [styles.sizeFull]: size === 'FULL',
            [styles.sizeFillContainer]: size === 'FILL',
            'bg-gradient': colorScheme === 'gradient',
            '!w-full': block,
            [styles.colorWhite]: colorScheme === 'white',
            [styles.colorGray]: colorScheme === 'gray',
            'bg-[#808897]': colorScheme === 'slate',
            [styles.colorTransparent]: colorScheme === 'transparent',
            [styles.colorBorderlessTransparent]: colorScheme === 'fullTransparent',
            'opacity-30': loading || disabled
        })}
        disabled={loading || disabled}
        {...rest}
    >
        {loading ? <Spinner size="small" /> : children}
    </button>
);
