import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '../../ui/Text/Text';
import warning from '../../../../assets/svg/warningSmall.svg';
import { useApplicationLocalState } from '../../hooks/useApplicationLocalState';

export const ConfirmCookies: FC = () => {
    const [show, setShow] = useState(false);
    const [alreadyAsked, setAlreadyAsked] = useState(false)

    const { t } = useTranslation();
    const { context, setCookieConfirmed } = useApplicationLocalState();

    const setCookiesState = useCallback((v: boolean) => {
        setCookieConfirmed?.(v);
        setShow(false);
    }, []);

    useEffect(() => {
        if (context?.readOnly === false) {
            if (!alreadyAsked && !context?.cookieConfirmed) {
                setShow(true);
                setAlreadyAsked(true);
            }
        }
        
    }, [context?.cookieConfirmed, context?.readOnly, setShow, setAlreadyAsked]);

    if (!show) {
        return null;
    }

    return (
        <div className="fixed left-[50%] top-[20%] bg-white w-[335px] min-h-[117px] ml-[-170px] rounded-lg p-4 z-10">
            <div className="flex gap-4">
                <div className="w-[9%]">
                    <img src={String(warning)} alt="warning" />
                </div>

                <div>
                    <div>
                        <Text className="text-gradient">{t('Cookie ensure')}</Text>
                    </div>
                    <div className="mt-4 flex gap-6">
                        <button className="text-gradient" onClick={() => setCookiesState(true)}>
                            {t('Accept')}
                        </button>
                        <button className="text-gradient" onClick={() => setCookiesState(false)}>
                            {t('Decline')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

