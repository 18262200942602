.productsContainer {
    margin-top: 20px;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
}
.productCard > img {
    border-radius: 10px;
}
.productCard:nth-child(even) {
    display: grid;
    /*justify-content: flex-end;*/
    margin-left: 5px;
    margin-bottom: 15px;
}
.productCard:nth-child(odd) {
    margin-right: 5px;
}

.infoBlock {
    margin-top: 10px;
    color: #fff;
}
.sol {
    color: #808897;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    padding-left: 10px;
}

.name {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
}
.price {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 5px;
}
.currency {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding-right: 5px;
}
.today {
    font-size: 12px;
    color: #808897;
    margin-bottom: 15px;
}
.buttonBlock {
    display: flex;
}
.heartImg {
    padding-left: 15px;
}
