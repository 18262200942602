import { FC, LegacyRef, useMemo, useState } from 'react';
import { Popover } from '@headlessui/react';
import { usePopper } from 'react-popper';
import { useTranslation } from 'react-i18next';
import { Text } from '../../ui/Text/Text';
import { Lang } from '../../types/Lang';

const languageBoundary: { [key in Lang]: string } = {
    ru: 'Русский',
    en: 'English',
    fr: 'Français',
    zh: '中文简体',
};

const LanguageSelector: FC<{ lng: string; onChangeLanguage: () => void }> = ({ lng, onChangeLanguage }) => (
    <div className="px-5 py-3 text-right" onClick={onChangeLanguage}>
        <Text>{lng}</Text>
    </div>
);

export const LanguageSwitcher: FC = () => {
    const { i18n } = useTranslation();

    const [referenceElement, setReferenceElement] = useState();
    const [popperElement, setPopperElement] = useState();

    const { styles, attributes } = usePopper(referenceElement, popperElement);

    const lang = useMemo(() => {
        const detectedLanguage = (i18n.language.split('-')[0] ?? 'en') as Lang;

        return languageBoundary[detectedLanguage];
    }, [i18n.language]);

    const handleChangeLanguage = async (lng: Lang, onChangeLanguage?: () => void) => {
        await i18n.changeLanguage(lng);
        onChangeLanguage?.();
    };

    return (
        <Popover>
            <Popover.Button ref={setReferenceElement as unknown as LegacyRef<HTMLButtonElement>}>{lang}</Popover.Button>
            <Popover.Panel
                ref={setPopperElement as unknown as LegacyRef<HTMLDivElement>}
                style={styles.popper}
                {...attributes.popper}
            >
                {({ close }) => (
                    <div className="p-10">
                        <div className="bg-[#131C28] w-full rounded-lg">
                            <LanguageSelector
                                lng="Русский"
                                onChangeLanguage={() => handleChangeLanguage('ru', close)}
                            />
                            <LanguageSelector
                                lng="English"
                                onChangeLanguage={() => handleChangeLanguage('en', close)}
                            />
                            {/*<LanguageSelector*/}
                            {/*    lng="Français"*/}
                            {/*    onChangeLanguage={() => handleChangeLanguage('en', close)}*/}
                            {/*/>*/}
                            <LanguageSelector
                                lng="中文简体"
                                onChangeLanguage={() => handleChangeLanguage('zh', close)}
                            />
                        </div>
                    </div>
                )}
            </Popover.Panel>
        </Popover>
    );
};
