import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit';
import { ErrorCodes, LoadingStatusesRecord } from '../../../constants';
import { RootState } from '../../../store/store';
import { GrpcService } from '../../../services/grpcService';
import { CreateTradeRequestResponse, TradeRequestDT0 } from 'grpc-era/offer_pb';
import { selectToken } from '../../../store/slices/authSlice';
import { LoadingStatuses } from '../../../types/LoadingStatuses';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { showAuthModal } from '../../../components/Modals/store/modalSlice';

export const createTradeRequest = createAsyncThunk<
    CreateTradeRequestResponse.AsObject,
    Parameters<typeof GrpcService.createTradeRequest>[0],
    { state: RootState }
>('tradeRequests/createTradeRequest', async (input, { getState, dispatch }) => {
    const token = selectToken(getState());

    return GrpcService.createTradeRequest(input, token)
        .then((c) => c.toObject())
        .catch((error) => {
            if (error) {
                if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                    dispatch(showAuthModal());
                } else if (error.name && error.name === ErrorCodes.RPC_ERROR) {
                    dispatch(showAuthModal());
                }
            }

            return Promise.reject(error);
        });
});

export const getTradeRequests = createAsyncThunk<
    Array<TradeRequestDT0.AsObject>,
    Parameters<typeof GrpcService.getTradeRequestByOfferId>[0],
    { state: RootState }
>('tradeRequests/getTradeRequests', async (input, { getState, dispatch }) => {
    const token = selectToken(getState());

    return GrpcService.getTradeRequestByOfferId(input, token)
        .then((c) => c.toObject().offerrequestsList)
        .catch((error) => {
            if (error) {
                if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                    dispatch(showAuthModal());
                } else if (error.name && error.name === ErrorCodes.RPC_ERROR) {
                    dispatch(showAuthModal());
                }
            }

            return Promise.reject(error);
        });
});

type AdditionalFields = {
    loadingStatus: LoadingStatuses;
};

const initialAdditionalFields: AdditionalFields = {
    loadingStatus: LoadingStatusesRecord.Initial,
};

const tradeRequestsAdapter = createEntityAdapter<TradeRequestDT0.AsObject>();

export const tradeRequestsSlice = createSlice({
    name: 'tradeRequests',
    initialState: tradeRequestsAdapter.getInitialState(initialAdditionalFields),
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTradeRequests.pending, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            })
            .addCase(getTradeRequests.fulfilled, (draftState, { payload, meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
                tradeRequestsAdapter.setAll(draftState, payload);
            })
            .addCase(getTradeRequests.rejected, (draftState, { meta: { requestStatus, aborted } }) => {
                !aborted && (draftState.loadingStatus = requestStatus);
            })
            .addCase(createTradeRequest.pending, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            })
            .addCase(createTradeRequest.fulfilled, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            })
            .addCase(createTradeRequest.rejected, (draftState, { meta: { requestStatus } }) => {
                draftState.loadingStatus = requestStatus;
            })
            .addCase(
                LOCATION_CHANGE,
                (
                    draftState,
                    {
                        payload: {
                            location: { pathname },
                        },
                    }: LocationChangeAction
                ) => {
                    return pathname.startsWith('/offers/')
                        ? tradeRequestsAdapter.getInitialState(initialAdditionalFields)
                        : undefined;
                }
            );
    },
});

const selectSlice = ({ tradeRequests }: RootState) => tradeRequests;

export const selectTradeRequestsList = tradeRequestsAdapter.getSelectors(selectSlice).selectAll;

export const selectTradeRequestsListLength = tradeRequestsAdapter.getSelectors(selectSlice).selectTotal;

export const selectTradeRequestById = (id?: number) =>
    createSelector(selectSlice, (slice) =>
        id ? tradeRequestsAdapter.getSelectors().selectById(slice, id) : undefined
    );

export const selectTradeRequestsLoadingStatus = createSelector(selectSlice, ({ loadingStatus }) => loadingStatus);
