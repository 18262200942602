/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: offer.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as offer_pb from './offer_pb';


export class OfferClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorcreateOffer = new grpcWeb.MethodDescriptor(
    '/Offer/createOffer',
    grpcWeb.MethodType.UNARY,
    offer_pb.CreateOfferRequest,
    offer_pb.OfferIdResponse,
    (request: offer_pb.CreateOfferRequest) => {
      return request.serializeBinary();
    },
    offer_pb.OfferIdResponse.deserializeBinary
  );

  createOffer(
    request: offer_pb.CreateOfferRequest,
    metadata: grpcWeb.Metadata | null): Promise<offer_pb.OfferIdResponse>;

  createOffer(
    request: offer_pb.CreateOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: offer_pb.OfferIdResponse) => void): grpcWeb.ClientReadableStream<offer_pb.OfferIdResponse>;

  createOffer(
    request: offer_pb.CreateOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: offer_pb.OfferIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Offer/createOffer',
        request,
        metadata || {},
        this.methodDescriptorcreateOffer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Offer/createOffer',
    request,
    metadata || {},
    this.methodDescriptorcreateOffer);
  }

  methodDescriptorgetFileUploadLink = new grpcWeb.MethodDescriptor(
    '/Offer/getFileUploadLink',
    grpcWeb.MethodType.UNARY,
    offer_pb.GetFileUploadLinkRequest,
    offer_pb.GetFileUploadLinkResponse,
    (request: offer_pb.GetFileUploadLinkRequest) => {
      return request.serializeBinary();
    },
    offer_pb.GetFileUploadLinkResponse.deserializeBinary
  );

  getFileUploadLink(
    request: offer_pb.GetFileUploadLinkRequest,
    metadata: grpcWeb.Metadata | null): Promise<offer_pb.GetFileUploadLinkResponse>;

  getFileUploadLink(
    request: offer_pb.GetFileUploadLinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: offer_pb.GetFileUploadLinkResponse) => void): grpcWeb.ClientReadableStream<offer_pb.GetFileUploadLinkResponse>;

  getFileUploadLink(
    request: offer_pb.GetFileUploadLinkRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: offer_pb.GetFileUploadLinkResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Offer/getFileUploadLink',
        request,
        metadata || {},
        this.methodDescriptorgetFileUploadLink,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Offer/getFileUploadLink',
    request,
    metadata || {},
    this.methodDescriptorgetFileUploadLink);
  }

  methodDescriptordeleteOffer = new grpcWeb.MethodDescriptor(
    '/Offer/deleteOffer',
    grpcWeb.MethodType.UNARY,
    offer_pb.OfferIdRequest,
    offer_pb.OfferIdResponse,
    (request: offer_pb.OfferIdRequest) => {
      return request.serializeBinary();
    },
    offer_pb.OfferIdResponse.deserializeBinary
  );

  deleteOffer(
    request: offer_pb.OfferIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<offer_pb.OfferIdResponse>;

  deleteOffer(
    request: offer_pb.OfferIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: offer_pb.OfferIdResponse) => void): grpcWeb.ClientReadableStream<offer_pb.OfferIdResponse>;

  deleteOffer(
    request: offer_pb.OfferIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: offer_pb.OfferIdResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Offer/deleteOffer',
        request,
        metadata || {},
        this.methodDescriptordeleteOffer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Offer/deleteOffer',
    request,
    metadata || {},
    this.methodDescriptordeleteOffer);
  }

  methodDescriptorpublicGetOffer = new grpcWeb.MethodDescriptor(
    '/Offer/publicGetOffer',
    grpcWeb.MethodType.UNARY,
    offer_pb.OfferIdRequest,
    offer_pb.OfferResponse,
    (request: offer_pb.OfferIdRequest) => {
      return request.serializeBinary();
    },
    offer_pb.OfferResponse.deserializeBinary
  );

  publicGetOffer(
    request: offer_pb.OfferIdRequest,
    metadata: grpcWeb.Metadata | null): Promise<offer_pb.OfferResponse>;

  publicGetOffer(
    request: offer_pb.OfferIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: offer_pb.OfferResponse) => void): grpcWeb.ClientReadableStream<offer_pb.OfferResponse>;

  publicGetOffer(
    request: offer_pb.OfferIdRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: offer_pb.OfferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Offer/publicGetOffer',
        request,
        metadata || {},
        this.methodDescriptorpublicGetOffer,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Offer/publicGetOffer',
    request,
    metadata || {},
    this.methodDescriptorpublicGetOffer);
  }

  methodDescriptorpublicGetOffersByCategory = new grpcWeb.MethodDescriptor(
    '/Offer/publicGetOffersByCategory',
    grpcWeb.MethodType.UNARY,
    offer_pb.GetOffersByCategoryRequest,
    offer_pb.OffersResponse,
    (request: offer_pb.GetOffersByCategoryRequest) => {
      return request.serializeBinary();
    },
    offer_pb.OffersResponse.deserializeBinary
  );

  publicGetOffersByCategory(
    request: offer_pb.GetOffersByCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<offer_pb.OffersResponse>;

  publicGetOffersByCategory(
    request: offer_pb.GetOffersByCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: offer_pb.OffersResponse) => void): grpcWeb.ClientReadableStream<offer_pb.OffersResponse>;

  publicGetOffersByCategory(
    request: offer_pb.GetOffersByCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: offer_pb.OffersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Offer/publicGetOffersByCategory',
        request,
        metadata || {},
        this.methodDescriptorpublicGetOffersByCategory,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Offer/publicGetOffersByCategory',
    request,
    metadata || {},
    this.methodDescriptorpublicGetOffersByCategory);
  }

  methodDescriptorgetMyOffers = new grpcWeb.MethodDescriptor(
    '/Offer/getMyOffers',
    grpcWeb.MethodType.UNARY,
    offer_pb.GetOffersByCategoryRequest,
    offer_pb.OffersResponse,
    (request: offer_pb.GetOffersByCategoryRequest) => {
      return request.serializeBinary();
    },
    offer_pb.OffersResponse.deserializeBinary
  );

  getMyOffers(
    request: offer_pb.GetOffersByCategoryRequest,
    metadata: grpcWeb.Metadata | null): Promise<offer_pb.OffersResponse>;

  getMyOffers(
    request: offer_pb.GetOffersByCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: offer_pb.OffersResponse) => void): grpcWeb.ClientReadableStream<offer_pb.OffersResponse>;

  getMyOffers(
    request: offer_pb.GetOffersByCategoryRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: offer_pb.OffersResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Offer/getMyOffers',
        request,
        metadata || {},
        this.methodDescriptorgetMyOffers,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Offer/getMyOffers',
    request,
    metadata || {},
    this.methodDescriptorgetMyOffers);
  }

  methodDescriptorcreateTradeRequest = new grpcWeb.MethodDescriptor(
    '/Offer/createTradeRequest',
    grpcWeb.MethodType.UNARY,
    offer_pb.CreateTradeRequest,
    offer_pb.CreateTradeRequestResponse,
    (request: offer_pb.CreateTradeRequest) => {
      return request.serializeBinary();
    },
    offer_pb.CreateTradeRequestResponse.deserializeBinary
  );

  createTradeRequest(
    request: offer_pb.CreateTradeRequest,
    metadata: grpcWeb.Metadata | null): Promise<offer_pb.CreateTradeRequestResponse>;

  createTradeRequest(
    request: offer_pb.CreateTradeRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: offer_pb.CreateTradeRequestResponse) => void): grpcWeb.ClientReadableStream<offer_pb.CreateTradeRequestResponse>;

  createTradeRequest(
    request: offer_pb.CreateTradeRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: offer_pb.CreateTradeRequestResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Offer/createTradeRequest',
        request,
        metadata || {},
        this.methodDescriptorcreateTradeRequest,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Offer/createTradeRequest',
    request,
    metadata || {},
    this.methodDescriptorcreateTradeRequest);
  }

  methodDescriptorpublicGetOfferTradeRequests = new grpcWeb.MethodDescriptor(
    '/Offer/publicGetOfferTradeRequests',
    grpcWeb.MethodType.UNARY,
    offer_pb.PublicGetTradeRequestsForOfferRequest,
    offer_pb.PublicGetTradeRequestsForOfferResponse,
    (request: offer_pb.PublicGetTradeRequestsForOfferRequest) => {
      return request.serializeBinary();
    },
    offer_pb.PublicGetTradeRequestsForOfferResponse.deserializeBinary
  );

  publicGetOfferTradeRequests(
    request: offer_pb.PublicGetTradeRequestsForOfferRequest,
    metadata: grpcWeb.Metadata | null): Promise<offer_pb.PublicGetTradeRequestsForOfferResponse>;

  publicGetOfferTradeRequests(
    request: offer_pb.PublicGetTradeRequestsForOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: offer_pb.PublicGetTradeRequestsForOfferResponse) => void): grpcWeb.ClientReadableStream<offer_pb.PublicGetTradeRequestsForOfferResponse>;

  publicGetOfferTradeRequests(
    request: offer_pb.PublicGetTradeRequestsForOfferRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: offer_pb.PublicGetTradeRequestsForOfferResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Offer/publicGetOfferTradeRequests',
        request,
        metadata || {},
        this.methodDescriptorpublicGetOfferTradeRequests,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Offer/publicGetOfferTradeRequests',
    request,
    metadata || {},
    this.methodDescriptorpublicGetOfferTradeRequests);
  }

  methodDescriptorpublicGetOfferCategories = new grpcWeb.MethodDescriptor(
    '/Offer/publicGetOfferCategories',
    grpcWeb.MethodType.UNARY,
    offer_pb.PublicGetOfferCategoriesRequest,
    offer_pb.PublicGetOfferCategoriesResponse,
    (request: offer_pb.PublicGetOfferCategoriesRequest) => {
      return request.serializeBinary();
    },
    offer_pb.PublicGetOfferCategoriesResponse.deserializeBinary
  );

  publicGetOfferCategories(
    request: offer_pb.PublicGetOfferCategoriesRequest,
    metadata: grpcWeb.Metadata | null): Promise<offer_pb.PublicGetOfferCategoriesResponse>;

  publicGetOfferCategories(
    request: offer_pb.PublicGetOfferCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: offer_pb.PublicGetOfferCategoriesResponse) => void): grpcWeb.ClientReadableStream<offer_pb.PublicGetOfferCategoriesResponse>;

  publicGetOfferCategories(
    request: offer_pb.PublicGetOfferCategoriesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: offer_pb.PublicGetOfferCategoriesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Offer/publicGetOfferCategories',
        request,
        metadata || {},
        this.methodDescriptorpublicGetOfferCategories,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Offer/publicGetOfferCategories',
    request,
    metadata || {},
    this.methodDescriptorpublicGetOfferCategories);
  }

}

