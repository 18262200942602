import { FC } from 'react';
import cn from 'classnames';
import * as classes from './Chat.module.css';
import { ChatDTO } from 'grpc-era/chat_pb';
import { useSolanaService } from '../../../../hooks/useSolanaService';
import { NewMessageIcon } from '../../../../ui/NewMessageIcon/NewMessageIcon';

type Props = {
    chat: ChatDTO.AsObject;
};

export const Chat: FC<Props> = ({ chat }) => {
    const { publicKey } = useSolanaService();

    if (!publicKey) {
        return null;
    }

    const partner = chat.fromPublicKey === publicKey.toString() ? chat.toPublicKey : chat.fromPublicKey;

    return (
        <div className={classes.wrapper}>
            <div>
                <p className={classes.id}>{`${String(partner).substring(0, 3)}`}</p>
            </div>
            <div className={cn(classes.content, 'overflow-x-hidden')}>
                <span className={cn(classes.chatId, 'text-ellipsis overflow-x-hidden')}>{partner}</span>
                <span className={classes.text}>{chat.lastMessage}</span>
            </div>
            <div className={cn(classes.dateWrapper, 'h-full py-3')}>
                <p className={classes.date}>{`${chat.updatedAt.substring(8, 10)}.${chat.updatedAt.substring(5, 7)}`}</p>
                {!chat.wasRead && <NewMessageIcon />}
            </div>
        </div>
    );
};
