import { FC } from 'react';
import styles from './Landing.module.css';
import truck0004 from './assets/Img/png/truck0004.png';
import { Advantages } from './components/Advantages/Advantages';
import { Header } from './components/Header/Header';
import { EraAbout } from './components/EraAbout/EraAbout';
import { Scroll } from './components/Scroll/Scroll';
import { Deal } from './components/Deal/Deal';
import { WhatIs } from './components/WhatIs/WhatIs';
import { GlobalMarket } from './components/GlobalMarket/GlobalMarket';
import { NewExperience } from './components/NewExperience/NewExperience';
import { CryptoUniverse } from './components/CryptoUniverse/CryptoUniverse';
import { News } from './components/News/News';
import { Groups } from './components/Groups/Groups';
import { Experts } from './components/Experts/Experts';
import { Footer } from '../../ui/layout/Footer/Footer';
import { Map } from './components/Map/Map';

export const MobileLayout: FC = () => {
    return (
        <div className={styles.topLevelContainer}>
            <div className={styles.wrapper}>
                <Header />
                <Scroll />
                <img src={truck0004} alt="truck-img" className={styles.topImgTruck} />
                <div className={styles.container}>
                    <EraAbout />
                    <Deal />
                    <Advantages />
                </div>
                <WhatIs />
                <Map />
                <GlobalMarket />
                <NewExperience />
                <CryptoUniverse />
                <News />
                <Groups />
                <Experts />
                <Footer />
            </div>
        </div>
    );
};
