import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { config } from '../../../config/config';
import { LocalStorageKeysEnum } from '../../constants';
import { ApplicationLocalContext, ApplicationLocalType } from '../../hooks/useApplicationLocalState';

const getApplicationLocalState = (): Partial<ApplicationLocalType> | undefined => {
    const state = localStorage.getItem(LocalStorageKeysEnum.ApplicationLocal);

    if (state) {
        return JSON.parse(state) as unknown as ApplicationLocalType;
    }

    return undefined;
};

export const ApplicationLocalState: FC = ({ children }) => {
    const [applicationLocalState, setApplicationLocalState] = useState<Partial<ApplicationLocalType> | undefined>({});

    useLayoutEffect(() => {
        const ls = getApplicationLocalState() || {};

        if (ls.tokenExpireAt && ls.tokenExpireAt <= Date.now()) {
            const expiredState = { ...ls, tokenExpireAt: undefined };
            setApplicationLocalState(() => {
                localStorage.setItem(LocalStorageKeysEnum.ApplicationLocal, JSON.stringify({ ...expiredState, token: undefined }));
                return expiredState;
            });
        }

        if (ls.readOnly === undefined) {
            ls.readOnly = true
        }

        setApplicationLocalState(ls);
    }, [setApplicationLocalState]);

    const handleSetToken = useCallback(
        (token: string) => {
            const expireTimestamp = Date.now() + config.tokenLifetime;

            setApplicationLocalState((prev) => {
                localStorage.setItem(
                    LocalStorageKeysEnum.ApplicationLocal,
                    JSON.stringify({ ...prev, token, tokenExpireAt: expireTimestamp })
                );
                return { ...prev, tokenExpireAt: expireTimestamp };
            });
        },
        [setApplicationLocalState, applicationLocalState]
    );

    const handleSetCookieConfirmed = useCallback(
        (flag: boolean) => {
            setApplicationLocalState((prev) => {
                localStorage.setItem(
                    LocalStorageKeysEnum.ApplicationLocal,
                    JSON.stringify({ ...prev, cookieConfirmed: flag })
                );
                return { ...prev, cookieConfirmed: flag };
            });
        },
        [setApplicationLocalState, applicationLocalState]
    );

    const handleSetReadOnly = useCallback(
        (flag: boolean) => {
            setApplicationLocalState((prev) => {
                localStorage.setItem(
                    LocalStorageKeysEnum.ApplicationLocal,
                    JSON.stringify({ ...prev, readOnly: flag })
                );

                return { ...prev, readOnly: flag };
            });
        },
        [setApplicationLocalState, applicationLocalState]
    );

    const contextValue = useMemo(
        () => ({
            context: applicationLocalState,
            setToken: handleSetToken,
            setCookieConfirmed: handleSetCookieConfirmed,
            setReadOnly: handleSetReadOnly,
        }),
        [applicationLocalState]
    );

    return <ApplicationLocalContext.Provider value={contextValue}>{children}</ApplicationLocalContext.Provider>;
};

