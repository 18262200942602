import { FC } from 'react';
// import { InstallPhantomModal } from '../InstallPhantomModal/InstallPhantomModal';
import { AuthModal } from './Auth/Auth';
import { useSelector } from 'react-redux';
import { selectModalType } from './store/modalSlice';
import { BuyLinksModal } from './BuyLinksModal/BuyLinksModal';

export const Modals: FC = () => {
    const modalType = useSelector(selectModalType);

    if (modalType === 'buyUsdt') {
        return <BuyLinksModal />;
    }

    if (modalType === 'auth') {
        return <AuthModal />;

        // const isPhantomInstalled = typeof window !== 'undefined' && !!(window as any).solana?.isPhantom;
        //
        // return isPhantomInstalled ? <AuthModal /> : <InstallPhantomModal />;
    }

    return null;
};
