.searchArea {
    border: 1px solid #808897;
    border-radius: 10px;
    padding: 10px 20px;
}

.input {
    border: none;
    outline: none;
    width: 100%;
    background: transparent;
    color: #fff;
}

::placeholder {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #fff !important;
    opacity: 1;
}
