import { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DealDTO } from 'grpc-era/deal_pb';
import { Button } from '../../ui/Button/Button';
import { Text } from '../../ui/Text/Text';
import { GrpcService } from '../../services/grpcService';
import { useSelector } from 'react-redux';
import { selectToken } from '../../store/slices/authSlice';
import { Spinner } from '../../ui/Spinner/Spinner';
import { useSolanaService } from '../../hooks/useSolanaService';
import { generateDealStepsData } from '../Deals/components/Deal/Deal';
import { ErrorCodes } from '../../constants';
import { useAppDispatch } from '../../store/store';
import { showAuthModal } from '../../components/Modals/store/modalSlice';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const Admin: FC = () => {
    const { t } = useTranslation();

    const params = useParams<{ dealId: string }>();
    const dealId = Number(params.dealId);
    const dispatch = useAppDispatch();
    const token = useSelector(selectToken);

    const [deal, setDeal] = useState<DealDTO.AsObject | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchDeal = useCallback(() => {
        let tries = 1;

        const fetcher = () =>
            GrpcService.getDeal(dealId, token)
                .then((res) => setDeal(res))
                .catch((error) => {
                    if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                        dispatch(showAuthModal());
                        return;
                    }
                    toast('Error occurred');
                    console.error(error);
                    if (tries <= 2) {
                        tries++;
                        setTimeout(() => {
                            fetcher();
                        }, 2000);
                    }
                });

        fetcher();
    }, [dealId, dispatch, token]);

    useEffect(() => {
        fetchDeal();
    }, [fetchDeal]);

    const { judgeConfirm, judgeCancel } = useSolanaService();

    const sellerWins = useCallback(async () => {
        if (deal) {
            setLoading(true);
            judgeConfirm(deal.sellerPublicKey, deal.escrowAccount).finally(() => {
                fetchDeal();
                setLoading(false);
            });
        }
    }, [deal, judgeConfirm, fetchDeal]);

    const buyerWins = useCallback(async () => {
        if (deal) {
            setLoading(true);
            judgeCancel(deal.buyerPublicKey, deal.escrowAccount).finally(() => {
                fetchDeal();
                setLoading(false);
            });
        }
    }, [deal, judgeCancel, fetchDeal]);

    const tradeStatus = deal && generateDealStepsData(deal);

    return (
        <div className="p-5">
            {!deal && <Spinner />}

            {deal && (
                <>
                    <div className="flex flex-col">
                        <Text>{`${t('Deal')} № ${deal.dealId}`}</Text>
                        <Text>{`${t('Offer')} № ${deal.offerid}`}</Text>
                        <Text>{`${t('Status')} ${tradeStatus?.activeStep}`}</Text>
                    </div>

                    <div className="my-2">{t('Who wins?')}</div>

                    <div className="flex justify-between gap-4">
                        <Button colorScheme="white" disabled={loading} onClick={sellerWins}>
                            <Text className="capitalize">{t('Seller')}</Text>
                        </Button>
                        <Button colorScheme="white" disabled={loading} onClick={buyerWins}>
                            <Text className="capitalize">{t('Buyer')}</Text>
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};
