import { FC } from 'react';
import styles from './Scroll.module.css';

export const Scroll: FC = () => (
    <div className={styles.scrollWrapper}>
        <div className={styles.scroll}>
            <span>Scroll</span>
        </div>
    </div>
);
