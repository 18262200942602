import { FC, useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';

type TransitionState = 'entering' | 'entered' | 'exiting' | 'exited';

const transitionTimeout = 500;

const defaultStyle = {
    transition: `opacity ${transitionTimeout}ms ease-in-out`,
    opacity: 0,
    height: 'calc(100% - 5rem)'
};

const transitionStyles: { [state in TransitionState]: { opacity: number } } = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
};

export const Animated: FC = ({ children }) => {
    const [inProp, setInProp] = useState(false);

    useEffect(() => {
        setInProp(() => true);
    }, []);

    return (
        <Transition in={inProp} timeout={transitionTimeout}>
            {(state: TransitionState) => <div style={{ ...defaultStyle, ...transitionStyles[state] }}>{children}</div>}
        </Transition>
    );
};
