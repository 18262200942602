import { FC } from 'react';
import React from 'react';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import { Configuration } from 'rollbar';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from '../config/config';
import { GlobalStreamsProvider } from './components/GlobalStreamsProvider/GlobalStreamsProvider';
import { BottomNavigation } from './components/Navigation/BottomNavigation';
import { Modals } from './components/Modals';
import { PublicDataProvider } from './components/PublicDataProvider/PublicDataProvider';
import { ConfirmCookies } from './components/ConfirmCookies/ConfirmCookies';
import { ErrorDisplay } from './components/ErrorDisplay/ErrorDisplay';
import { Router } from './components/Router/Router';
import { SolanaWalletProvider } from './components/SolanaWalletProvider/SolanaWalletProvider';
import { browserHistory, store } from './store/store';
import { SelectCountry } from './components/SelectCountry/SelectCountry';
import { ApplicationLocalState } from './components/ApplicationLocalState/ApplicationLocalState';

const rollbarConfig: Configuration = {
    accessToken: config.rollbarAccessToken,
    environment: config.mode,
    enabled: !!config.rollbarAccessToken,
    // autoInstrument: {
    //     network: true,
    //     log: false,
    //     dom: false,
    //     navigation: false,
    //     connectivity: false,
    //     contentSecurityPolicy: false,
    // }
};

export const App: FC = () => (
    <Provider store={store}>
        <ConnectedRouter history={browserHistory} noInitialPop>
            <SolanaWalletProvider>
                <RollbarProvider config={rollbarConfig}>
                    <ErrorBoundary fallbackUI={ErrorDisplay}>
                        <ApplicationLocalState>
                            <PublicDataProvider>
                                <ToastContainer />
                                <Modals />
                                <SelectCountry />
                                <ConfirmCookies />
                                <GlobalStreamsProvider />
                                <div className="h-[calc(100%-6rem)] overflow-y-auto">
                                    <Router />
                                </div>

                                <BottomNavigation />
                            </PublicDataProvider>
                        </ApplicationLocalState>
                    </ErrorBoundary>
                </RollbarProvider>
            </SolanaWalletProvider>
        </ConnectedRouter>
    </Provider>
);

