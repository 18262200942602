import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { uploadUrl } from '../constants';
import { ErrorService, IErrorService } from './errorService';

export class HttpService {
    private axios: AxiosInstance;
    private errorService: IErrorService;

    constructor(config?: AxiosRequestConfig) {
        this.errorService = new ErrorService();

        this.axios = axios.create({
            baseURL: uploadUrl,
            ...config,
        });

        this.axios.interceptors.response.use(
            (res) => res,
            (error) => {
                this.errorService.notify(error);

                return Promise.reject(error);
            }
        );

        this.post = this.post.bind(this)
    }

    async get() {
        throw new Error('Not implemented');
    }

    post<T = any>(url: string, data: any): Promise<{ data: T | undefined; error: any | undefined }> {
        return this.axios
            .post(url, data)
            .then((res: AxiosResponse) => ({
                data: res.data as T,
                error: undefined,
            }))
            .catch((error) => ({ error, data: undefined }));
    }

    // async post(url: string, data: any) {
    //     return await this.axios.post(url, data);
    // }

    delete() {
        throw new Error('Not implemented');
    }

    put() {
        throw new Error('Not implemented');
    }
}
