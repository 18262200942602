import { FC } from 'react';
import { Image } from '../Image/Image';

type Props = {
    imageUrl: string;
};

export const ProductImage: FC<Props> = ({ imageUrl }) => (
    <Image
        src={imageUrl}
        alt="product-img"
        className="w-full aspect-square object-contain bg-white rounded"
    />
);
