import { FC } from 'react';
import { Logo } from '../Logo/Logo';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';
import { navigate } from '../../helpers/navigate';

export const Header: FC = () => {
    return (
        <div className="flex justify-between mt-5">
            <div className="grow-0 w-full" />
            <div className="grow-0 w-full flex justify-center" onClick={() => navigate('https://eracrypto.app/')}>
                <Logo />
            </div>
            <div className="grow-0 w-full flex items-end justify-end z-20">
                <LanguageSwitcher />
            </div>
        </div>
    );
};

