import { FC } from 'react';
import styles from './Advantages.module.css';

const advantagesData = [
    {
        img: require('../../assets/Img/multi-currency.svg'),
        imgAlt: 'multi-currency-img',
        title: 'Multi-currency deals',
        text: 'ERA crypto allows you to pay in your currency while your suppliers will receive payments in their preferred currencies',
    },
    {
        img: require('../../assets/Img/safety-reliability.svg'),
        imgAlt: 'safety-reliability-img',
        title: 'Safety & Reliability',
        text: 'ERA crypto is fast and safe solution for those who having need to speed up processes',
    },
    {
        img: require('../../assets/Img/efficient.svg'),
        imgAlt: 'efficient-img',
        title: 'Efficient use of resources',
        text: 'To ensure that your business runs smoothly and efficiently, you need ERA crypto to make the best of your buying experience',
    },
];

export const Advantages: FC = () => (
    <div className={styles.advantages}>
        {advantagesData.map(({ img, imgAlt, title, text }) => (
            <div className={styles.advantagesWrapper} key={title}>
                <div className={styles.advantagesBlock}>
                    <img src={img} alt={imgAlt} />
                    <span className={styles.advantagesTitle}>{title}</span>
                </div>
                <div className={styles.advantagesText}>{text}</div>
            </div>
        ))}
    </div>
);
