import { useCallback, useEffect } from 'react';
import { useAppDispatch } from '../store/store';
// import { getHaveNewMessages } from '../../Chats/store/chatSlice';
import { useSelector } from 'react-redux';
import { selectToken } from '../store/slices/authSlice';
// import {GrpcService} from "../services/grpcService";
// import {ClientReadableStream} from "grpc-web";
// import {GetUnreadMessagesResponse} from "grpc-era/chat_pb";
import { haveNewMessages } from '../screens/Chats/store/chatSlice';
import { websocketService } from '../services/wsService';
import { config } from '../../config/config';
import { UnreadMessagedWsResponseDTO } from '../services/types';

export const useUnreadMessagesStream = () => {
    const dispatch = useAppDispatch();
    const token = useSelector(selectToken);

    const messageHandler = useCallback(
        (data: UnreadMessagedWsResponseDTO) => {
            dispatch(haveNewMessages(!data.wasRead));
        },
        [dispatch]
    );

    useEffect(() => {
        const ws = websocketService(config.websocket.unreadMessages, messageHandler);
        ws.connect(token);

        return () => {
            ws.disconnect();
        };
    }, [token, messageHandler]);

    // useEffect(() => {
    //     let stream: ClientReadableStream<GetUnreadMessagesResponse.AsObject>
    //
    //     if (token) {
    //         GrpcService.listenUnreadMessages({ auth: token }, (message) => {
    //             console.log('new msg', message)
    //             dispatch(haveNewMessages(!message.wasRead))
    //         })
    //     }
    //
    //     return () => {
    //         if (stream) {
    //             stream.cancel()
    //         }
    //     }
    // }, [dispatch, token]);

    return null;
};
