import { useEffect, useState, FC, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store/store';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import * as classes from './CurrentChat.module.css';
import moment from 'moment';
import arrow from '../../../../../../assets/icon/arrow.svg';
import {
    dropOpenedChatId,
    getAllChats,
    getChatMessages,
    selectMessages,
    selectMessagesLoadingStatus,
    selectOpenedChat,
    sendMessage,
} from '../../store/chatSlice';
import { LoadingStatusesRecord } from '../../../../constants';
import { useSolanaService } from '../../../../hooks/useSolanaService';
import { Spinner } from '../../../../ui/Spinner/Spinner';
import { Animated } from '../../../../ui/Animated/Animated';
import { Subheader } from '../../../../components/Subeader/Subheader';
import {useTranslation} from "react-i18next";

export const CurrentChat: FC = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation()

    const messages = useSelector(selectMessages);
    const messagesLoadingStatus = useSelector(selectMessagesLoadingStatus);
    const chat = useSelector(selectOpenedChat);
    const { id } = useParams<{ id: string }>();

    const [message, setMessage] = useState('');

    const { publicKey } = useSolanaService();

    const pk = publicKey?.toString();

    const recipient = pk === chat?.fromPublicKey ? chat?.toPublicKey : chat?.fromPublicKey;

    const sendOurMessage = () =>
        chat &&
        message.trim().length &&
        dispatch(sendMessage({ message, to: recipient! }))
            .then(() => dispatch(getChatMessages({ chatId: Number(id) })))
            .then(() => setMessage(''));

    useEffect(() => {
        id && dispatch(getAllChats());
        id && dispatch(getChatMessages({ chatId: Number(id) }));

        return () => {
            dispatch(dropOpenedChatId());
        };
    }, [id, dispatch]);

    const messageEndRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    if (!pk) {
        return null;
    }

    return (
        <>
            <Subheader title={t("Chat")} caption={recipient && `${t('Wallet')} ${recipient}`} />
            <Animated>
                <div className="h-full flex flex-col ">
                    <div className={cn(classes.wrapper, 'flex-1 px-5')}>
                        {messagesLoadingStatus === LoadingStatusesRecord.Loading ? (
                            <Spinner showText={true} />
                        ) : (
                            [...messages].reverse().map((message) => (
                                <div
                                    className={
                                        pk !== message.toPublicKey
                                            ? classes.messageFromWrapper
                                            : classes.messageToWrapper
                                    }
                                    key={message.id}
                                >
                                    <div
                                        className={cn('',
                                            pk !== message.toPublicKey ? classes.messageFrom : classes.messageTo
                                        )}
                                    >
                                        <div className="w-full flex-1 overflow-hidden">
                                            <p className="break-words">{message.message}</p>
                                        </div>
                                        <div className={classes.time}>
                                            <span>{moment(message.createdAt).format('hh:mm')}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    <div ref={messageEndRef} />
                    <div>
                        <div className={classes.form}>
                            <input
                                className={classes.input}
                                type="text"
                                value={message}
                                placeholder={t("Your message")!}
                                onKeyDown={(e) => e.key === 'Enter' && sendOurMessage()}
                                onChange={(e) => {
                                    setMessage(e.target.value);
                                }}
                            />

                            <div onClick={sendOurMessage} className={classes.button}>
                                <img src={String(arrow)} alt="arrow" />
                            </div>
                        </div>
                    </div>
                </div>
            </Animated>
        </>
    );
};
