import { FC, Fragment } from 'react';
import classNames from 'classnames';

type Props = {
    steps: Array<{ label: string; id: string | number }>;
    activeStepId: string | number;
    className?: string;
};

export const Stepper: FC<Props> = ({ steps, className, activeStepId }) => {
    return (
        <div className={classNames(className, 'flex items-center gap-4 justify-between')}>
            {steps.map(({ label, id }, index) => (
                <Fragment key={id}>
                    <div
                        className={classNames('font-bold text-sm leading-4', {
                            ['text-brand-600']: id !== activeStepId,
                        })}
                    >
                        {label}
                    </div>

                    {index !== steps.length - 1 && <div className="grow h-px bg-gradient-white" />}
                </Fragment>
            ))}
        </div>
    );
};
