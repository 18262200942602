import deliveryIcon from '../../../assets/icon/CategoryDelivery.png';
import buildingMaterialsIcon from '../../../assets/icon/CategoryBuildingMaterials.png';
import clothinIcon from '../../../assets/icon/CategoryClothing.png';
import computersSmartphonesIcon from '../../../assets/icon/CategoryComputersSmartphones.png';
import digitalGoodsIcon from '../../../assets/icon/CategoryDigitalGoods.png';
import digitalContentIcon from '../../../assets/icon/CategoryDigitalContent.png';
import equipmentIcon from '../../../assets/icon/CategoryEquipment.png';
import foodIcon from '../../../assets/icon/CategoryFood.png';
import gamesIcon from '../../../assets/icon/CategoryGames.png';
import jewellerIcon from '../../../assets/icon/CategoryJewellery.png';
import realEstateIcon from '../../../assets/icon/CategoryRealEstate.png';
import servicesIcon from '../../../assets/icon/CategoryServices.png';
import softwareIcon from '../../../assets/icon/CategorySoftware.png';
import tobaccoIcon from '../../../assets/icon/CategoryTobacco.png';
import vehiclesIcon from '../../../assets/icon/CategoryVehicles.png';

export const categoryIconsMap: { [key: string]: string } = {
    delivery: deliveryIcon,
    'building materials': buildingMaterialsIcon,
    'clothing': clothinIcon,
    'computers and smartphones': computersSmartphonesIcon,
    'digital goods': digitalGoodsIcon,
    'digital content': digitalContentIcon,
    equipment: equipmentIcon,
    food: foodIcon,
    games: gamesIcon,
    jeweller: jewellerIcon,
    jewellery: jewellerIcon,
    'real estate': realEstateIcon,
    'real food': foodIcon,
    services: servicesIcon,
    software: softwareIcon,
    tobacco: tobaccoIcon,
    vehicles: vehiclesIcon,
};
