import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorCodes, LoadingStatusesRecord, SubscriptionStatusesEnum } from '../../../constants';
import { RootState } from '../../../store/store';
import { GrpcService } from '../../../services/grpcService';
import { selectToken } from '../../../store/slices/authSlice';
import { showAuthModal } from '../../../components/Modals/store/modalSlice';
import { LoadingStatuses } from '../../../types/LoadingStatuses';
import { DealWsResponseDTO } from '../../../services/types';

type AdditionalFields = {
    subscriptionStatus: SubscriptionStatusesEnum;
    loadingStatus: LoadingStatuses;
};

const initialAdditionalFields: AdditionalFields = {
    subscriptionStatus: SubscriptionStatusesEnum.Initial,
    loadingStatus: LoadingStatusesRecord.Initial,
};

export const getDeals = createAsyncThunk<Array<DealWsResponseDTO>, void, { state: RootState }>(
    'deals/getDeals',
    async (_, { getState, dispatch }) => {
        const token = selectToken(getState());

        return GrpcService.getDeals(token)
            .then((res) => res.toObject().dealsList)
            .then((dealsList) => dealsList.map((deal) => ({ ...deal, offerData: JSON.parse(deal.offerData)?.details })))
            .catch((error) => {
                if (error) {
                    if (error.code && error.code === ErrorCodes.FORBIDDEN) {
                        dispatch(showAuthModal());
                    } else if (error.name && error.name === ErrorCodes.RPC_ERROR) {
                        dispatch(showAuthModal());
                    }
                }

                return Promise.reject(error);
            }) as unknown as Promise<Array<DealWsResponseDTO>>;
    }
);

const selectId = ({ dealId }: DealWsResponseDTO) => dealId;
const sortComparer = ({ createdAt: createdAt1 }: DealWsResponseDTO, { createdAt: createdAt2 }: DealWsResponseDTO) =>
    Date.parse(createdAt2) - Date.parse(createdAt1);

const dealsAdapter = createEntityAdapter<DealWsResponseDTO>({ selectId, sortComparer });

export const dealsSlice = createSlice({
    name: 'deals',
    initialState: dealsAdapter.getInitialState(initialAdditionalFields),
    reducers: {
        updateDeals(draftState, { payload }: PayloadAction<Array<DealWsResponseDTO>>) {
            dealsAdapter.setMany(draftState, payload);
            draftState.subscriptionStatus = SubscriptionStatusesEnum.Subscribed;
        },
        updateDealsSubscriptionStatus(draftState, { payload }: PayloadAction<SubscriptionStatusesEnum>) {
            draftState.subscriptionStatus = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDeals.pending, (draftState) => {
            draftState.loadingStatus = LoadingStatusesRecord.Loading;
        });
        builder.addCase(getDeals.rejected, (draftState) => {
            draftState.loadingStatus = LoadingStatusesRecord.Error;
        });
        builder.addCase(getDeals.fulfilled, (draftState, { payload }: PayloadAction<Array<DealWsResponseDTO>>) => {
            draftState.loadingStatus = LoadingStatusesRecord.Loaded;
            dealsAdapter.setMany(draftState, payload);
        });
    },
});

export const { updateDeals, updateDealsSubscriptionStatus } = dealsSlice.actions;

const selectSlice = ({ deals }: RootState) => deals;

export const selectDealsList = dealsAdapter.getSelectors(selectSlice).selectAll;

export const selectDealsListLength = dealsAdapter.getSelectors(selectSlice).selectTotal;

export const selectDealById = (id?: number) =>
    createSelector(selectSlice, (slice) => (id ? dealsAdapter.getSelectors().selectById(slice, id) : undefined));

export const selectDealsSubscriptionStatus = createSelector(
    selectSlice,
    ({ subscriptionStatus }) => subscriptionStatus
);

export const selectDealsLoadingStatus = createSelector(selectSlice, ({ loadingStatus }) => loadingStatus);
