.news {
    margin-bottom: 100px;
    position: relative;
    z-index: 2;
}
.newsWrapper {
    margin-bottom: 35px;
}
.newsWrapper:last-child {
    margin-bottom: 0;
}
.newsBlock {
    padding: 0 18px;
    display: grid;
    justify-content: center;
}
.newsData {
    display: flex;
}
.newsInfo {
    margin: 10px 0 0 45px;
}
.date {
    margin-left: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: lowercase;
    opacity: 0.5;
}
.newsText {
    margin-top: 7px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
}
