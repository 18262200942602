import { FC } from 'react';
import styles from './News.module.css';
import { Title } from '../../../../ui/Title/Title';
import { Gradient } from '../../../../ui/Gradient/Gradient';

const newsData = [
    {
        id: 1,
        img: require('../../assets/Img/picDone.svg'),
        alt: 'pic-date',
        date: "dec 21'",
        text: 'Analyzing experience and environment',
        about: 'Some less text',
    },
    {
        id: 2,
        img: require('../../assets/Img/picDone.svg'),
        alt: 'pic-date',
        date: "dec 21'",
        text: 'Developing corporate style & brand',
        about: 'Some less text',
    },
    {
        id: 3,
        img: require('../../assets/Img/picDotted.svg'),
        alt: 'pic-date',
        date: "dec 21'",
        text: 'Analyzing experience and environment',
        about: 'Some less text',
    },
    {
        id: 4,
        img: require('../../assets/Img/picDotted.svg'),
        alt: 'pic-date',
        date: "dec 21'",
        text: 'Developing corporate style & brand',
        about: 'Some less text',
    },
];

export const News: FC = () => (
    <div className={styles.news}>
        <Gradient type="3" />
        <Title type="1">News</Title>
        <div className={styles.newsBlock}>
            {newsData.map(({ id, date, img, alt, text, about }) => (
                <div className={styles.newsWrapper} key={id}>
                    <div className={styles.newsData}>
                        <img src={img} alt={alt} />
                        <span className={styles.date}>{date}</span>
                    </div>
                    <div className={styles.newsInfo}>
                        <Title type="4">{text}</Title>
                        <div className={styles.newsText}>{about}</div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);
