import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../ui/Modal/Modal';
import { Text } from '../../../ui/Text/Text';
import { Button } from '../../../ui/Button/Button';
import binanceLogo from '../../../../../assets/svg/Binance_logo.svg';
import gateLogo from '../../../../../assets/svg/Gate_io_logo.svg';
import huobiLogo from '../../../../../assets/svg/Huobi_logo.svg';
import { useAppDispatch } from '../../../store/store';
import { closeBuyModal } from '../store/modalSlice';
import { navigate } from '../../../helpers/navigate';

const BuyItem: FC<{ logo: string; description: string; link: string }> = ({ logo, description, link }) => {
    const { t } = useTranslation();

    return (
        <div className="grid grid-cols-2 gap-x-4 p-5">
            <div>
                <div>
                    <img src={logo} alt="" />
                </div>
                <div className="mt-2 text-left !leading-none">
                    <Text className="text-xs leading-[inherit] ">{description}</Text>
                </div>
            </div>
            <div>
                <Button colorScheme="gradient" size="FILL" onClick={() => navigate(link)}>
                    <Text className="text-xl">{`${t('Buy')} USDT`}</Text>
                </Button>
            </div>
        </div>
    );
};

export const BuyLinksModal: FC = () => {
    const dispatch = useAppDispatch();

    const { t } = useTranslation();

    const handleClose = useCallback(() => {
        dispatch(closeBuyModal());
    }, [dispatch]);

    return (
        <Modal open={true} onClose={handleClose}>
            <div className="my-4">
                <Text className="text-2xl">{`${t('Buy')} USDT ${t('and')} SOL`}</Text>
            </div>

            <div className="grid grid-cols-1 auto-rows-fr">
                <BuyItem
                    logo={String(binanceLogo)}
                    description="Buy, trade, and hold 350+ cryptocurrencies on Binance"
                    link="https://www.binance.me/en/activity/referral-entry/CPA?fromActivityPage=true&ref=CPA_007YR6U0TP"
                />
                <BuyItem
                    logo={String(gateLogo)}
                    description="Trade over 1,400 cryptocurrencies safely, quickly, and easily"
                    link="https://www.gate.io/signup/12564942"
                />
                <BuyItem
                    logo={String(huobiLogo)}
                    description="Create your Huobi account to earn exciting welcome rewards"
                    link="https://www.huobi.com/en-us/v/register/double-invite/?inviter_id=11346560&invite_code=kjai6223"
                />
            </div>
        </Modal>
    );
};
