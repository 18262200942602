import { FC } from "react";
import predealPicture from '../../../../assets/svg/Predeal.svg'
import plusPicture from '../../../../assets/svg/plus-thin.svg'

export const PredealPicture: FC = () => {

    return (
        <div className="relative">
            <div className="absolute top-[33%] left-[46%]">
                <img src={String(plusPicture)} alt="+" />
            </div>
            <img src={String(predealPicture)} alt="" />
        </div>
    )
}