import { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Text } from '../../ui/Text/Text';
import { Input } from '../../ui/Input/Input';
import { CopyButton } from '../../ui/Button/CopyButton';
import { useSolanaService } from '../../hooks/useSolanaService';
import { buildReferralURL } from '../../helpers/buildReferralURL';
import { useAppDispatch } from '../../store/store';
import { getAllChats, selectChatsLoadingStatus } from '../Chats/store/chatSlice';
import { LoadingStatusesRecord } from '../../constants';
import { Animated } from '../../ui/Animated/Animated';
import { Spinner } from '../../ui/Spinner/Spinner';
import { Subheader } from '../../components/Subeader/Subheader';
import { selectToken } from '../../store/slices/authSlice';
import { showAuthModal } from '../../components/Modals/store/modalSlice';
import { useTranslation } from 'react-i18next';

export const Earn: FC = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const { publicKey } = useSolanaService();
    const token = useSelector(selectToken);
    const loaded = useSelector(selectChatsLoadingStatus) === LoadingStatusesRecord.Loaded;
    const loading = useSelector(selectChatsLoadingStatus) === LoadingStatusesRecord.Loading;
    const rejected = useSelector(selectChatsLoadingStatus) === LoadingStatusesRecord.Error;

    useEffect(() => {
        dispatch(getAllChats());
    }, [dispatch, token]);

    const host = window.location.host;
    const protocol = window.location.protocol;

    const login = useCallback(() => dispatch(showAuthModal()), [dispatch]);

    const refObj = useMemo(() => {
        let url = new URL(protocol + '//' + host);
        let shortUrl = '';
        if (publicKey && loaded) {
            url = buildReferralURL(protocol + '//' + host, 'ref', publicKey.toString());
            shortUrl = url.toString().slice(0, 40) + '...';
        }

        return { url, shortUrl };
    }, [host, publicKey, loaded, protocol]);

    const refLink = refObj.url.toString();
    const shortRefLink = refObj.shortUrl;

    const commissionPercent = '25';

    return (
        <Animated>
            <Subheader title={t('Earn with ERA Crypto!')} />

            {rejected && (
                <div className="py-5 px-10">
                    <Text>
                        {t('You should')}{' '}
                        <Text className="underline underline-offset-2" onClick={login}>
                            {t('Login')}
                        </Text>{' '}
                        {t('before access this page')}
                    </Text>
                </div>
            )}

            {loading && <Spinner />}

            {loaded && (
                <div className="flex flex-col h-full px-5 pt-5">
                    <div className="mx-auto">
                        <div className="mb-5">
                            <Text>{t('earnPercent', { percent: commissionPercent })}</Text>
                        </div>

                        <div>
                            <Text>{t('Your referral link')}:</Text>
                            <Input disabled={true} value={shortRefLink} icon={<CopyButton valueToCopy={refLink} />} />
                        </div>
                    </div>
                </div>
            )}
        </Animated>
    );
};
