import styles from './Spinner.module.css';
import cn from 'classnames';
import { FC } from 'react';
import classNames from 'classnames';

type Props = Partial<{
    className: string;
    text: string;
    showText: boolean;
    size: 'small' | 'medium' | 'large';
}>;

export const Spinner: FC<Props> = ({ className, showText, text = 'Loading...', size = 'medium' }) => (
    <span className={cn('w-full flex flex-col items-center justify-center h-full', className)}>
        <span
            className={classNames(styles.loading, {
                'w-[20] h-[20]': size === 'small',
                'w-[40] h-[40]': size === 'medium',
                'w-[60] h-[60]': size === 'large',
            })}
        />

        {showText && <h3 className="text-lg font-semibold italic mt-3">{text}</h3>}
    </span>
);
