import { FC } from 'react';
import { Image } from '../Image/Image';

type Props = {
    imageUrl: string;
    fallbackUrl?: string;
    alt?: string;
};

export const CategoryImage: FC<Props> = ({ imageUrl, fallbackUrl, alt }) => (
    <Image
        src={imageUrl}
        fallback={fallbackUrl}
        alt={alt ?? "category-img"}
        className="w-full aspect-square object-contain rounded"
    />
);
