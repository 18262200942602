.globalMarket {
    margin-top: 50px;
}
.globalMarketSelling {
    text-align: center;
    margin: 0 auto;
}
.globalMarketArrows {
    padding: 40px 0 95px 0;
    display: flex;
    justify-content: space-between;
    width: 130px;
    margin: 0 auto;
}

