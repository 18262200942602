export const share = async (shareData: ShareData): Promise<boolean> => {
    try {
        if (!navigator.canShare) {
            return false;
        }

        await navigator.share(shareData);
        return true;
    } catch (e: unknown) {
        console.log(e);
        return false;
    }
};
