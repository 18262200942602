import { createContext, useContext } from "react"

export type ApplicationLocalType = {
    tokenExpireAt: number;
    readOnly: boolean;
    cookieConfirmed: boolean;
}

type ApplicationLocalContext = {
    context: Partial<ApplicationLocalType> | undefined;
    setToken: (token: string) => void;
    setCookieConfirmed: (flag: boolean) => void;
    setReadOnly: (flag: boolean) => void;
}

export const ApplicationLocalContext = createContext<Partial<ApplicationLocalContext>>({})

export const useApplicationLocalState = () => useContext(ApplicationLocalContext)