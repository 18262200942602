/**
 * @fileoverview gRPC-Web generated client stub for 
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.15.8
// source: chat.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as chat_pb from './chat_pb';


export class ChatClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorsendMessage = new grpcWeb.MethodDescriptor(
    '/Chat/sendMessage',
    grpcWeb.MethodType.UNARY,
    chat_pb.SendMessageRequest,
    chat_pb.SendMessageResponse,
    (request: chat_pb.SendMessageRequest) => {
      return request.serializeBinary();
    },
    chat_pb.SendMessageResponse.deserializeBinary
  );

  sendMessage(
    request: chat_pb.SendMessageRequest,
    metadata: grpcWeb.Metadata | null): Promise<chat_pb.SendMessageResponse>;

  sendMessage(
    request: chat_pb.SendMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: chat_pb.SendMessageResponse) => void): grpcWeb.ClientReadableStream<chat_pb.SendMessageResponse>;

  sendMessage(
    request: chat_pb.SendMessageRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: chat_pb.SendMessageResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Chat/sendMessage',
        request,
        metadata || {},
        this.methodDescriptorsendMessage,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Chat/sendMessage',
    request,
    metadata || {},
    this.methodDescriptorsendMessage);
  }

  methodDescriptorgetChatMessages = new grpcWeb.MethodDescriptor(
    '/Chat/getChatMessages',
    grpcWeb.MethodType.UNARY,
    chat_pb.GetChatMessagesRequest,
    chat_pb.GetChatMessagesResponse,
    (request: chat_pb.GetChatMessagesRequest) => {
      return request.serializeBinary();
    },
    chat_pb.GetChatMessagesResponse.deserializeBinary
  );

  getChatMessages(
    request: chat_pb.GetChatMessagesRequest,
    metadata: grpcWeb.Metadata | null): Promise<chat_pb.GetChatMessagesResponse>;

  getChatMessages(
    request: chat_pb.GetChatMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: chat_pb.GetChatMessagesResponse) => void): grpcWeb.ClientReadableStream<chat_pb.GetChatMessagesResponse>;

  getChatMessages(
    request: chat_pb.GetChatMessagesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: chat_pb.GetChatMessagesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Chat/getChatMessages',
        request,
        metadata || {},
        this.methodDescriptorgetChatMessages,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Chat/getChatMessages',
    request,
    metadata || {},
    this.methodDescriptorgetChatMessages);
  }

  methodDescriptorgetChats = new grpcWeb.MethodDescriptor(
    '/Chat/getChats',
    grpcWeb.MethodType.UNARY,
    chat_pb.GetChatsRequest,
    chat_pb.GetChatsResponse,
    (request: chat_pb.GetChatsRequest) => {
      return request.serializeBinary();
    },
    chat_pb.GetChatsResponse.deserializeBinary
  );

  getChats(
    request: chat_pb.GetChatsRequest,
    metadata: grpcWeb.Metadata | null): Promise<chat_pb.GetChatsResponse>;

  getChats(
    request: chat_pb.GetChatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: chat_pb.GetChatsResponse) => void): grpcWeb.ClientReadableStream<chat_pb.GetChatsResponse>;

  getChats(
    request: chat_pb.GetChatsRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: chat_pb.GetChatsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Chat/getChats',
        request,
        metadata || {},
        this.methodDescriptorgetChats,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Chat/getChats',
    request,
    metadata || {},
    this.methodDescriptorgetChats);
  }

  methodDescriptoruploadChatFile = new grpcWeb.MethodDescriptor(
    '/Chat/uploadChatFile',
    grpcWeb.MethodType.UNARY,
    chat_pb.ChatFileUploadRequest,
    chat_pb.ChatFileUploadResponse,
    (request: chat_pb.ChatFileUploadRequest) => {
      return request.serializeBinary();
    },
    chat_pb.ChatFileUploadResponse.deserializeBinary
  );

  uploadChatFile(
    request: chat_pb.ChatFileUploadRequest,
    metadata: grpcWeb.Metadata | null): Promise<chat_pb.ChatFileUploadResponse>;

  uploadChatFile(
    request: chat_pb.ChatFileUploadRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: chat_pb.ChatFileUploadResponse) => void): grpcWeb.ClientReadableStream<chat_pb.ChatFileUploadResponse>;

  uploadChatFile(
    request: chat_pb.ChatFileUploadRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: chat_pb.ChatFileUploadResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Chat/uploadChatFile',
        request,
        metadata || {},
        this.methodDescriptoruploadChatFile,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Chat/uploadChatFile',
    request,
    metadata || {},
    this.methodDescriptoruploadChatFile);
  }

  methodDescriptorgetChatFiles = new grpcWeb.MethodDescriptor(
    '/Chat/getChatFiles',
    grpcWeb.MethodType.UNARY,
    chat_pb.GetChatsFilesRequest,
    chat_pb.GetChatsFilesResponse,
    (request: chat_pb.GetChatsFilesRequest) => {
      return request.serializeBinary();
    },
    chat_pb.GetChatsFilesResponse.deserializeBinary
  );

  getChatFiles(
    request: chat_pb.GetChatsFilesRequest,
    metadata: grpcWeb.Metadata | null): Promise<chat_pb.GetChatsFilesResponse>;

  getChatFiles(
    request: chat_pb.GetChatsFilesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: chat_pb.GetChatsFilesResponse) => void): grpcWeb.ClientReadableStream<chat_pb.GetChatsFilesResponse>;

  getChatFiles(
    request: chat_pb.GetChatsFilesRequest,
    metadata: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: chat_pb.GetChatsFilesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/Chat/getChatFiles',
        request,
        metadata || {},
        this.methodDescriptorgetChatFiles,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/Chat/getChatFiles',
    request,
    metadata || {},
    this.methodDescriptorgetChatFiles);
  }

}

