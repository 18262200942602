import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { createNewOffer } from '../../store/offersSlice';
import { bytesInMegabyte, MAX_PRICE_OFFER } from '../../../../constants';
import { config } from '../../../../../config/config';

export type FormValues = Parameters<typeof createNewOffer>[0];

const priceMinLimit = 0;
const priceMaxLimit = MAX_PRICE_OFFER;
const imageSizeLimit = bytesInMegabyte * config.maxFileSizeMB;

export const resolver = yupResolver(
    Yup.object().shape({
        price: Yup.number()
            .typeError('Price should be a valid number')
            .moreThan(Number(priceMinLimit), `Price should be greater than ${priceMinLimit}`)
            .max(Number(priceMaxLimit), `Price should not be more than ${priceMaxLimit} USD(T)`),
        imagesList: Yup.mixed()
            .test('required', 'Please upload a product image', (imagesList) => imagesList?.length)
            .test(
                'size',
                `Product image should not be more than ${config.maxFileSizeMB} Megabytes`,
                (imagesList) => imagesList?.[0]?.size <= imageSizeLimit
            ),
        header: Yup.string().required('Please enter a name of new offer'),
        offerData: Yup.string().required('Please enter offer details'),
    })
);
