.mapWrapper {
    position: relative;
    margin: 30px 0 40px 10px;
    background-image: url("../../assets/Img/map.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 320px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mapPoint {
    position: absolute;
    top: 8%;
    width: 300px;
}

@media (min-width: 400px) {
    .mapPoint {
        width: 340px;
        top: 3%;
    }
}

@media (min-width: 500px) {
    .mapPoint {
        width: 360px;
        top: 2%;
    }
}
