import { createAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../store/store';

type State = { type: 'auth' | 'cookies' | 'buyUsdt' | null };

const initialState: State = { type: null };

const SHOW_AUTH_MODAL = 'showAuthModal';
const HIDE_AUTH_MODAL = 'hideAuthModal';
const SHOW_BUY_MODAL = 'showBuyModal';
const HIDE_BUY_MODAL = 'hideBuyModal';

export const showAuthModal = createAction(SHOW_AUTH_MODAL);
export const closeAuthModal = createAction(HIDE_AUTH_MODAL);
export const showBuyModal = createAction(SHOW_BUY_MODAL);
export const closeBuyModal = createAction(HIDE_BUY_MODAL);

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(showAuthModal, (draftState) => {
                draftState.type = 'auth';
            })
            .addCase(closeAuthModal, (draftState) => {
                draftState.type = null;
            })
            .addCase(showBuyModal, (draftState) => {
                draftState.type = 'buyUsdt';
            })
            .addCase(closeBuyModal, (draftState) => {
                draftState.type = null;
            });
    },
});

const selectSlice = ({ modal }: RootState) => modal;

export const selectModalType = createSelector(selectSlice, ({ type }) => type);
