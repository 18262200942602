import { FC, Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { Control, Controller, Path } from 'react-hook-form';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { FieldValues } from 'react-hook-form/dist/types/fields';

export const Select = <ValueType, FormValues extends FieldValues>({
    options,
    control,
    name,
    className,
}: {
    options: Array<{ label: string; value: ValueType }>;
    control: Control<FormValues>;
    name: Path<FormValues>;
    className?: string;
}): ReturnType<FC> => (
    <Controller
        name={name}
        control={control}
        render={({ field }) => (
            <Listbox value={field.value} onChange={field.onChange} as="div" className={classNames('w-full h-[77px]', className)}>
                <div className="relative h-full">
                    <Listbox.Button className="relative w-full h-full rounded-lg border border-solid border-brand-600 py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300">
                        <span className="block truncate text-white bg-transparent ">
                            {options.find(({ value }) => value === field.value)?.label}
                        </span>

                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                    </Listbox.Button>

                    <Transition
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                            {options.map(({ label, value }) => (
                                <Listbox.Option
                                    key={label}
                                    className={({ active }) =>
                                        `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                            active ? 'bg-brand-800 text-white' : 'text-gray-900 bg-transparent'
                                        }`
                                    }
                                    value={value}
                                >
                                    {({ selected }) => (
                                        <>
                                            <span
                                                className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                            >
                                                {label}
                                            </span>

                                            {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-brand-700">
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        )}
    />
);
