import { FC } from 'react';

type Props = Partial<{
    message: string;
}>;

export const ErrorMessage: FC<Props> = ({ message }) => (
    <p className="bg-red-400 p-5 mt-16 mx-auto max-w-sm min-w-min text-center text-lg text-light font-semibold rounded">
        {message ?? 'Something went wrong...'}
    </p>
);
