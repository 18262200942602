{
    "translation": {
        "Login": "Login",
        "Offers": "Offers",
        "Offer": "Offer",
        "Buy": "Buy",
        "Sell": "Sell",
        "Search": "Search",
        "Price": "Price",
        "price": "price",
        "CONNECT WALLET": "CONNECT WALLET",
        "Free connect": "Connecting wallet is free! Era uses your waller for login only.",
        "Use your solana wallet": "Use your solana wallet is perfect for the fastest deals in the world",
        "Each deal is safe": "Each deal is safe.",
        "Your money is returned if the deal fails": "Your money is returned if the deal fails.",
        "Accept": "Accept",
        "Decline": "Decline",
        "Cookie ensure": "ERA crypto uses cookies to ensure your best experience on ERA crypto",
        "and": "and",
        "Deals": "Deals",
        "Deal": "Deal",
        "Status": "Status",
        "Who wins?": "Who wins?",
        "Seller": "Seller",
        "Buyer": "Buyer",
        "Loading": "Loading",
        "No chats yet": "No chats yet",
        "You should": "You should",
        "You need": "You need",
        "to see your chats": "to see your chats",
        "to see your deals": "to see your deals",
        "before access this page": "before access this page",
        "Chat": "Chat",
        "Wallet": "Wallet",
        "Your message": "Your message",
        "Couldn't load this deal": "Couldn't load this deal. Please try refreshing the page and check if you are using a valid link",
        "Couldn't load this offer": "Couldn't load this offer. Please try refreshing the page and check if you are using a valid link",
        "Couldn't load offers": "Couldn't load offers. Please try refreshing the page",
        "Couldn't find any offers": "Couldn't find any offers. Please try other settings",
        "Description": "Description",
        "DealState-Created": "The deal has started. Money is stored in blockchain. The seller is waiting for the buyer to approve this deal",
        "DealState-Cancelled": "Deal canceled by buyer. Money was returned to the buyer",
        "DealState-Approved": "Deal created. Money stored in blockchain. The buyer has approved the deal. The seller can start the delivery",
        "DealState-Paid": "Deal finished. Money has been sent to the seller",
        "Approve": "Approve",
        "Cancel": "Cancel",
        "Release": "Release",
        "Talk to buyer": "TALK TO BUYER",
        "Talk to seller": "TALK TO SELLER",
        "No deals": "You have not started any deals yet",
        "Proceed to": "Proceed to",
        "Earn with ERA Crypto!": "Earn with ERA Crypto!",
        "earnPercent": "You will receive {{percent}}% commission from each transaction if you share this link with a friend",
        "Your referral link": "Your referral link",
        "New offer": "New offer",
        "Own offer": "Own offer",
        "Offer name": "Offer name",
        "Offer price": "Offer price",
        "Offer description": "Offer description",
        "Details": "Details",
        "Upload": "Upload",
        "photo": "photo",
        "Create": "CREATE",
        "to ensure this transaction": "to ensure this transaction",
        "approximately": "approximately",
        "Start Deal": "START DEAL",
        "Delete Offer": "DELETE OFFER",
        "Sellers price offers": "Sellers’ price offers",
        "Suggested price": "Suggested price",
        "Product does not have a name": "Product does not have a name",
        "delivery": "Delivery",
        "building materials": "Building Materials",
        "clothing": "Clothing, accessories",
        "computers and smartphones": "Computers, smartphones",
        "digital goods": "Digital Goods",
        "equipment": "Equipment",
        "food": "Food",
        "games": "Games",
        "jeweller": "Jewellery",
        "jewellery": "Jewellery",
        "real estate": "Real Estate",
        "real food": "Eco Food",        
        "services": "Services",
        "software": "Software",
        "tobacco": "Tobacco",
        "vehicles": "Vehicles",
        "all": "Show all",
        "Share": "Share",
        "In descending order": "In descending order",
        "In ascending order": "In ascending order",
        "Date of creation": "Date of creation",
        "Sort direction": "Sort direction",
        "You have enough money to start the deal": "You have enough money to start the deal",
        "firstState": "The request has been sent to the blockchain, waiting for the deal creation",
        "secondState": "Confirmation received, starting the deal creation",
        "thirdState": "Deal creation in progress"
    }
}
