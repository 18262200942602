.chats {
    width: 100%;
    height: 100%;
    background-color: #131C28;
    border-radius: 0 0 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px 0 20px;
    position: relative;
}

.chatsTitle {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
}

.chatsText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #808897;
}

.chatsButtonWrapper {
    position: absolute;
    left: 0;
    height: 50px;
    width: 50px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms;
}

.chatsButtonWrapper:hover {
    cursor: pointer;
}

.chatsButton{
    left: 0;
    height: 15px;
    width: 15px;
    border-top: 3px solid #FFFFFF;
    border-left: 3px solid #FFFFFF;
    transform: rotate(-45deg);
}
