import { FC, useCallback } from 'react';
import { OfferResponse } from 'grpc-era/offer_pb';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import shareIcon from '/assets/svg/share.svg';
import { hostName } from '../../../../constants';
import { ProductImage } from '../../../../ui/ProductImage/ProductImage';
import { Title } from '../../../../ui/Title/Title';
import { Text } from '../../../../ui/Text/Text';
import { Button } from '../../../../ui/Button/Button';
import { deleteOffer } from '../../store/offersSlice';
import { useAppDispatch } from '../../../../store/store';
import { selectTradeRequestsList } from '../../store/tradeRequestsSlice';
import { useOfferActions } from './OfferActions';

type Props = {
    offer: OfferResponse.AsObject;
};

export const OwnOffer: FC<Props> = ({ offer }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const tradeRequests = useSelector(selectTradeRequestsList);
    const { onStartSelling, openChat, onShareOffer } = useOfferActions();

    const handleShareClick = () =>
        onShareOffer()
            .then(() => {
                toast.info('Copied!');
            })
            .catch((err) => {
                toast.error(err);
            });

    const onDeleteOffer = useCallback(async () => {
        // disableActionButtons();
        const result = await dispatch(deleteOffer({ offerId: offer.id }));

        deleteOffer.fulfilled.match(result) && history.push('/offers');
        if (deleteOffer.rejected.match(result)) {
            // enableActionButtons();
            console.error('Error during deleting offer: ', result);
        }
    }, [dispatch, history, offer]);

    return (
        <div>
            <div className="flex flex-wrap items-baseline justify-between gap-2.5">
                <Title type="6">{offer.header}</Title>

                <p className="text-brand-600 text-xs">{moment(offer.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
            </div>

            <div className="flex items-center justify-center mt-5">
                <ProductImage imageUrl={`${hostName}${offer.imagePath}`} />
            </div>

            <div className="my-4 flex justify-end items-center">
                <div className="flex items-center gap-2 cursor-pointer" onClick={handleShareClick}>
                    <img src={String(shareIcon)} alt="share" />
                    <Text>{t('Share')}</Text>
                </div>
            </div>

            <div>
                <p className="font-bold text-sm leading-4 text-brand-600">{t('Price')}:</p>

                <Title type="6" className="mt-2">
                    $ {offer.price}
                </Title>
            </div>

            <div className="mt-8">
                <p className="font-bold text-sm leading-4 text-brand-600">{t('Description')}:</p>

                <p className="mt-2 text-sm leading-4">{offer.offerData}</p>
            </div>

            <div className="mt-8 flex flex-col align-middle">
                <Button
                    size="XL"
                    colorScheme="gradient"
                    className="mx-auto"
                    block
                    onClick={onDeleteOffer}
                    // disabled={actionButtonsState}
                    // loading={actionButtonsState}
                >
                    {t('Delete Offer')}
                </Button>
            </div>

            {!!tradeRequests.length && (
                <div className="mt-8">
                    <p className="font-bold text-sm leading-4 text-brand-600">{t('Sellers price offers')}:</p>

                    {tradeRequests.map((tradeRequest) => (
                        <div
                            key={tradeRequest.id}
                            className="mt-2.5 bg-white rounded-2xl p-4 pb-6 text-brand-600 text-sm leading-4 max-w-[400] mx-auto"
                        >
                            <div className="flex flex-wrap items-center justify-between gap-2.5 border-b pb-4">
                                <p>{moment(tradeRequest.createdAt).format('D MMMM YYYY')}</p>

                                <p># {tradeRequest.id}</p>
                            </div>

                            <div className="flex flex-wrap items-center justify-between gap-2.5 mt-4">
                                <p className="text-brand-900">{t('Seller')}</p>

                                <p className="break-all">{tradeRequest.userPublicKey}</p>
                            </div>

                            <div className="flex flex-wrap items-center justify-between gap-2.5 mt-4">
                                <p className="text-brand-900">{t('Suggested price')}</p>

                                <p className="text-gradient font-bold text-lg leading-5">$ {tradeRequest.price}</p>
                            </div>

                            <p className="mt-5 text-brand-900">{t('Details')}:</p>

                            <p className="mt-2">{tradeRequest.text}</p>

                            <div className="mt-5">
                                <Button
                                    size="S"
                                    colorScheme="gradient"
                                    className="mx-auto"
                                    block
                                    onClick={() =>
                                        onStartSelling(
                                            tradeRequest.userPublicKey,
                                            tradeRequest.offerId,
                                            Number(tradeRequest.price)
                                        )
                                    }
                                    // disabled={actionButtonsState}
                                    // loading={actionButtonsState}
                                >
                                    {t('Start Deal')}
                                </Button>

                                <Button
                                    size="S"
                                    colorScheme="transparent"
                                    className="mt-2.5 mx-auto"
                                    block
                                    onClick={() => openChat(tradeRequest.userPublicKey, history.push)}
                                >
                                    {`${t('Talk to')} ${t('Seller')}`}
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

