export type UnreadMessagedWsResponseDTO = {
    wasRead: boolean;
    to: string;
};

export enum DealStatusEnum {
    NEW = 0,
    BUYER_APPROVED = 1,
    BUYER_FINISHED = 2,
    JUDGE_FINISHED = 3,
    JUDGE_CANCELED = 4,
    BUYER_CANCELED = 5,
}

export type DealWsResponseDTO = {
    offerId: number;
    offerData: string;
    buyerPublicKey: string;
    sellerPublicKey: string;
    offerHeader: string;
    createdAt: string;
    escrowAccount: string;
    status: DealStatusEnum;
    dealId: number;
    amount: string;
};

export type ChatWsResponseDTO = {
    id: number,
    chatId: number,
    message: string,
    fromPublicKey: string,
    toPublicKey: string,
    createdAt: string,
}
