import classNames from 'classnames';
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import styles from './Input.module.css';

type Props = Partial<{
    icon: ReactNode;
    error: string;
    inputSize: 'small' | 'medium';
}> &
    InputHTMLAttributes<HTMLInputElement>;

export const Input = forwardRef<HTMLInputElement, Props>(function Input(
    { icon, className, error, inputSize = 'medium', ...rest },
    ref
) {
    return (
        <div className={classNames('w-full', className)}>
            <div className={styles.searchArea}>
                <div className="flex items-center">
                    <input
                        {...rest}
                        ref={ref}
                        className={classNames(
                            styles.input,
                            inputSize === 'medium' && 'h-[55]',
                            inputSize === 'small' && 'h-[30]'
                        )}
                    />

                    <span className="w-6 h-6 flex items-center justify-center">{icon}</span>
                </div>
            </div>

            {error && <p className="text-brand-700">{error}</p>}
        </div>
    );
});
