import { FC } from 'react';
import { toast } from 'react-toastify';
import copyIcon from '../../../../assets/svg/copyIcon.svg';
import { copyToClipboard } from '../../helpers/copyToClipboard';
import { Button } from './Button';

type Props = {
    valueToCopy: string;
};

export const CopyButton: FC<Props> = ({ valueToCopy }) => {
    const copy = async () => {
        await copyToClipboard(valueToCopy);
        toast('Copied!');
    };

    return (
        <Button colorScheme="fullTransparent" onClick={copy}>
            <img src={String(copyIcon)} alt="copy" />
        </Button>
    );
};
