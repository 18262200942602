import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { OfferTypesEnum } from 'grpc-era/offer_pb';
import { CurrencyDollarIcon } from '@heroicons/react/outline';
import { Title } from '../../../../ui/Title/Title';
import { createNewOffer, selectOffersLoadingStatus } from '../../store/offersSlice';
import { useAppDispatch } from '../../../../store/store';
import { Button } from '../../../../ui/Button/Button';
import { Select } from '../../../../ui/Select/Select';
import { Input } from '../../../../ui/Input/Input';
import { LoadingStatusesRecord } from '../../../../constants';
import { ProductImage } from '../../../../ui/ProductImage/ProductImage';
import { Modal } from '../../../../ui/Modal/Modal';
import { Text } from '../../../../ui/Text/Text';
import { selectPublicOfferCategories } from '../../../../store/slices/publicSlice';
import { FormValues, resolver } from './formData';
import { getAllChats } from '../../../Chats/store/chatSlice';
import { selectToken } from '../../../../store/slices/authSlice';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import importTemplate from 'url:../../../../../../assets/import-template.csv'

export const CreateOffer: FC = () => {
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const history = useHistory();
    const token = useSelector(selectToken);
    const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
    const creationInProgress = useSelector(selectOffersLoadingStatus) === LoadingStatusesRecord.Loading;

    const { list: offerCategories } = useSelector(selectPublicOfferCategories);

    useEffect(() => {
        dispatch(getAllChats());
    }, [dispatch, token]);

    const defaultValues: Partial<FormValues> = useMemo(
        () => ({
            offerCategory: offerCategories[0],
            offerType: OfferTypesEnum.SELL,
        }),
        [offerCategories]
    );

    const offerCategoriesOptions = useMemo(
        () => offerCategories.map((category) => ({ label: t(category), value: category })),
        [offerCategories, t]
    );

    const {
        watch,
        setValue,
        control,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues,
        resolver,
    });

    const selectedOfferType = watch('offerType');
    const selectedFilesList = watch('imagesList');
    const selectedImageFile = selectedFilesList?.item(0);

    useEffect(() => {
        let objectUrl: string;

        if (selectedImageFile) {
            objectUrl = URL.createObjectURL(selectedImageFile);
            setPreviewImageUrl(objectUrl);
        }

        return () => {
            URL.revokeObjectURL(objectUrl);
        };
    }, [selectedImageFile]);

    const onSubmit = useCallback(
        async (formValues: FormValues) => {
            const payload = {
                ...formValues,
                price: formValues.price.toString()
            }
            const result = await dispatch(createNewOffer(payload));
            createNewOffer.fulfilled.match(result) && history.push(`/offers/${result.payload.offerId}`);
            createNewOffer.rejected.match(result) && console.error(result);
        },
        [dispatch, history]
    );

    const offerTypeSetters = useMemo(
        () => ({
            [OfferTypesEnum.BUY]: () => setValue('offerType', OfferTypesEnum.BUY),
            [OfferTypesEnum.SELL]: () => setValue('offerType', OfferTypesEnum.SELL),
        }),
        [setValue]
    );

    const handleClose = () => history.goBack();

    return (
        <div>
            <Modal open={true} onClose={handleClose}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Title type="6">{t('New offer')}</Title>

                    <div className="flex justify-center gap-3 mt-8">
                        {[OfferTypesEnum.SELL, OfferTypesEnum.BUY].map((offerType) => (
                            <div key={offerType} className="flex-1">
                                <Button
                                    type="button"
                                    colorScheme={selectedOfferType === offerType ? 'white' : 'gray'}
                                    onClick={offerTypeSetters[offerType]}
                                    size="FULL"
                                >
                                    {offerType === OfferTypesEnum.BUY ? t('Buy') : t('Sell')}
                                </Button>
                            </div>
                        ))}
                    </div>

                    <Input
                        {...register('header')}
                        error={errors.header?.message}
                        placeholder={t('Offer name')!}
                        className="mt-5"
                    />

                    <div className="mt-5 flex justify-between">
                        <Link to="/bulk" className="underline underline-offset-4">
                            <Text>Import list</Text>
                        </Link>

                        <a href={importTemplate} className="underline underline-offset-4">
                            <Text>Download a template (csv)</Text>
                        </a>
                    </div>

                    <Select options={offerCategoriesOptions} name="offerCategory" control={control} className="mt-5" />

                    <Input
                        icon={<CurrencyDollarIcon />}
                        {...register('price')}
                        error={errors.price?.message}
                        placeholder={t('Price')!}
                        className="mt-5"
                    />

                    <Input
                        {...register('offerData')}
                        error={errors.offerData?.message}
                        placeholder={t('Details')!}
                        className="mt-5"
                    />

                    <Input
                        id="offerImage"
                        {...register('imagesList')}
                        error={errors.imagesList?.message}
                        className="hidden"
                        type="file"
                    />
                    <div className={cn('mt-5 w-[104px] h-[104px]', previewImageUrl ? 'hidden' : 'visible')}>
                        <label htmlFor="offerImage">
                            <div className="border-dotted border-2 border-[#626A79] bg-[#131C28] h-full w-full rounded-md flex">
                                <div className="m-auto text-center">
                                    <Text>
                                        +<br />
                                        {t('Upload')}
                                        <br />
                                        {t('photo')}
                                    </Text>
                                </div>
                            </div>
                        </label>
                    </div>

                    {previewImageUrl && (
                        <>
                            <div className="mx-auto mt-5 w-[300]">
                                <ProductImage imageUrl={previewImageUrl} />
                            </div>
                            {errors.imagesList?.message && (
                                <span className="text-brand-700">{errors.imagesList?.message}</span>
                            )}
                        </>
                    )}

                    <Button colorScheme="gradient" loading={creationInProgress} className="mt-8 mx-auto">
                        {t('Create')}
                    </Button>
                </form>
            </Modal>
        </div>
    );
};

