.groupsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 0 30px;
    grid-row-gap: 30px;
    position: relative;
    z-index: 2;
}
.groupsItem {
    display: flex;
    justify-content: center;
    align-items: center;
}
