import { FC } from 'react';
import { TabItem, Tabs } from '../../ui/Tabs/Tabs';
import { Text } from '../../ui/Text/Text';
import { Deals } from '../Deals/components/Deals/Deals';
import { MyOffers } from '../Offers/components/MyOffers/MyOffers';

export const My: FC = () => {
    const tabs: TabItem[] = [
        {
            header: <Text>My deals</Text>,
            content: <Deals />,
        },
        {
            header: <Text>My offers</Text>,
            content: <MyOffers />,
        },
    ];

    return <Tabs tabs={tabs} />;
};
