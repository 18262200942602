import { FC } from 'react';

import { Redirect } from 'react-router-dom';
import { useReferral } from '../../hooks/useReferral';

export const HomePage: FC = () => {
    useReferral();

    return <Redirect to="/offers" />;
};
