import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import en from '../../../../assets/translations/en.json';
import ru from '../../../../assets/translations/ru.json';
import zh from '../../../../assets/translations/zh.json';

const resources = { en, ru, zh };

i18n.use(detector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',

        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
