import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../store/store';
import { hostName, LoadingStatusesRecord } from '../../../../constants';
import { Title } from '../../../../ui/Title/Title';
import moment from 'moment';
import { Button } from '../../../../ui/Button/Button';
import { OfferResponse, OfferTypesEnum } from 'grpc-era/offer_pb';
import {
    createTradeRequest,
    getTradeRequests,
    selectTradeRequestsList,
    selectTradeRequestsLoadingStatus,
} from '../../store/tradeRequestsSlice';
import { FormValues, resolver } from './formData';
import { Input } from '../../../../ui/Input/Input';
import { CurrencyDollarIcon } from '@heroicons/react/outline';
import { useSolanaService } from '../../../../hooks/useSolanaService';
import { ProductImage } from '../../../../ui/ProductImage/ProductImage';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { showBuyModal } from '../../../../components/Modals/store/modalSlice';
import { useBooleanState } from '../../../../hooks/useBooleanState';
import { Warning } from '../../../../components/Warning/Warning';
import { Text } from '../../../../ui/Text/Text';
import { config } from '../../../../../config/config';
import { TokenAccountNotFoundError } from '@solana/spl-token';
import { PublicKeyNotFound } from '../../../../services/errorService';
import { useTranslation } from 'react-i18next';
import shareIcon from '../../../../../../assets/svg/share.svg';
import complainIcon from '../../../../../../assets/svg/complain.svg';
import { navigate } from '../../../../helpers/navigate';
import { useOfferActions } from './OfferActions';
import { useApplicationLocalState } from '../../../../hooks/useApplicationLocalState';

type Props = {
    offer: OfferResponse.AsObject;
};

export const Offer: FC<Props> = ({ offer }) => {
    const { t } = useTranslation();

    const [enoughMoney, setEnoughMoney] = useState<null | boolean>(null);
    const [error, setError] = useState<string>('');

    const { context } = useApplicationLocalState()
    const readOnlyState = context?.readOnly
    const { onStartSelling, onShareOffer, openChat } = useOfferActions();

    const { state: isLoading, setFalse: disableLoading, setTrue: enableLoading } = useBooleanState(false);

    const {
        state: actionButtonsState,
        setFalse: enableActionButtons,
        setTrue: disableActionButtons,
    } = useBooleanState(false);

    const dispatch = useAppDispatch();
    const history = useHistory();

    const handleShowBuyModal = useCallback(() => {
        dispatch(showBuyModal());
    }, [dispatch]);

    const offerId = offer.id;

    const tradeRequestsLoadingStatus = useSelector(selectTradeRequestsLoadingStatus);
    const tradeRequests = useSelector(selectTradeRequestsList);

    const { publicKey, getTokenBalance } = useSolanaService();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<FormValues>({
        defaultValues: { offerId },
        resolver,
    });

    const onSubmit = useCallback(
        async (formValues: FormValues) => {
            if (!readOnlyState) {
                enableLoading();
                disableActionButtons();
    
                createTradeRequest.fulfilled.match(await dispatch(createTradeRequest(formValues))) &&
                    dispatch(getTradeRequests({ offerId }));
            }
            
        },
        [dispatch, offerId, disableActionButtons]
    );

    const onSuccessfulTradeStart = () => {
        history.push(`/predeal/${offerId}`)
    }

    const handleStartSelling = () => {
        enableLoading();
        disableActionButtons();

        onStartSelling(offer.ownerPublicKey, offer.id, Number(offer.price), onSuccessfulTradeStart)
            .catch((e) => {
                setError(error)
                disableLoading();
                enableActionButtons();
            });
    }

    const validateBalance = useCallback(async () => {
        if (!offer) {
            return;
        }

        if (readOnlyState) {
            if (actionButtonsState) {
                disableActionButtons();
            }

            return
        }

        enableLoading();
        disableActionButtons();

        const { data: tokenBalanceData, error: tokenBalanceError } = await getTokenBalance();

        disableLoading();

        if (tokenBalanceError) {
            console.log('Offer --- error tokenBalanceError', tokenBalanceError, tokenBalanceError?.name)
            setEnoughMoney(false)
            setError(tokenBalanceError.name + ' ' + tokenBalanceError.message);
            
            if (tokenBalanceError instanceof PublicKeyNotFound) {
                // setPkFound(true)
                enableActionButtons()
            }

            if (tokenBalanceError instanceof TokenAccountNotFoundError) {
                // do nothing coz token account doesnt exist
                // setAccountExists(false);
            }
            return;
        }

        if (tokenBalanceData) {
            setError('');
            // setAccountExists(true);
            if (Number(offer.price) > tokenBalanceData) {
                setEnoughMoney(false);
            } else {
                setEnoughMoney(true);
                enableActionButtons();
            }
        }
    }, [getTokenBalance, offer, publicKey]);

    useEffect(() => {
        console.log('validate balance effect')
        validateBalance().finally();
    }, [validateBalance, publicKey]);

    const handleComplainClick = () => {
        navigate(config.complainLink);
    };

    const handleShareClick = () =>
        onShareOffer()
            .then(() => {
                toast.info('Copied!');
            })
            .catch((err) => {
                toast.error(err);
            });

    return (
        <div>
            <div className="flex flex-wrap items-baseline justify-between gap-2.5">
                <Title type="6">{offer.header}</Title>

                <p className="text-brand-600 text-xs">{moment(offer.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</p>
            </div>

            <div className="flex items-center justify-center mt-5">
                <ProductImage imageUrl={`${hostName}${offer.imagePath}`} />
            </div>

            <div className="my-4 flex justify-between items-center">
                <div className="flex items-center gap-2 cursor-pointer" onClick={handleComplainClick}>
                    <img src={String(complainIcon)} alt="complain" />
                    <Text className="text-[#808897]">{t('Complain')}</Text>
                </div>

                <div className="flex items-center gap-2 cursor-pointer" onClick={handleShareClick}>
                    <img src={String(shareIcon)} alt="share" />
                    <Text>{t('Share')}</Text>
                </div>
            </div>

            <div>
                <p className="font-bold text-sm leading-4 text-brand-600">{t('Price')}:</p>

                <Title type="6" className="mt-2">
                    $ {offer.price}
                </Title>
            </div>

            <div className="mt-5">
                <p className="font-bold text-sm leading-4 text-brand-600">
                    {offer.offerType === OfferTypesEnum.BUY ? t('Buyer') : t('Seller')}:
                </p>

                <p className="mt-2 text-sm leading-4 break-all">{offer.ownerPublicKey}</p>
            </div>

            <div className="mt-8">
                <p className="font-bold text-sm leading-4 text-brand-600">{t('Description')}:</p>

                <p className="mt-2 text-sm leading-4">{offer.offerData}</p>
            </div>

            <div className="mt-8 flex flex-col align-middle">
                {offer.offerType === OfferTypesEnum.BUY && (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label className="font-bold text-sm leading-4 mt-5">{t('Offer price')}:</label>

                        <Input
                            icon={<CurrencyDollarIcon />}
                            {...register('price')}
                            error={errors.price?.message}
                            placeholder={t('Price')!}
                            className="mt-3.5"
                        />

                        <label className="font-bold text-sm leading-4 mt-5 block">{t('Offer description')}:</label>

                        <Input
                            {...register('text')}
                            error={errors.text?.message}
                            placeholder={t('Description')!}
                            className="mt-3.5"
                        />

                        <Button
                            block
                            size="XL"
                            className="mt-7 mx-auto"
                            colorScheme="gradient"
                            loading={tradeRequestsLoadingStatus === LoadingStatusesRecord.Loading}
                            disabled={tradeRequestsLoadingStatus === LoadingStatusesRecord.Loading || readOnlyState}
                        >
                            {t('Offer')}
                        </Button>
                    </form>
                )}

                {error && (
                    <div className="mb-5">
                        <Text className="text-slate-400">{error}</Text>
                    </div>
                )}

                {enoughMoney && (
                    <div className="mb-5">
                        <Text className="text-slate-400">{t('You have enough money to start the deal')}</Text>
                    </div>
                )}

                {enoughMoney === false && offer.offerType === OfferTypesEnum.SELL && (
                    <div className="mb-5">
                        <Warning
                            content={
                                <Text>
                                    {`${t('You need')} ${offer.price} USDT ${t('and')} ${t('approximately')} ${
                                        config.minSol
                                    } SOL ${t('to ensure this transaction')}`}
                                </Text>
                            }
                            footer={
                                <button onClick={handleShowBuyModal}>
                                    <div className="relative">
                                        <Text className="text-gradient">{`${t('Buy')} USDT ${t('and')} SOL now`}</Text>
                                        <div className="absolute bg-gradient h-[1px] w-full bottom-[10%]" />
                                    </div>
                                </button>
                            }
                        />
                    </div>
                )}
                {offer.offerType === OfferTypesEnum.SELL && (
                    <Button
                        size="XL"
                        colorScheme="gradient"
                        className="mx-auto"
                        block
                        onClick={handleStartSelling}
                        // disabled={actionButtonsState || !enoughMoney || !!readOnlyState}
                        disabled={actionButtonsState || readOnlyState}
                        loading={isLoading}
                    >
                        {t('Start Deal')}
                    </Button>
                )}

                <Button
                    size="XL"
                    colorScheme="gray"
                    className="mt-2.5 mx-auto"
                    block
                    onClick={() => openChat(offer.ownerPublicKey, history.push)}
                    disabled={!!readOnlyState}
                >
                    {offer.offerType === OfferTypesEnum.BUY ? t('Talk to buyer') : t('Talk to seller')}
                </Button>
            </div>

            {!!tradeRequests?.length && (
                <div className="mt-8">
                    <p className="font-bold text-sm leading-4 text-brand-600">{t('Sellers price offers')}:</p>

                    {tradeRequests.map(({ text, price, id, createdAt, userPublicKey }) => (
                        <div
                            key={id}
                            className="mt-2.5 bg-white rounded-2xl p-4 pb-6 text-brand-600 text-sm leading-4 max-w-[400] mx-auto"
                        >
                            <div className="flex flex-wrap items-center justify-between gap-2.5 border-b pb-4">
                                <p>{moment(createdAt).format('D MMMM YYYY')}</p>

                                <p># {id}</p>
                            </div>

                            <div className="flex flex-wrap items-center justify-between gap-2.5 mt-4">
                                <p className="text-brand-900">{t('Seller')}</p>

                                <p className="break-all">{userPublicKey}</p>
                            </div>

                            <div className="flex flex-wrap items-center justify-between gap-2.5 mt-4">
                                <p className="text-brand-900">{t('Suggested price')}</p>

                                <p className="text-gradient font-bold text-lg leading-5">$ {price}</p>
                            </div>

                            <p className="mt-5 text-brand-900">{t('Details')}:</p>

                            <p className="mt-2">{text}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

