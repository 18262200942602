import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Switch, Route, RouteProps, Redirect, useLocation } from 'react-router-dom';
import { Chats } from '../../screens/Chats/Chats';
import { CurrentChat } from '../../screens/Chats/components/CurrentChat/CurrentChat';
import { CreateOffer } from '../../screens/Offers/components/CreateOffer/CreateOffer';
import { MobileLayout } from '../../screens/Landing/Landing';
import { Offers } from '../../screens/Offers/components/Offers/Offers';
// import { Offer } from '../../screens/Offers/components/Offer/Offer';
import { OfferContainer as Offer } from '../../screens/Offers/components/Offer/OfferContainer';
import { FC } from 'react';
import { HomePage } from '../../screens/HomePage/HomePage';
import { Deals } from '../../screens/Deals/components/Deals/Deals';
import { Deal } from '../../screens/Deals/components/Deal/Deal';
import { StyledContainer } from '../StyledContainer/StyledContainer';
import { Earn } from '../../screens/Earn/Earn';
import { My } from '../../screens/My/My';
import { Admin } from '../../screens/Admin/Admin';
import { useApplicationLocalState } from '../../hooks/useApplicationLocalState';
import { Predeal } from '../../screens/Predeal/Predeal';
import { BulkCreateOffer } from '../../screens/Offers/components/BulkCreateOffer/BulkCreateOffer';

const ProtectedRoute: FC<RouteProps> = ({ children, exact, path }) => {
    const { context } = useApplicationLocalState()

    return context?.readOnly === true ? (
        <Redirect to="/offers" />
    ) : (
        <Route exact={exact} path={path}>
            {children}
        </Route>
    );
};

export const Router: FC = () => {
    const location = useLocation();

    return (
        <TransitionGroup className="h-full">
            <CSSTransition
                key={location.pathname}
                classNames="fade"
                timeout={{
                    appear: 800,
                    enter: 800,
                    exit: 0,
                }}
            >
                <Switch location={location}>
                    <Route exact path="/">
                        <HomePage />
                    </Route>

                    <Route exact path="/predeal/:offerId">
                        <StyledContainer>
                            <Predeal />
                        </StyledContainer>
                    </Route>

                    <ProtectedRoute exact path="/chats">
                        <StyledContainer>
                            <Chats />
                        </StyledContainer>
                    </ProtectedRoute>

                    <ProtectedRoute path="/chats/:id">
                        <StyledContainer>
                            <CurrentChat />
                        </StyledContainer>
                    </ProtectedRoute>

                    <ProtectedRoute path="/createOffer">
                        <StyledContainer>
                            <CreateOffer />
                        </StyledContainer>
                    </ProtectedRoute>

                    <Route path="/mobile">
                        <StyledContainer>
                            <MobileLayout />
                        </StyledContainer>
                    </Route>

                    <Route exact path="/offers">
                        <StyledContainer>
                            <Offers />
                        </StyledContainer>
                    </Route>

                    <ProtectedRoute exact path="/bulk">
                        <StyledContainer>
                            <BulkCreateOffer />
                        </StyledContainer>
                    </ProtectedRoute>

                    <Route path="/offers/:id">
                        <StyledContainer>
                            <Offer />
                        </StyledContainer>
                    </Route>

                    <ProtectedRoute exact path="/my">
                        <StyledContainer>
                            <My />
                        </StyledContainer>
                    </ProtectedRoute>

                    <ProtectedRoute exact path="/deals">
                        <StyledContainer>
                            <Deals />
                        </StyledContainer>
                    </ProtectedRoute>

                    <ProtectedRoute path="/deals/:id">
                        <StyledContainer>
                            <Deal />
                        </StyledContainer>
                    </ProtectedRoute>

                    <ProtectedRoute path="/earn">
                        <StyledContainer>
                            <Earn />
                        </StyledContainer>
                    </ProtectedRoute>

                    <ProtectedRoute path="/admin/:dealId">
                        <Admin />
                    </ProtectedRoute>

                    <Redirect to="/offers" />
                </Switch>
            </CSSTransition>
        </TransitionGroup>
    );
};

