import { FC } from 'react';

import { useChatsStream } from '../../hooks/useChatsStream';
import { useDealsStream } from '../../hooks/useDealsStream';
import { useUnreadMessagesStream } from '../../hooks/useUnreadMessagesStream';

export const GlobalStreamsProvider: FC = () => {
    useChatsStream()
    useDealsStream()
    useUnreadMessagesStream();

    return null;
};
