import { FC } from 'react';
import styles from './NewExperience.module.css';

export const NewExperience: FC = () => (
    <div className={styles.newExperience}>
        <div>
            <div className={styles.textWrapper}>
                <div className={styles.expGradient} />
                <div className={styles.fastSupportive}>
                    <span className={styles.expFast}>Fast</span>
                    <span>Supportive</span>
                </div>
                <div className={styles.reliableMoney}>
                    Reliable
                    <span className={styles.expMoney}>Money</span>
                </div>
                <span className={styles.expIntegrated}>Integrated</span>
                <div className={styles.expForPeople}>For people</div>
            </div>
            <div className={styles.expTitle}>New experience</div>
        </div>
    </div>
);
