import { FC, ReactNode, useMemo } from 'react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    // GlowWalletAdapter,
    PhantomWalletAdapter,
    // SlopeWalletAdapter,
    SolflareWalletAdapter,
    // TorusWalletAdapter,
    TrustWalletAdapter
} from '@solana/wallet-adapter-wallets';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { config } from '../../../config/config';
import { useCookie } from '../../hooks/useCookie';
import { cookieReferralName } from '../../constants';

type Props = { children?: ReactNode };

const network = WalletAdapterNetwork.Devnet;
const networkEndpoint = config.networkEndpoint

export const SolanaWalletProvider: FC<Props> = ({ children }) => {
    const { getCookie } = useCookie();
    const cookie = useMemo(() => {
        return getCookie(cookieReferralName);
    }, [getCookie]);

    const wallets = useMemo(() => {
        const phantomWalletAdapter = new PhantomWalletAdapter();

        try {
            if (cookie) {
                phantomWalletAdapter.url = config.phantomWalletAdapterUrl + encodeURIComponent('?ref=' + cookie) ;
            } else {
                phantomWalletAdapter.url = config.phantomWalletAdapterUrl;
            }
        } catch (e) {
            console.error(e)
            phantomWalletAdapter.url = config.phantomWalletAdapterUrl;
        }

        const solflareWalletAdapter = new SolflareWalletAdapter({ network });
        const trustWalletAdapter = new TrustWalletAdapter({ network })

        return [
            phantomWalletAdapter,
            // new GlowWalletAdapter(),
            // new SlopeWalletAdapter(),
            solflareWalletAdapter,
            //new TorusWalletAdapter(),
            trustWalletAdapter
        ];
    }, [cookie]);

    return (
        <ConnectionProvider endpoint={networkEndpoint}>
            <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>{children}</WalletModalProvider>
            </WalletProvider>
        </ConnectionProvider>
    );
};
