import { FC } from 'react';
import { Text } from '../../ui/Text/Text';

export const Logo: FC = () => {
    return (
        <div className="flex flex-nowrap">
            <Text className="!text-xl font-bold">ERA</Text>
            <div className="mx-0.5" />
            <Text className="!text-xl font-thin">crypto</Text>
        </div>
    );
};
