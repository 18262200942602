import { FC } from 'react';
import styles from './Groups.module.css';

const groupData = [
    { id: 1, img: require('../../assets/Img/coinbase.svg'), alt: 'coinbase-img' },
    { id: 2, img: require('../../assets/Img/ecncapital.svg'), alt: 'ecnCapital-img' },
    { id: 3, img: require('../../assets/Img/coinmarketcup.svg'), alt: 'coinMarketCup-img' },
    { id: 4, img: require('../../assets/Img/coinbase.svg'), alt: 'coinbase-img' },
    { id: 5, img: require('../../assets/Img/ecncapital.svg'), alt: 'ecnCapital-img' },
    { id: 6, img: require('../../assets/Img/coinbase.svg'), alt: 'coinbase-img' },
];

export const Groups: FC = () => (
    <div className={styles.groups}>
        <div className={styles.groupsWrapper}>
            {groupData.map(({ id, img, alt }) => (
                <div className={styles.groupsItem} key={id}>
                    <img src={img} alt={alt} />
                </div>
            ))}
        </div>
    </div>
);
