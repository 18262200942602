import { FC } from 'react';
import styles from './CryptoUniverse.module.css';
import { Title } from '../../../../ui/Title/Title';

export const CryptoUniverse: FC = () => (
    <div className={styles.cryptoUniverse}>
        <Title type="1">ERA crypto universe</Title>
        <p className={styles.cryptoUniverseAbout}>
            ERA crypto is an answer to your needs. <br />
            ERA crypto allows free economic relations.
        </p>
        <div className={styles.cryptoUniverseWrapper}>
            <div className={styles.cryptoUniverseBlock}>
                <div className={styles.cryptoUniverseMenu}>
                    <span>Community</span>
                </div>
                <div className={styles.cryptoUniverseMenu}>
                    <span>P2P</span>
                </div>
                <div className={styles.cryptoUniverseMenu}>
                    <span>Transport</span>
                </div>
            </div>

            <div className={styles.cryptoUniverseBlock}>
                <div className={styles.cryptoUniverseMenu}>
                    <span>Marketplaces</span>
                </div>
                <div className={styles.cryptoUniverseMenu}>
                    <div>
                        Crypto <br /> exchange <br />
                        platforms
                    </div>
                </div>
                <div className={styles.cryptoUniverseMenu}>
                    <span>And more</span>
                </div>
            </div>
        </div>
    </div>
);
