import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch } from '../store/store';
import { useSelector } from 'react-redux';
import { selectToken } from '../store/slices/authSlice';
import { updateDeals, updateDealsSubscriptionStatus } from '../screens/Deals/store/dealsSlice';
import { SubscriptionStatusesEnum } from '../constants';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { DealStatusEnum, DealWsResponseDTO } from '../services/types';
import { websocketService } from '../services/wsService';
import { config } from '../../config/config';
import { useRollbar } from '@rollbar/react';

const convertDealStatus = (dealStatus: keyof typeof DealStatusEnum) => {
    // TODO ПНУТЬ СТАСА, ЧТОБЫ СДЕЛАЛ ОДИНАКОВЫЕ СТАТУСЫ (ЛИБО СТРОКОЙ ЛИБО ЦИФРОЙ)
    //  В ПОДПИСКАХ И СТАТИЧЕСКИХ ЗАПРОСАХ
    //  ПОСЛЕ ЧЕГО УДАЛИТЬ ЭТО ГОВНО

    return DealStatusEnum[dealStatus];
};

export const useDealsStream = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { pathname } = useLocation();
    const rollbar = useRollbar()

    const token = useSelector(selectToken)!;
    const onConnect = useCallback(
        () => {
            dispatch(updateDealsSubscriptionStatus(SubscriptionStatusesEnum.Subscribed))
        },
        [dispatch]
    );
    const onConnectionFailed = useCallback(
        (code: number, error: string) => {
            if (code !== 1000) {
                rollbar.error({ error, token })
            }
            dispatch(updateDealsSubscriptionStatus(SubscriptionStatusesEnum.SubscriptionFailed))
        },
        [dispatch, rollbar, token]
    );

    const dealHandlerRef = useRef<(newDeal: DealWsResponseDTO) => void>();

    useEffect(() => {
        dealHandlerRef.current = (newDeal: DealWsResponseDTO) => {
            if (pathname !== `/offers/${newDeal.offerId}`) {
                toast.info('Your deal was updated! Click to check the new status.', {
                    onClick: () => history.push(`/deals/${newDeal.dealId}`),
                    theme: 'dark',
                });
                return;
            }

            // history.push(`/deals/${newDeal.dealId}`);
        };
    }, [dispatch, history, pathname]);

    const messageHandler = useCallback(
        (newDeal: DealWsResponseDTO) => {
            newDeal.status = convertDealStatus(newDeal.status as unknown as keyof typeof DealStatusEnum);
            newDeal.offerData = JSON.parse(newDeal.offerData)?.details;

            dispatch(updateDeals([newDeal]));

            dealHandlerRef.current?.(newDeal);
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(updateDealsSubscriptionStatus(SubscriptionStatusesEnum.Subscribing));

        const ws = websocketService(config.websocket.deals, messageHandler);
        ws.connect(token, onConnect, onConnectionFailed);

        return () => {
            ws.disconnect();
        };
    }, [dispatch, onConnect, token, messageHandler, onConnectionFailed]);
};
