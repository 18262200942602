import logoIcon from '../../assets/icon/logo-dark.png';
import { Config } from './config_base';

export const config: Config = {
    mode: 'production',
    network: 'mainnet-beta',
    networkEndpoint: 'https://app.eracrypto.app/solana_node/',
    programId: 'D15RnkWdbcUUyCEcuAGhugjxEbwgcSSywhceBMorHPVQ',
    mint: 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    judgePublicKey: 'GvCBZqQEypbs8w2ou7M9FXZ5bbTmYrd6NS3hnUYHXMTw',
    phantomWalletAdapterUrl: 'https://phantom.app/ul/browse/https%3A%2F%2Fapp.eracrypto.app',
    logoUrl: String(logoIcon),
    hostName: 'https://app.eracrypto.app/',
    apiUrl: 'https://app.eracrypto.app:8080',
    uploadUrl: 'https://app.eracrypto.app',
    websocket: {
        echo: 'wss://app.eracrypto.app/ws/echo',
        deals: 'wss://app.eracrypto.app/ws/deals',
        chats: 'wss://app.eracrypto.app/ws/messages',
        unreadMessages: 'wss://app.eracrypto.app/ws/unread_messages',
        solanaNode: 'wss://app.eracrypto.app/solana_ws/'
    },
    lamports: 0.000001,
    maxFileSizeMB: 10,
    minSol: 0.004,
    // rollbarAccessToken: 'dce89f3a636a47e885b4e7181d1f6682',
    rollbarAccessToken: '',
    complainLink: 'https://t.me/eracrypto_bot',
    tokenLifetime: 1000 * 60 * 60 * 12 // 12 hours
};
