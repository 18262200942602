import { FC } from 'react';
import cn from 'classnames';

type TextProps = {
    className?: string;
    variant?: 'title';
} & JSX.IntrinsicElements['span'];

export const Text: FC<TextProps> = ({ children, className, variant, ...rest }) => {
    return (
        <span
            {...rest}
            className={cn('text-sm', className, variant === 'title' && 'font-bold text-sm leading-4 text-brand-600')}
        >
            {children}
        </span>
    );
};

