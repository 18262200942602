.offers {
    max-width: 800px;
    margin: 0 auto;
    font-family: 'Gilroy-Regular', sans-serif;
}
.button {
    margin-right: 10px;
}
.search {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.settingsBlock {
    background: #fff;
    border-radius: 10px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.categories {
    -ms-overflow-style: none;  /* Hide scrollbar for IE and Edge */
    scrollbar-width: none;  /* Hide scrollbar for Firefox */
}

.categories:hover {
    cursor: pointer;
}

.categories::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}

.carousel-left {
    transition: transform 1s ease;
    transform: translateX(calc(-80% - 20px));
}

.carousel-right {
    transition: transform 1s ease;
    transform: translateX(calc(80% - 20px));
}

@media (min-width: 600px) {
    .search {
        margin-top: 15px;
    }
}
